import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  debounce,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import UserOption from 'app/components/AutocompleteComponents/UserOption';
import { notifyError, notifySuccess } from 'app/components/Toast';
import { SYSTEM_CODE_AC } from 'constants/systemCode';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useGetExpertsMutation } from 'redux/services/experts.services';
import {
  useCreateMultiSaleMutation,
  useCreateSaleMutation,
} from 'redux/services/sale.services';
import { EContactForm, EFlashSaleStatus, ESelectBooking } from 'types/enum';
import { IExpert } from 'types/Expert';
import { ICreateFlashSaleParams } from 'types/Sale';
import { TypeOf, z } from 'zod';
import FormAddSale from './components/FormAddSale';
import SaleOtp from './SaleOtp';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IAddFormSale {
  handleClose?: () => void;
}

const saleSchema = z
  .object({
    expertId: z
      .string({
        invalid_type_error: 'Vui lòng chọn chuyên gia',
        required_error: 'Vui lòng chọn chuyên gia',
      })
      .optional(),
    contactForm: z.nativeEnum(EContactForm, {
      invalid_type_error: 'Vui lòng chọn hình thức liên hệ',
      required_error: 'Vui lòng chọn hình thức liên hệ',
    }),
    name: z.string().optional().default(''),
    discountAmount: z.number({
      invalid_type_error: 'Vui lòng nhập giá flash sale',
      required_error: 'Vui lòng nhập giá flash sale',
    }),
    countdownStep: z.coerce.number().optional().default(0),
    saleStartTime: z.date({
      invalid_type_error: 'Vui lòng chọn thời gian',
      required_error: 'Vui lòng chọn thời gian',
    }),
    saleEndTime: z.date({
      invalid_type_error: 'Vui lòng chọn thời gian',
      required_error: 'Vui lòng chọn thời gian',
    }),
    countdownTime: z.date().optional(),
    skill: z.string().optional(),
    dateTiltle: z.string().optional().default(''),
    oldPirce: z.number().optional(),
  })
  .refine(
    data =>
      (!data.saleStartTime && !data.saleEndTime) ||
      (data.saleStartTime &&
        data.saleEndTime &&
        data.saleStartTime < data.saleEndTime),
    {
      message: 'Thời gian kết thúc phải lớn hơn thời gian bắt đầu',
      path: ['saleEndTime'],
    },
  )
  .refine(
    data =>
      (data.oldPirce &&
        data.discountAmount &&
        data.oldPirce > data.discountAmount) ||
      !data.oldPirce ||
      (!data.name &&
        !data.discountAmount &&
        !data.saleEndTime &&
        !data.saleStartTime),
    {
      message: 'Giá giảm phải nhỏ hơn giá cũ',
      path: ['discountAmount'],
    },
  );

const AddSale = (props: IAddFormSale) => {
  const {
    handleClose = () => {
      return;
    },
  } = props;
  const [getExperts, { isLoading: isLoadingExperts, data: listExperts }] =
    useGetExpertsMutation();
  const listSearchExpert: IExpert[] = listExperts?.listData || [];
  const [currentExpert, setCurrentExpert] = useState<IExpert | undefined>();

  const [activeStep, setActiveStep] = useState(1);
  type SaleInput = TypeOf<typeof saleSchema>;

  const otpSubmitBtnRef = useRef<{ handleOTP: () => Promise<boolean> }>(null);
  const methods = useForm<SaleInput>({
    resolver: zodResolver(saleSchema),
    mode: 'onChange',
  });

  const [createFlashSale] = useCreateSaleMutation();
  const [createMultiFlashSale] = useCreateMultiSaleMutation();
  const {
    handleSubmit,
    control,
    trigger,
    getValues,
    watch,
    formState: { isDirty },
  } = methods;

  const formContact = useWatch({ control: control, name: 'contactForm' });

  const submitForm: SubmitHandler<SaleInput> = async data => {
    const payload: Partial<ICreateFlashSaleParams>[] = [];
    if (data.discountAmount) {
      const {
        saleEndTime,
        saleStartTime,
        countdownTime,
        countdownStep,
        oldPirce,
        ...rest
      } = data;
      data.countdownTime = data.saleStartTime;
      const addTimeCountDown = dayjs(data.countdownTime)
        .hour(dayjs().hour())
        .minute(dayjs().minute())
        .add(countdownStep || 0, 'hour');
      payload.push({
        reference: data?.expertId || '',
        // contactForm: EContactForm.CALL,
        status: EFlashSaleStatus.ACTIVED,
        countdownStep: countdownStep,
        saleEndTime: dayjs(saleEndTime).utc().format(),
        saleStartTime: dayjs(saleStartTime).utc().format(),
        countdownTime: addTimeCountDown.utc().format(),
        ...rest,
      });
    }
    try {
      const formatPayload = payload?.length === 1 ? payload[0] : payload;
      if (payload?.length === 1) {
        await createFlashSale(formatPayload)
          .unwrap()
          .then(() => {
            isDirty && notifySuccess('Thêm mới thành công');
            handleClose();
          })
          .catch(e => {
            notifyError(
              SYSTEM_CODE_AC[e.data?.systemCode] || e.data?.message?.[0],
            );
            // handleClose();
            setActiveStep(1);
          });
      } else {
        await createMultiFlashSale(formatPayload)
          .unwrap()
          .then(() => {
            isDirty && notifySuccess('Thêm mới thành công');
            handleClose();
          })
          .catch(e => {
            notifyError(
              SYSTEM_CODE_AC[e.data?.systemCode] || e.data?.message?.[0],
            );
            // handleClose();
            setActiveStep(1);
          });
      }
    } catch {}
  };

  const [selectForm, setSelectForm] = useState<ESelectBooking>(
    ESelectBooking.BOOK_DIRECT,
  );

  const handleChange = (e: any) => {
    setSelectForm(e.target.value);
    // const currentValues = getValues('expertId');
    // reset({ expertId: currentValues });
  };

  const handleOpen = async () => {
    const valid = await trigger();
    if (!valid) return;
    setActiveStep(2);
  };

  return (
    <Card>
      <CardHeader
        title={'Thêm flash sale'}
        titleTypographyProps={{ variant: 'h6' }}
      />
      {activeStep !== 2 && (
        <form onSubmit={handleSubmit(submitForm)}>
          <CardContent>
            <Grid container spacing={7} rowSpacing={4}>
              <Grid item xs={12}>
                <Controller
                  name="expertId"
                  control={control}
                  render={({
                    field: { onChange, name, value, ref, onBlur },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      ref={ref}
                      freeSolo
                      value={value}
                      onChange={(e, value) => {
                        if (typeof value === 'string') {
                          onChange(value);
                        } else if (value) {
                          onChange(value?._id);
                          setCurrentExpert(value);
                        }
                      }}
                      onBlur={onBlur}
                      options={listSearchExpert}
                      getOptionLabel={option =>
                        typeof option === 'string' ? option : option.fullname
                      }
                      loading={isLoadingExperts}
                      onInputChange={debounce(
                        (event, newInputEvent) =>
                          newInputEvent &&
                          getExperts({
                            search: newInputEvent,
                            isExpert: true,
                            limit: 20,
                          }),
                        500,
                      )}
                      isOptionEqualToValue={(option, value) =>
                        value._id === option._id
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          size="small"
                          name={name}
                          label="Nhập tên chuyên gia"
                          error={!!error}
                          fullWidth
                          helperText={error?.message}
                        />
                      )}
                      PaperComponent={props => (
                        <Paper {...props} elevation={24} />
                      )}
                      renderTags={(users, getTagProps, state) =>
                        users.map((u, index) => (
                          <Chip
                            key={u._id ?? u}
                            variant="outlined"
                            avatar={
                              <Avatar
                                alt={u.fullname}
                                src={u.avatar?.location}
                              />
                            }
                            label={u.fullname ?? u}
                            onDelete={getTagProps({ index }).onDelete}
                          ></Chip>
                        ))
                      }
                      renderOption={(props, option) => (
                        <UserOption {...props} expert={option} />
                      )}
                      noOptionsText={'Không tìm thấy chuyên gia'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectForm}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value={ESelectBooking.BOOK_DIRECT}
                      control={<Radio />}
                      label="Gói tư vấn chuyên gia"
                    />
                    <FormControlLabel
                      value={ESelectBooking.BOOK_SKILL}
                      control={<Radio />}
                      label="Gói tư vấn kỹ năng"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel size="small">Hình thức liên hệ</InputLabel>
                  <Controller
                    name="contactForm"
                    control={control}
                    render={({ field, fieldState }) => (
                      <Select
                        label="Hình thức liên hệ"
                        {...field}
                        value={field.value ?? ''}
                        error={!!fieldState.error}
                        size="small"
                      >
                        <MenuItem value={EContactForm.CALL}>Gọi điện</MenuItem>
                        <MenuItem value={EContactForm.MEET}>Gặp mặt</MenuItem>
                        <MenuItem value={EContactForm.SHOW}>Show</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <FormAddSale
                formSale={selectForm}
                control={control}
                currentExpert={currentExpert}
                formContact={formContact}
              />
            </Grid>
          </CardContent>
        </form>
      )}
      {activeStep === 2 && (
        <CardContent>
          <Box
            sx={{
              marginTop: 4,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: 200,
            }}
          >
            <Typography sx={{ typography: 'body' }} align="center">
              Nhập mã OTP
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Box
                sx={{
                  maxWidth: 400,
                }}
              >
                <SaleOtp ref={otpSubmitBtnRef} />
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}

      <CardActions>
        <Grid container>
          <Grid item xs={12}>
            {activeStep > 1 && (
              <Button
                onClick={() => setActiveStep(s => s - 1)}
                disabled={activeStep <= 1}
                variant="contained"
                sx={{ marginRight: 3.5 }}
              >
                quay lại
              </Button>
            )}
            <Button
              onClick={() => {
                if (activeStep >= 2) {
                  otpSubmitBtnRef.current?.handleOTP().then(result => {
                    if (result) {
                      handleSubmit(submitForm)();
                    }
                  });
                } else {
                  handleOpen();
                }
              }}
              // onClick={() => handleSubmit(submitForm)()}
              disabled={!isDirty}
              variant="contained"
              sx={{ marginRight: 3.5 }}
            >
              Tiếp tục
            </Button>
            <Button
              type="reset"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Hủy
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default AddSale;
