import { Box, Paper } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import AddVoucherFile from '../BoxAddVoucherFile/AddVoucherFile';
import VoucherDetail from '../VoucherDetail';

const AddVoucherPage = () => {
  const [searchParams] = useSearchParams();
  const typeCreate = searchParams.get('type');
  return (
    <Paper>
      <Box>
        <VoucherDetail />
      </Box>
    </Paper>
  );
};

export default AddVoucherPage;
