// ** React Imports
import { ElementType, ReactNode } from 'react';

// ** Next Imports

// ** MUI Imports
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box, { BoxProps } from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';

// ** Configs Import
import themeConfig from 'configs/themeConfig';

// ** Types
import { NavLink } from '@core/layouts/types';
import { Settings } from '@core/context/settingsContext';

// ** Custom Components Imports
import UserIcon from 'layouts/components/UserIcon';

// ** Utils
// import { handleURLQueries } from '@core/layouts/utils';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';

interface Props {
  item: NavLink;
  settings: Settings;
  navVisible?: boolean;
  toggleNavVisibility: () => void;
}

// ** Styled Components
// const MenuNavLink = styled(ListItemButton)<
//   ListItemButtonProps & {
//     component?: ElementType;
//     target?: '_blank' | undefined;
//   }
// >(({ theme }) => ({
const MenuNavLink = styled(ListItemButton)<
  ListItemButtonProps & {
    component?: ElementType;
    target?: '_blank' | undefined;
    to?: string;
  }
>(({ theme }) => ({
  width: '100%',
  borderTopRightRadius: 100,
  borderBottomRightRadius: 100,
  color: theme.palette.text.primary,
  padding: theme.spacing(2.25, 3.5),
  transition: 'opacity .25s ease-in-out',
  '&.active, &.active:hover': {
    boxShadow: theme.shadows[3],
    backgroundImage: `linear-gradient(98deg, ${theme.palette.customColors.primaryGradient}, ${theme.palette.primary.main} 94%)`,
  },
  '&.active .MuiTypography-root, &.active .MuiSvgIcon-root': {
    color: `${theme.palette.common.white} !important`,
  },
}));

const MenuItemTextMetaWrapper = styled(Box)<BoxProps>({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  transition: 'opacity .25s ease-in-out',
  ...(themeConfig.menuTextTruncate && { overflow: 'hidden' }),
});

const VerticalNavLink = ({ item, navVisible, toggleNavVisibility }: Props) => {
  // ** Hooks
  // const location = useLocation();
  const IconTag: ReactNode | React.FC = item.icon;
  const location = useLocation();

  const isNavLinkActive = () => {
    //TODO: handle active link
    if (location.pathname === item.path) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ListItem
      disablePadding
      className="nav-link"
      disabled={item.disabled || false}
      sx={{ mt: 1.5, px: '0 !important' }}
    >
      <MenuNavLink
        component={Link}
        to={item.path === undefined ? '/' : `${item.path}`}
        className={isNavLinkActive() ? 'active' : ''}
        {...(item.openInNewTab ? { target: '_blank' } : null)}
        onClick={e => {
          if (item.path === undefined) {
            e.preventDefault();
            e.stopPropagation();
          }
          if (navVisible) {
            toggleNavVisibility();
          }
        }}
        sx={{
          pl: 5.5,
          ...(item.disabled
            ? { pointerEvents: 'none' }
            : { cursor: 'pointer' }),
        }}
      >
        <ListItemIcon
          sx={{
            mr: 2.5,
            color: 'text.primary',
            transition: 'margin .25s ease-in-out',
          }}
        >
          <UserIcon icon={IconTag} />
        </ListItemIcon>

        <MenuItemTextMetaWrapper>
          <Tooltip title={item.title} enterDelay={500} placement="right-start">
            <Typography
              sx={{ fontSize: '14px' }}
              {...(themeConfig.menuTextTruncate && { noWrap: true })}
            >
              {item.title}
            </Typography>
          </Tooltip>
          {item.badgeContent ? (
            <Chip
              label={item.badgeContent}
              color={item.badgeColor || 'primary'}
              sx={{
                height: 20,
                fontWeight: 500,
                marginLeft: 1.25,
                '& .MuiChip-label': { px: 1.5, textTransform: 'capitalize' },
              }}
            />
          ) : null}
        </MenuItemTextMetaWrapper>
      </MenuNavLink>
    </ListItem>
  );
};

export default VerticalNavLink;
