export const OFFER_TYPE_REQUEST = {
  1: 'Gói dịch vụ ',
  2: 'Gói đặt lịch chuyên gia ',
  3: 'Gói kỹ năng ',
  4: 'Gói đặt show trực tiếp ',
};

export enum OFFER_STATUS {
  SERVED = 7, // Đã tư vấn
  PAID_EXPERT = 6, // Đã thanh toán cho chuyên gia
  COMPLETED = 5, // Đã hoàn thành
  CONFIRMED = 4, // Đã xác nhận
  PAID = 3, // Đã thanh toán chờ xác nhận
  ACCEPTED = 2, // Đã chọn offer
  DEFAULT = 1, // Mặc định
  BLOCKED = 0, // Đã bị khoá
  DELETED = -1, // Đã bị xoá
  CANCELED_AFTER_ACCEPTED = -2, // Chuyên gia huỷ chào giá khi người dùng đã chọn
  REPORTED = -3, // Bị khiếu nại
  PENDING = -4, // Chờ duyệt yêu cầu đặc biệt
  CANCEL_REQUESTED = -5, // Chờ duyệt huỷ yêu cầu đặc biệt
  DELAY_REQUESTED = -6, // Chờ duyệt dời lịch
  CANCELED_BEFORE_ACCEPTED = -7, // Chuyên gia huỷ chào giá khi người dùng chưa chọn
}
