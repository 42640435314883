import {
  Box,
  Card,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
} from '@mui/material';
import { ICertificate, IExpert } from 'types/Expert';

const TabCertificates = ({ info }: { info?: IExpert }) => {
  return (
    <Stack gap={4}>
      {info?.certificates.map(c => (
        <CardCertificate key={c._id} certificate={c} />
      ))}
    </Stack>
  );
};

export default TabCertificates;

const CardCertificate = ({ certificate }: { certificate: ICertificate }) => {
  const getStatus = (status: number) => {
    switch (status) {
      case -1:
        return 'Từ chối';
      case 1:
        return 'Đã duyệt';
      default:
        return 'Chờ duyệt';
    }
  };
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          <Grid item sm={12} md={6} paddingY={1}>
            <Typography variant="body2" gutterBottom>
              <Stack direction="row" alignItems="center" gap={2} height={40}>
                <strong>ID</strong> {certificate._id}
              </Stack>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} paddingY={1}>
            <Typography variant="body2" gutterBottom>
              <Stack direction="row" alignItems="center" gap={2} height={40}>
                <strong>Tên giấy chứng nhận</strong> {certificate.name}
              </Stack>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} paddingY={1}>
            <Typography variant="body2" gutterBottom>
              <Stack direction="row" alignItems="center" gap={2} height={40}>
                <strong>Mô tả</strong> {certificate.description}
              </Stack>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} paddingY={1}>
            <Typography variant="body2" gutterBottom>
              <Stack direction="row" alignItems="center" gap={2} height={40}>
                <strong>URL</strong>{' '}
                <a href={certificate.url} target="_blank" rel="noreferrer">
                  {certificate.url}
                </a>
              </Stack>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} paddingY={1}>
            <Typography variant="body2" gutterBottom>
              <Stack direction="row" alignItems="center" gap={2} height={40}>
                <strong>Trạng thái</strong> {getStatus(certificate.status)}
              </Stack>
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} paddingY={1}>
            <Stack direction="row" alignItems="center" gap={2}>
              {certificate.images.map(item => {
                return (
                  <img
                    key={item._id}
                    src={item.location}
                    alt={item.name}
                    className="w-[100px] h-[100px] object-cover"
                    loading="lazy"
                  />
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
