import { useSearchParams } from 'react-router-dom';

const useTablePage = () => {
  const DEFAULT_PAGE = 0;
  const DEFAULT_LIMIT = 5;
  const [searchParams, setSearchParams] = useSearchParams({
    page: DEFAULT_PAGE.toString(),
    limit: DEFAULT_LIMIT.toString(),
    sort: '-1',
  });
  const page = Number(searchParams.get('page') || DEFAULT_PAGE);
  const limit = Number(searchParams.get('limit') || DEFAULT_LIMIT);
  const skip = page * limit;

  const handleChangePage = (event: unknown, newPage: any) => {
    setSearchParams(params => {
      params.set('page', newPage.toString());
      return params;
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setSearchParams(params => {
      params.set('page', '0');
      params.set('limit', event.target.value);
      return params;
    });
  };

  return {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    limit,
    skip,
  };
};

export default useTablePage;
