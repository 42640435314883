import { Chip } from '@mui/material';

export const StatusOffers = ({ status }: { status: any }) => {
  const badge: any = {
    '-7': {
      variant: 'error',
      text: 'Đã hủy',
    },
    '-6': {
      variant: 'warning',
      text: 'Chờ duyệt',
    },
    '-3': {
      variant: 'error',
      text: 'Khiếu nại',
    },
    '-2': {
      variant: 'error',
      text: 'Đã hủy',
    },
    '-1': {
      variant: 'error',
      text: 'Đã bị xóa',
    },
    '1': {
      variant: 'success',
      text: 'Đang diễn ra',
    },
    '2': {
      variant: 'success',
      text: 'Đã chọn offer',
    },
    '3': {
      variant: 'warning',
      text: 'Chờ xác nhận',
    },
    '4': {
      variant: 'success',
      text: 'Đã xác nhận',
    },
    '5': {
      variant: 'success',
      text: 'Hoàn thành/chưa thanh toán',
    },
    '6': {
      variant: 'success',
      text: 'Hoàn thành, đã thanh toán',
    },
    '7': {
      variant: 'success',
      text: 'Đã tư vấn',
    },
  };
  return <Chip label={badge[status]?.text} color={badge[status]?.variant} />;
};
