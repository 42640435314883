import {
  VoucherDiscountType,
  VoucherScope,
  VoucherStatus,
  VoucherType,
} from 'types/Voucher';
import { TypeOf, z } from 'zod';

export const voucherSchema = z
  .object({
    voucherName: z
      .string()
      .trim()
      .min(1, 'Tên voucher không được để trống')
      .max(40, 'Tên không được dài quá 40 ký tự'),
    code: z
      .string()
      .trim()
      .min(1, 'Mã voucher không được để trống')
      .max(10, 'Mã voucher không được dài hơn 10 ký tự')
      .regex(
        new RegExp('(^[A-Za-z0-9]*$)'),
        'Mã voucher chỉ bao gồm chữ và số',
      ),
    maxDiscountAmount: z.coerce.number().optional(),
    discountPercentage: z.coerce
      .number()
      .max(100, 'Không hợp lệ')
      .transform(n => n / 100)
      .optional(),
    consultingTime: z.coerce
      .number()
      .min(0, 'Thời gian miễn phí không hợp lệ')
      .optional(),
    discountAmount: z.coerce.number().optional(),
    status: z.nativeEnum(VoucherStatus, {
      invalid_type_error: 'Vui lòng chọn trạng thái',
    }),
    voucherType: z.nativeEnum(VoucherType),
    discountType: z.nativeEnum(VoucherDiscountType),
    // minBookingPrice: z.coerce.number({
    //   invalid_type_error: 'Vui lòng nhập số tiền book tối thiểu',
    // }),
    minBookingPrice: z.coerce.number().optional(),
    quantity: z.coerce.number({ invalid_type_error: 'Vui lòng nhập số lượng' }),
    budget: z.coerce.number({ invalid_type_error: 'Vui lòng nhập ngân sách' }),
    expert: z
      .preprocess(
        value => (typeof value === 'string' ? value : (value as any)._id),
        z.string(),
      )
      .optional(),
    startDate: z.coerce.string({
      invalid_type_error: 'Vui lòng chọn ngày bắt đầu',
      required_error: 'Vui lòng chọn ngày bắt đầu',
    }),
    endDate: z.coerce.string({
      invalid_type_error: 'Vui lòng chọn ngày hết hạn',
      required_error: 'Vui lòng chọn ngày bắt đầu',
    }),
    scope: z
      .nativeEnum(VoucherScope, {
        invalid_type_error: 'Vui lòng chọn',
        required_error: 'Vui lòng chọn',
      })
      .optional(),
    users: z.array(z.string()).optional(),
  })
  .refine(data => new Date(data.startDate) < new Date(data.endDate), {
    message: 'Thời gian hết hạn phải lớn hơn thời gian bắt đầu',
    path: ['endDate'],
  })
  .refine(
    data =>
      data.voucherType === VoucherType.Expert ||
      (data.scope === VoucherScope.SomeUser && (data.users?.length || 0) > 0) ||
      data.scope !== VoucherScope.SomeUser,
    {
      message: 'Vui lòng nhập id',
      path: ['users'],
    },
  )
  .refine(
    data =>
      (data.voucherType === VoucherType.Expert && data.expert) ||
      data.voucherType !== VoucherType.Expert,
    {
      message: 'Vui lòng chọn chuyên gia',
      path: ['expert'],
    },
  )
  .refine(
    data =>
      (data.voucherType === VoucherType.Expert && data.expert) ||
      data.voucherType !== VoucherType.Expert,
    {
      message: 'Vui lòng nhập chuyên gia',
      path: ['expert'],
    },
  )
  .refine(
    data =>
      (data.discountType === VoucherDiscountType.Percent &&
        data.discountPercentage) ||
      data.discountType !== VoucherDiscountType.Percent,
    {
      message: 'Vui lòng nhập phần trăm giảm',
      path: ['discountPercentage'],
    },
  )
  .refine(
    data =>
      (data.discountType === VoucherDiscountType.FlatMoney &&
        data.discountAmount) ||
      data.discountType !== VoucherDiscountType.FlatMoney,
    {
      message: 'Vui lòng nhập số tiền giảm',
      path: ['discountAmount'],
    },
  )
  .refine(
    data =>
      (data.voucherType === VoucherType.Expert &&
        data.discountType === VoucherDiscountType.FlatMoney) ||
      data.voucherType === VoucherType.User ||
      data.discountType === VoucherDiscountType.DISCOUNT_BY_TIME,
    {
      message:
        'Loại voucher chuyên gia không được chọn hình thức giảm giá theo phần trăm',
      path: ['discountType'],
    },
  )
  .refine(
    data =>
      (data.discountType === VoucherDiscountType.FlatMoney &&
        data.discountAmount &&
        data.budget >= data.quantity * data.discountAmount) ||
      (data.discountType === VoucherDiscountType.Percent &&
        data.discountPercentage &&
        data.maxDiscountAmount &&
        data.budget >= data.quantity * data.maxDiscountAmount) ||
      data.discountType === VoucherDiscountType.DISCOUNT_BY_TIME,
    {
      message: 'Số lượng vượt quá ngân sách',
      path: ['quantity'],
    },
  )
  .refine(
    data =>
      (data.discountType === VoucherDiscountType.DISCOUNT_BY_TIME &&
        (data?.consultingTime || 0) > 0) ||
      data.discountType === VoucherDiscountType.FlatMoney ||
      data.discountType === VoucherDiscountType.Percent ||
      data.status === VoucherStatus.Hidden,
    {
      message: 'Vui lòng nhập thời gian',
      path: ['consultingTime'],
    },
  )
  .refine(
    data =>
      data.discountType === VoucherDiscountType.DISCOUNT_BY_TIME ||
      ((data.discountType === VoucherDiscountType.FlatMoney ||
        data.discountType === VoucherDiscountType.Percent) &&
        (data.minBookingPrice || 0) > 0),
    {
      message: 'Vui lòng nhập số tiền book tối thiểu',
      path: ['minBookingPrice'],
    },
  );

export type VoucherInput = TypeOf<typeof voucherSchema>;
