import * as React from 'react';
import { BrowserRouter, Route, Routes, useRoutes } from 'react-router-dom';

import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext';
import BlankLayout from '@core/layouts/BlankLayout';
import ThemeComponent from '@core/theme/ThemeComponent';
import { createEmotionCache } from '@core/utils/create-emotion-cache';
import { CacheProvider } from '@emotion/react';
import UserLayout from 'layouts/UserLayout';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MessageRouter from 'routers/MessageRouter';
import VoucherRouter from 'routers/VoucherRoutes';
import 'styles/global.css';
import Error401 from './pages/401';
import Error404 from './pages/404';
import Error500 from './pages/500';
import AccountSettings from './pages/AccountSettings';
import CardBasic from './pages/Cards';
import { Dashboard } from './pages/Dashboard/Loadable';
import FormLayouts from './pages/FormLayout';
import Icons from './pages/Icons';
import LoginPage from './pages/Login';
import RegisterPage from './pages/Regsiter';
import MUITable from './pages/Tables';
import TypographyPage from './pages/Typography';
import UpcomingOffers from './pages/RequestAndOffer/UpcomingOffers';
import RequestRouter from 'routers/RequestRouter';

//localize dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import RequireLogin from './components/RouteGuard/RouteGuard';
import AccountsRouter from 'routers/AccountRoutes';
import PermissionRouter from 'routers/PermissionRoutes';
import AccountRoute from 'routers/AccountRoutes';
import AppRoutes from 'routers/AppRoutes';
dayjs.locale('vi');

const emotionCache = createEmotionCache();

export function App() {
  return (
    <CacheProvider value={emotionCache}>
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <>
                <ThemeComponent settings={settings}>
                  <BrowserRouter>
                    <AppRoutes />
                  </BrowserRouter>
                </ThemeComponent>
                <ToastContainer />
              </>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </CacheProvider>
  );
}
