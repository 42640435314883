// ** React Imports
import { useState, ChangeEvent } from 'react';

// ** MUI Imports
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import React from 'react';
import { Chip } from '@mui/material';

interface Column {
  id: 'name' | 'code' | 'population' | 'size' | 'density';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: any = [
  { id: '_id', label: 'id', maxWidth: 50 },
  { id: 'content', label: 'Tên gói', maxWidth: 300 },
  { id: 'contactForm', label: 'Hình thức', maxWidth: 300 },
  { id: 'status', label: 'Trạng thái', maxWidth: 300 },
];

interface IPOfferVoucher {
  offerDetail: any;
}

const VoucherOffer = (prop: IPOfferVoucher) => {
  const { offerDetail } = prop;
  // ** States
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const renderStatus = status => {
    if (status === 5 || status === 6) {
      return (
        <div>
          <Chip label="hoàn thành" color="success" />
        </div>
      );
    } else {
      return <Chip label="Chưa hoàn thành" color="error" />;
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 350 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ width: column.maxWidth }}
                  // sx={{ width: 300 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {[offerDetail]
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?._id}>
                    {columns.map(column => {
                      const value = row[column.id];

                      return (
                        <TableCell key={column?.id} align={column?.align}>
                          {column?.id === 'status'
                            ? renderStatus(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={[offerDetail].length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default VoucherOffer;
