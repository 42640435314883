import { ThemeColor } from '@core/layouts/types';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { visuallyHidden } from '@mui/utils';
import CustomPagingAction from 'app/components/Table/CustomPagingAction';
import { VoucherStatusText, headCellsExpert } from 'constants/voucher.constant';
import dayjs from 'dayjs';
import useTablePage from 'hooks/useTablePage';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAllQuery } from 'redux/services/voucher.services';
import { IListResponse } from 'types/GenericResponse';
import { SortOrder, getComparator } from 'utils/sort';
import {
  IVoucher,
  VoucherScope,
  VoucherStatus,
  VoucherType,
} from '../../../../types/Voucher';
import BoxExportFile from '../Component/BoxExportFile';
import VoucherFilter from '../Component/VoucherFilter';
import VoucherDetail from '../VoucherDetail';
import InfoDetailVoucher from '../Component/InfoDetailVoucher';

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const styleModalDetail = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const VoucherExpertPage = () => {
  const [searchParams] = useSearchParams();
  const { handleChangePage, handleChangeRowsPerPage, limit, page, skip } =
    useTablePage();
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IVoucher | undefined>();
  const [listData, setListData] = useState<IListResponse<IVoucher>>({
    isOver: true,
    listData: [],
    total: 0,
  });
  const [voucherData, setVoucherData] = useState<undefined | IVoucher>(
    undefined,
  );
  const [showFilter, setShowFilter] = useState(false);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { data, isFetching } = useGetAllQuery({
    ...paramsToObject(searchParams),
    voucherType: VoucherType.Expert,
    skip: page * limit,
    limit: limit,
  });

  const [open, setOpen] = React.useState(false);
  const [openDetailVoucher, setOpenDetailVoucher] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVoucherData(undefined);
  };

  const handleModalDetail = () => {
    setOpenDetailVoucher(false);
    setVoucherData(undefined);
  };

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IVoucher,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: keyof IVoucher) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  interface StatusObj {
    [key: string]: {
      color: ThemeColor;
    };
  }
  const statusObj: StatusObj = {
    '-1': { color: 'error' },
    '-2': { color: 'error' },
    '-3': { color: 'error' },
    0: { color: 'error' },
    1: { color: 'success' },
    3: { color: 'primary' },
    4: { color: 'warning' },
    5: { color: 'info' },
  };

  const visibleRows = React.useMemo(() => {
    if (orderBy) {
      const comparator = getComparator(order, orderBy);
      return listData.listData.slice().sort(comparator);
    } else {
      return listData.listData;
    }
  }, [order, orderBy, listData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={12} sm={5}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Danh sách voucher chuyên gia
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button
            sx={{ marginRight: 3 }}
            type="button"
            variant="contained"
            onClick={handleOpen}
          >
            Tạo voucher
          </Button>
          {/* <Button type="button" variant="contained" onClick={handleOpen}>
            Tạo voucher
          </Button> */}
          {/* <AddVoucherFile /> */}
          <BoxExportFile data={listData.listData} />
          <Button
            sx={{ marginLeft: 3 }}
            type="button"
            variant="contained"
            onClick={() => setShowFilter(f => !f)}
            endIcon={<FilterAltIcon />}
          >
            Lọc
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showFilter}>
        <Paper sx={{ padding: 5 }}>
          <VoucherFilter />
        </Paper>
      </Collapse>
      <TablePagination
        sx={{
          '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
            justifyContent: 'end',
            flexWrap: 'wrap',
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} trong ${
            count !== -1 ? count : `hơn ${to}`
          } kết quả`;
        }}
        labelRowsPerPage="Số dòng trong trang"
        count={listData.total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={CustomPagingAction}
        slot=""
      />
      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: 'min-content' }}
          aria-label="table in dashboard"
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {headCellsExpert.map(headCell => (
                <TableCell key={headCell.id} sx={{ whiteSpace: 'nowrap' }}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  bgcolor: 'customColors.tableActionCol',
                  borderRight: 'divider',
                }}
              >
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>

          {isFetching ? (
            <TableBody sx={{ textAlign: 'center' }}>
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '20px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {visibleRows.map((data, index) => (
                <TableRow
                  hover
                  key={data._id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{skip + index + 1}</TableCell>
                  <TableCell sx={{ maxWidth: 100 }}>
                    <Tooltip title="Sao chép">
                      <Button
                        fullWidth
                        onClick={() => {
                          navigator.clipboard.writeText(data._id);
                        }}
                      >
                        <Typography noWrap>{data._id}</Typography>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell sx={{ minWidth: 200 }}>
                    <Typography fontSize={14} className="max_line-2">
                      {data.voucherName}
                    </Typography>
                  </TableCell>
                  <TableCell>{data.code}</TableCell>
                  <TableCell>
                    {data.voucherType === VoucherType.User
                      ? data.scope === VoucherScope.AllUser
                        ? '1 số người dùng'
                        : 'Khách hàng chỉ định'
                      : data.expert
                      ? `1 số người dùng`
                      : 'Tất cả chuyên gia'}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={VoucherStatusText[data.status]}
                      color={statusObj[data.status].color}
                      sx={{
                        height: 24,
                        fontSize: '0.75rem',
                        textTransform: 'capitalize',
                        '& .MuiChip-label': { fontWeight: 500 },
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {data.discountAmount?.toLocaleString('en-US') || 'N/A'}
                  </TableCell>
                  <TableCell>
                    {data.minBookingPrice?.toLocaleString('en-US') || 'N/A'}
                  </TableCell>
                  <TableCell>
                    {data.budget?.toLocaleString('en-US') || 'N/A'}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dayjs(data.endDate).format('HH:mm DD-MM-YYYY')}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dayjs(data.startDate).format('HH:mm DD-MM-YYYY')}
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      right: 0,
                      zIndex: 1,
                      bgcolor: 'customColors.tableActionCol',
                    }}
                  >
                    <Stack flexDirection={'row'} gap={5}>
                      {data.status === VoucherStatus.Deleted ? (
                        ''
                      ) : (
                        <IconButton
                          onClick={() => {
                            setVoucherData(data);
                            handleOpen();
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}

                      <IconButton
                        onClick={() => {
                          setVoucherData(data);
                          setOpenDetailVoucher(true);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
            justifyContent: 'end',
            flexWrap: 'wrap',
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} trong ${
            count !== -1 ? count : `hơn ${to}`
          } kết quả`;
        }}
        labelRowsPerPage="Số dòng trong trang"
        count={listData.total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={CustomPagingAction}
      />
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <VoucherDetail
              defaultVoucherData={voucherData}
              handleClose={handleClose}
            />
          </Box>
        </Modal>
      )}
      {openDetailVoucher && (
        <Modal
          open={openDetailVoucher}
          onClose={handleModalDetail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModalDetail}>
            <InfoDetailVoucher
              voucherData={voucherData}
              handleClose={handleModalDetail}
            />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default VoucherExpertPage;
