import { OFFER_TYPE_REQUEST } from 'types/Offer';

export const getTitleOffer = (type: any, experts: string, offer: any) => {
  const getTypeRequest = OFFER_TYPE_REQUEST[type];
  let renderTitle = '';
  switch (type) {
    case 1:
      renderTitle = `"${offer?.title}"`;
      break;
    case 2:
      renderTitle = `"${experts}"`;
      break;
    case 3:
    case 4:
      renderTitle = `"${offer && offer.title.split("'")[1]}"`;
      break;
    default:
      renderTitle = '';
      break;
  }
  return getTypeRequest + renderTitle;
};
