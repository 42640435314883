import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { setCredentials } from 'redux/features/auth/authSlice';
import { IGenericResponse } from 'types/GenericResponse';
import { IUser } from 'types/User';
import { baseQuery } from './fetchQuery';
import { setUser } from 'redux/features/user/userSlice';
import { Cookies } from 'react-cookie';
import { UpdateUserDTO } from 'types/DTO/UpdateUserDTO';

export interface User {
  first_name: string;
  last_name: string;
}

export interface LoginOTPResponse {
  _id: string;
  token: string;
  permissions: Array<number>;
}

export interface LoginSendOTPResponse {
  _id: string;
}

export interface LoginOTPRequest {
  otp: string;
  accountId: string;
}
export interface LoginSendOTPRequest {
  username: string;
  password: string;
}
export interface LoginRequest {
  username: string;
  password: string;
}

const url = EndPoint.account;

export const accountsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'accountsApi',
  tagTypes: ['Accounts'],
  endpoints: builder => ({
    loginSendOTP: builder.mutation<
      IGenericResponse<LoginSendOTPResponse>,
      LoginSendOTPRequest
    >({
      query: credentials => ({
        url: 'login',
        method: 'POST',
        body: credentials,
      }),
    }),
    loginOTP: builder.mutation<
      IGenericResponse<LoginOTPResponse>,
      LoginOTPRequest
    >({
      query: credentials => ({
        url: 'verify-otp',
        method: 'GET',
        params: credentials,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { data },
          } = await queryFulfilled;
          const cookie = new Cookies();
          cookie.set('token', data.token, { path: '/' });
          cookie.set('_id', data._id, { path: '/' });
          cookie.set('permissions', data.permissions, { path: '/' });
          await dispatch(setCredentials(data));
          await dispatch(
            accountsApi.endpoints.getAccountById.initiate(data._id),
          );
        } catch (error) {}
      },
    }),
    get: builder.query<Array<IUser>, void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      transformResponse: (result: IGenericResponse<Array<IUser>>) => {
        return result.data;
      },
      providesTags: ['Accounts'],
    }),
    getAccountById: builder.query<IUser, string>({
      query: id => ({
        url: id,
        method: 'GET',
      }),
      transformResponse: (result: IGenericResponse<IUser>) => result.data,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
        } catch (error) {}
      },
    }),
    createAccount: builder.mutation<
      IGenericResponse<{
        accounts: IUser;
      }>,
      LoginRequest
    >({
      query: credentials => ({
        url: 'create',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: ['Accounts'],
    }),
    updateAccount: builder.mutation<
      IGenericResponse<{
        accounts: IUser;
      }>,
      UpdateUserDTO & { _id: string }
    >({
      query: data => {
        const { _id, ...payload } = data;
        return {
          url: 'update/' + _id,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Accounts'],
    }),
    deleteAccount: builder.mutation<
      IGenericResponse<{
        accounts: IUser;
      }>,
      string
    >({
      query: id => {
        return {
          url: 'delete/' + id,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Accounts'],
    }),
  }),
});

export const {
  // useLoginMutation,
  useLoginSendOTPMutation,
  useLoginOTPMutation,
  useGetQuery: useGetAccountsQuery,
  useCreateAccountMutation,
  useGetAccountByIdQuery,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
} = accountsApi;
