import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';
import { IVoucher, VoucherHistory, VoucherType } from 'types/Voucher';
import { VoucherFilterDTO } from 'types/DTO/VoucherFilterDTO';
import { VoucherDTO } from 'types/DTO/VoucherDTO';

export interface ListVoucherRequest {
  skip: number;
  limit: number;
  sort: -1 | 1 | 'asc' | 'ascending' | 'desc' | 'descending';
  status: number;
  voucherName: string;
  voucherType: VoucherType;
  code: string;
  discountAmount: number;
  startDate: Date;
  endDate: Date;
}

const url = EndPoint.vouchers;

export const vouchersApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'vouchersApi',
  tagTypes: ['Voucher'],
  endpoints: builder => ({
    createVoucher: builder.mutation<IGenericResponse<any>, Partial<VoucherDTO>>(
      {
        query: voucherData => ({
          url: '',
          method: 'POST',
          body: voucherData,
        }),
        invalidatesTags: ['Voucher'],
      },
    ),
    getAll: builder.query<IListResponse<IVoucher>, Partial<VoucherFilterDTO>>({
      query: params => {
        const { page, ...payload } = params as any;
        return {
          url: '',
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Voucher'],
      transformResponse: (
        result: IGenericResponse<IListResponse<IVoucher>>,
      ) => {
        return result.data;
      },
    }),
    editVoucher: builder.mutation<
      IGenericResponse<any>,
      Partial<VoucherDTO> & { _id: string }
    >({
      query: voucherData => {
        const { _id, ...payload } = voucherData;
        return {
          url: `/${_id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Voucher'],
    }),
    deleteVoucher: builder.mutation<IGenericResponse<any>, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Voucher'],
      onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
        await queryFulfilled;
      },
    }),
    sendVerifyOtpVoucher: builder.mutation<IGenericResponse<any>, void>({
      query: () => ({
        url: '/sms',
        method: 'POST',
      }),
    }),
    sendCodeOtpVoucher: builder.mutation<IGenericResponse<any>, string>({
      query: code => {
        return {
          url: `/sms`,
          method: 'GET',
          params: { code },
        };
      },
    }),
    getHistory: builder.query<
      IListResponse<IVoucher>,
      Partial<VoucherFilterDTO>
    >({
      query: params => {
        const { page, ...payload } = params as any;
        return {
          url: 'histories',
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Voucher'],
      transformResponse: (
        result: IGenericResponse<IListResponse<IVoucher>>,
      ) => {
        return result.data;
      },
    }),
    getHistoryDetails: builder.query<
      IListResponse<any>,
      Partial<VoucherFilterDTO & { id: string }>
    >({
      query: params => {
        const { id, ...payload } = params as any;
        return {
          url: 'histories/' + id,
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Voucher'],
      transformResponse: (result: IGenericResponse<any>) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetAllQuery,
  useCreateVoucherMutation,
  useDeleteVoucherMutation,
  useEditVoucherMutation,
  useGetHistoryQuery,
  useSendCodeOtpVoucherMutation,
  useSendVerifyOtpVoucherMutation,
  useGetHistoryDetailsQuery,
} = vouchersApi;
