import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import { notifySuccess } from 'app/components/Toast';
import Magnify from 'mdi-material-ui/Magnify';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useGetPermissionGroupsQuery } from 'redux/services/permissionGroups.services';
import {
  useCreateRoleMutation,
  useDeleteRoleMutation,
  useUpdateRoleMutation,
} from 'redux/services/roles.services';
import { IRoleResponse } from 'types/Role';
import { TypeOf, ZodType, z } from 'zod';

const schema: ZodType<{ roleName: string; permissionGroups: string[] }> = z
  .object({
    roleName: z.string().trim().min(1, 'Vui lòng nhập tên'),
    permissionGroups: z.array(z.string()),
  })
  .refine(data => data.permissionGroups.length > 0, {
    message: 'Yêu cầu ít nhất một nhóm quyền',
    path: ['permissionGroups'],
  });
type FormInput = TypeOf<typeof schema>;

const RoleDetails = ({
  roleData,
  handleClose = () => {
    return;
  },
}: {
  roleData?: IRoleResponse;
  handleClose?: () => void;
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      roleName: roleData?.roleName,
      permissionGroups: roleData?.permissionGroups || [],
    },
  });
  const [filter, setFilter] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const { data: listPermissionGroups } = useGetPermissionGroupsQuery();
  const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation();
  const [createRole, { isLoading: isCreating, error }] =
    useCreateRoleMutation();
  const [deleteRole, { isLoading: isDeleting }] = useDeleteRoleMutation();

  useEffect(() => {
    if (error) {
    }
  }, [error]);

  const submitForm: SubmitHandler<FormInput> = async data => {
    try {
      if (roleData) {
        await updateRole({ _id: roleData._id, ...data }).unwrap();
        handleClose();
        notifySuccess('Cập nhật công');
      } else {
        await createRole(data).unwrap();
        handleClose();
        notifySuccess('Thêm mới thành công');
      }
    } catch {}
  };
  const handleDeleteGroup = () => {
    if (roleData) {
      deleteRole(roleData._id)
        .unwrap()
        .then(() => {
          handleClose();
          toast.success('Xóa thành công');
        })
        .catch(() => {
          toast.error('Đã xảy ra lỗi');
        });
    }
  };

  return (
    <Stack
      spacing={4}
      component={'form'}
      onSubmit={handleSubmit(submitForm)}
      sx={{
        maxWidth: '100vw',
        height: '100vh',
        width: 400,
        padding: 4,
      }}
    >
      <Grid container>
        <Grid item xs={8}>
          <Typography sx={{ typography: 'h6' }}>
            {roleData ? 'Chỉnh sửa' : 'Tạo mới'}
          </Typography>
        </Grid>

        <Grid item xs={4} display={'flex'} justifyContent={'end'}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <ControlledTextField
        fullWidth
        size="small"
        control={control}
        label="Tên nhóm"
        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
        name="roleName"
      />
      <TextField
        size="small"
        sx={{ '& .MuiOutlinedInput-root': { borderRadius: 4 } }}
        onChange={e => {
          setFilter(e.target.value || '');
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Magnify fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      <Box component={Scrollbars}>
        <FormControl>
          <FormHelperText error>
            {errors.permissionGroups?.message}
          </FormHelperText>
          <Controller
            name="permissionGroups"
            control={control}
            render={({ field }) => {
              return (
                <FormGroup>
                  <List>
                    {listPermissionGroups
                      ?.filter(p =>
                        p.groupName
                          .toLowerCase()
                          .includes(filter.toLowerCase()),
                      )
                      ?.map(p => {
                        return (
                          <ListItem key={p._id}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={e => {
                                    if (e.target.checked) {
                                      setValue('permissionGroups', [
                                        ...getValues('permissionGroups'),
                                        p._id,
                                      ]);
                                    } else {
                                      const filtered = getValues(
                                        'permissionGroups',
                                      ).filter(pp => pp !== p._id);
                                      setValue('permissionGroups', filtered);
                                    }
                                  }}
                                  checked={
                                    field.value?.includes(p._id) || false
                                  }
                                />
                              }
                              label={p.groupName}
                            />
                          </ListItem>
                        );
                      })}
                  </List>
                </FormGroup>
              );
            }}
          />
        </FormControl>
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <LoadingButton
          type="submit"
          loading={isUpdating || isCreating || isDeleting}
          variant="contained"
          color="primary"
        >
          Lưu
        </LoadingButton>
        {roleData && (
          <LoadingButton
            loading={isUpdating || isCreating || isDeleting}
            variant="contained"
            color="error"
            onClick={() => setOpenDialog(true)}
            sx={{ marginLeft: 2 }}
          >
            Xóa
          </LoadingButton>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn chắc chắn muốn xóa nhóm quyền này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteGroup} variant="contained" color="error">
            Xóa
          </Button>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default RoleDetails;
