import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import { notifySuccess } from 'app/components/Toast';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useDeleteAccountMutation,
  useUpdateAccountMutation,
} from 'redux/services/accounts.services';
import { useGetRolesQuery } from 'redux/services/roles.services';
import { UpdateUserDTO } from 'types/DTO/UpdateUserDTO';
import { AccountStatus, IUser } from 'types/User';
import { TypeOf, ZodType, z } from 'zod';

const updateUserSchema: ZodType<UpdateUserDTO> = z.object({
  username: z.string(),
  email: z.string(),
  status: z.nativeEnum(AccountStatus),
  phone: z.string(),
  roles: z.array(z.string()),
});

type UpdateInput = TypeOf<typeof updateUserSchema>;

const UpdateAccount = ({
  accountData,
  handleClose = () => {
    return;
  },
}: {
  accountData?: IUser;
  handleClose?: () => void;
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [updateAccount, { isLoading: isUpdating }] = useUpdateAccountMutation();
  const [deleteAccount, { isLoading: isDeleting }] = useDeleteAccountMutation();
  const { data: listRole, isLoading: isLoadingRoles } = useGetRolesQuery();
  const isLoading = isUpdating || isDeleting;
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm<UpdateInput>({
    resolver: zodResolver(updateUserSchema),
    defaultValues: {
      username: accountData?.username,
      status: accountData?.status,
      email: accountData?.email,
      phone: accountData?.phone,
      roles: accountData?.roles.map(r => r._id),
    },
  });
  const submitUpdate: SubmitHandler<UpdateInput> = data => {
    if (accountData?._id) {
      updateAccount({ _id: accountData?._id, ...data })
        .unwrap()
        .then(() => {
          // toast.success('Cập nhật thành công');
          notifySuccess('Cập nhật thành công');
          handleClose();
        });
    }
  };

  const handleDelete = () => {
    if (accountData?._id) {
      deleteAccount(accountData?._id)
        .unwrap()
        .then(() => {
          toast.success('Xóa thành công');
          handleClose();
        });
    }
  };
  return (
    <Container maxWidth="sm">
      <Typography sx={{ typography: 'h5' }}>Cập nhật tài khoản</Typography>
      <Grid
        component={'form'}
        container
        spacing={7}
        rowSpacing={4}
        marginTop={2}
        onSubmit={handleSubmit(submitUpdate)}
      >
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            fullWidth
            size="small"
            control={control}
            name="username"
            label="Username"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            fullWidth
            size="small"
            control={control}
            name="email"
            label="Email"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledTextField
            fullWidth
            size="small"
            control={control}
            name="phone"
            label="Số điện thoại"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel size="small">Trạng thái</InputLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  label="Trạng thái"
                  {...field}
                  size="small"
                  value={field.value}
                >
                  <MenuItem value={AccountStatus.Active}>Hoạt động</MenuItem>
                  <MenuItem value={AccountStatus.Inactive}>
                    Không hoạt động
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormGroup>
            <FormLabel>Role</FormLabel>
            <Grid container>
              {listRole?.map((role, index) => (
                <Grid item xs={12} sm={6} key={role._id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={
                          accountData
                            ? accountData.roles.some(r => r._id === role._id)
                            : undefined
                        }
                        onChange={e => {
                          const list = getValues('roles');
                          if (e.target.checked) {
                            list.push(role._id);
                            setValue('roles', list);
                          } else {
                            setValue(
                              'roles',
                              list.filter(r => r !== role._id),
                            );
                          }
                        }}
                      />
                    }
                    label={role.roleName}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isLoading}
          >
            Chỉnh sửa
          </LoadingButton>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={() => setOpenDialog(true)}
            loading={isLoading}
            sx={{ marginLeft: 2 }}
          >
            Xóa
          </LoadingButton>
        </Grid>
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn chắc chắn muốn xóa tài khoản này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            onClick={handleDelete}
            variant="contained"
            color="error"
          >
            Xóa
          </LoadingButton>
          <LoadingButton
            loading={isLoading}
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
          >
            Hủy
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UpdateAccount;
