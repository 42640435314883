import RoutePermission from 'app/components/RouteGuard/RoutePermission';
import AccountsPage from 'app/pages/Accounts';
import CreateAccountPage from 'app/pages/Accounts/create';
import AccountDetailPage from 'app/pages/Accounts/details';
import { RouteObject } from 'react-router-dom';

const AccountRoutes: RouteObject = {
  path: 'accounts',
  children: [
    {
      index: true,
      element: (
        <RoutePermission permissions={[1]}>
          <AccountsPage />
        </RoutePermission>
      ),
    },
    {
      path: 'create',
      element: (
        <RoutePermission permissions={[0]}>
          <CreateAccountPage />
        </RoutePermission>
      ),
    },
    {
      path: ':userId',
      element: (
        <RoutePermission permissions={[1]}>
          <AccountDetailPage />
        </RoutePermission>
      ),
    },
  ],
};

export default AccountRoutes;
// {permissionRoute([1], { index: true, element: <AccountsPage /> })}
// {permissionRoute([0], { path: 'create', element: <CreateAccountPage /> })}
// {permissionRoute([1], { path: ':userId', element: <AccountDetailPage /> })}
