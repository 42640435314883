import { ICategory } from './Category';
import { IImageModel } from './ImageModel';

export enum EStatusExpert {
  ACTIVE = 1,
  NO_VERIFY = 0,
  BLOCK = -1,
  REMOVE = -2,
}
export enum EAskanyStatusExpert {
  ACCEPT = 1,
  NOT_PROFILE = 0,
  PENDING = -1,
  REJECT = -2,
}
export interface IExpert {
  readonly _id: string;
  expertname: string;
  fullname: string;
  avatar: IImageModel;
  gender: number;
  certificates: Array<ICertificate>;
  isAccountSupport: boolean;
  lang: string;
  languages: Array<any>;
  points: number;
  pointOffer: number;
  createdAt: string;
  lastLogin: string;
  modifiedAt: string;
  status: 1 | 0 | -1 | -2;
  /*
   *   1 - hoat dong ,
   *   0 - chua xac thuc,
   *  -1 - Khoa ,
   *  -2 - xoa
   */
  askanyStatus: 1 | 0 | -1 | -2;
  /*
   *   1 - duyệt ,
   *   0 - default chưa tạo hs chuyên gia
   *   -1 - chờ duyệt
   *   -2 - từ chối
   */
  introStatus: number;
  introduce: string;
  introduceTemp: string;
  auth: {
    email: string;
    phone: string;
  };
  workingExperience: Array<IExperience>;
  skills: Array<ISkill>;
  tags: string[];
  wallet: number;
  experienceYears?: number;
  totalOffer: number;
  totalOfferCreate: number;
  totalOfferPay: number;
  verifyDate: null | string;
  timeLine: Array<any>;
  isVerifyBasic: boolean;
  commissionPercentage?: number;
  callPrice: {
    cost: number;
    totalMinutes?: string;
  };
  callPriceCost?: number;
  meetPrice: {
    cost: number;
    totalMinutes?: string;
  };
  meetPriceCost?: number;
  showPriceCost?: number;
}
export interface IExpertDetail {
  readonly _id: string;
  expertname: string;
  fullname: string;
}

export interface IExperience {
  readonly _id: string;
  author: IExpert;
  category: ICategory;
  categoryAdmin: Array<any>;
  companyName: string;
  description: string;
  draft?: string | null;
  isAdmin: boolean;
  isDraft: boolean;
  isShowProfile: boolean;
  isWorking: boolean;
  position: IPosition;
  reason: string;
  status: number;
  timeEndWork?: null | string;
  timeStartWork: string;
  createdAt: string;
  modifiedAt: string;
}

export interface IPosition {
  readonly _id: string;
  en: any;
  vi: any;
  status: number;
}

export interface ISkill {
  readonly _id: string;
  author: any;
  authorFullname: string;
  authorProvince: number;
  bookbefore: null | any;
  callPrice: any;
  callPriceCost?: number;
  category: ICategory;
  categoryAdmin: Array<any>;
  description: string;
  draft: null | string;
  expExperience: number;
  isAdmin: boolean;
  isDraft: boolean;
  isSpecial: boolean;
  keywords: Array<string>;
  meetPrice: any;
  meetPriceCost: number;
  minMinute: null | number;
  minTimeCall: number;
  minTimeMeet: number;
  minCancelAccept: null | number;
  name: string;
  percentCancel: number;
  priceCost: any;
  ratingCount: number;
  ratingSingleCounts: Array<any>;
  showPrice: any;
  showPriceCost: any;
  stars: number;
  status: number;
  createdAt: string;
  modifiedAt: string;
}

export interface ICertificate {
  readonly _id: string;
  name: string;
  description: string;
  expert: string | IExpert;
  images: Array<IImageModel>;
  isDraft: boolean;
  isShowProfile: boolean;
  createdAt: string;
  updatedAt: string;
  reason: string;
  status: number;
  url: string;
  draft: ICertificate & { oldStatus: number };
}
