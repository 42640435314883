import { zodResolver } from '@hookform/resolvers/zod';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  useSendCodeOtpSaleMutation,
  useSendVerifyOtpSaleMutation,
} from 'redux/services/sale.services';
import { object, string } from 'zod';

const schema = object({
  otp: string().min(1, 'Vui lòng nhập mã OTP!'),
});

const SaleOtp = forwardRef<
  {
    handleOTP: () => Promise<boolean>;
  },
  unknown
>((_, ref) => {
  const [sendCodeOTP] = useSendCodeOtpSaleMutation();
  const [resendTimer, setResendTimer] = useState(60);
  const [getOTP] = useSendVerifyOtpSaleMutation();
  const timerRef = useRef<NodeJS.Timeout>();
  const methods = useForm({
    resolver: zodResolver(schema),
  });
  const {
    control,
    setError,
    clearErrors,
    getValues,
    formState: { errors },
  } = methods;

  const handleGetOTP = useCallback(() => {
    setResendTimer(60);
    const request = getOTP();
    return request;
  }, []);

  useEffect(() => {
    if (resendTimer > 0) {
      timerRef.current = setTimeout(() => {
        setResendTimer(t => t - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [resendTimer]);

  useEffect(() => {
    const request = handleGetOTP();
    return () => {
      request.abort();
    };
  }, []);

  const submitOTP = async () => {
    try {
      await sendCodeOTP(getValues('otp'));
      return true;
    } catch {
      setError('otp', { message: 'Sai mã OTP' });
      return false;
    }
  };
  useImperativeHandle(ref, () => ({
    handleOTP() {
      return submitOTP();
    },
  }));
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <form>
        <Controller
          control={control}
          rules={{ validate: value => value.length === 6 }}
          render={({ field, fieldState }) => (
            <Box>
              <MuiOtpInput
                sx={{ gap: 3 }}
                {...field}
                onChange={value => {
                  field.onChange(value);
                  if (value.length >= 6) {
                  } else {
                    clearErrors();
                  }
                }}
                length={6}
              />
              <FormHelperText
                style={{ marginTop: '15px' }}
                error={!!errors.otp}
              >
                {errors.otp?.message as string}
              </FormHelperText>
            </Box>
          )}
          name="otp"
        />
      </form>
      <Box display={'flex'} alignItems={'center'} sx={{ marginTop: 4 }}>
        <Button
          size="small"
          variant="text"
          color="primary"
          endIcon={<ReplayIcon />}
          disabled={resendTimer > 0}
        >
          {resendTimer > 0 &&
            dayjs()
              .set('minute', 0)
              .set('second', resendTimer)
              .format('mm:ss')}{' '}
          Gửi lại mã OTP
        </Button>
      </Box>
    </Box>
  );
});

export default SaleOtp;
