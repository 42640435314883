import EditIcon from '@mui/icons-material/Edit';
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetPermissionGroupsQuery } from 'redux/services/permissionGroups.services';
import { useGetRolesQuery } from 'redux/services/roles.services';
import { IRoleResponse } from 'types/Role';
import RoleDetails from './RoleDetails';

const RolesPage = () => {
  const [searchParams] = useSearchParams({
    page: '0',
    limit: '10',
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const page = Number(searchParams.get('page') || 0);
  const limit = Number(searchParams.get('limit') || 10);
  const skip = page * limit;
  const [role, setRole] = useState<undefined | IRoleResponse>(undefined);
  // const [updateRole, { isLoading: isUpdating }] = useUpdateRoleMutation();
  const { data: listRole } = useGetRolesQuery();
  const { data: listPermissionGroup, isLoading: isLoadingPG } =
    useGetPermissionGroupsQuery();

  const handleOpen = () => {
    setOpenDrawer(true);
  };
  const handleClose = () => {
    setOpenDrawer(false);
    setRole(undefined);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Danh sách role
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="button" variant="contained" onClick={handleOpen}>
            Tạo role
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%', minWidth: 'min-content' }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>id</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell>Nhóm quyền</TableCell>
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  bgcolor: 'background.paper',
                  borderRight: 'divider',
                }}
              >
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listRole?.map((data, index) => (
              <TableRow
                key={data._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{skip + index + 1}</TableCell>
                <TableCell sx={{ maxWidth: 100 }}>
                  <Tooltip title="Sao chép">
                    <Button
                      fullWidth
                      onClick={() => {
                        navigator.clipboard.writeText(data._id);
                      }}
                    >
                      <Typography noWrap>{data._id}</Typography>
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>{data.roleName}</TableCell>
                <TableCell>
                  <Autocomplete
                    multiple
                    loading={isLoadingPG}
                    value={data.permissionGroups}
                    isOptionEqualToValue={(option, value) => option === value}
                    getOptionLabel={option =>
                      listPermissionGroup?.find(p => p._id === option)
                        ?.groupName || ''
                    }
                    // placeholder="Chưa chọn nhóm quyền"
                    renderInput={params => (
                      <TextField variant="standard" {...params} />
                    )}
                    options={listPermissionGroup?.map(pg => pg._id) || []}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    position: 'sticky',
                    right: 0,
                    zIndex: 1,
                    bgcolor: 'background.paper',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setRole(data);
                      handleOpen();
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Drawer open={openDrawer} onClose={handleClose} anchor="right">
        <RoleDetails roleData={role} handleClose={handleClose} />
      </Drawer>
    </Box>
  );
};

export default RolesPage;
