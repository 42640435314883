import { OutlinedInput, OutlinedInputProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface Props {
  name: string;
  control: Control<any>;
}

const ControlledOutlinedInput = (props: Props & OutlinedInputProps) => {
  const { control, name, ...outlinedProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <OutlinedInput
          {...field}
          {...outlinedProps}
          value={field.value || ''}
          onBlur={e => {
            field.onBlur();
            outlinedProps.onBlur?.(e);
          }}
          onChange={e => {
            field.onChange(e);
            outlinedProps.onChange?.(e);
          }}
        />
      )}
    />
  );
};

export default ControlledOutlinedInput;
