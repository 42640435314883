import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { IExperience, IExpert } from 'types/Expert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';

const TabExperience = ({ info }: { info?: IExpert }) => {
  return (
    <Stack gap={4}>
      {info?.workingExperience.map(exp => (
        <CardExperience key={exp._id} experience={exp} />
      ))}
    </Stack>
  );
};

export default TabExperience;

const CardExperience = ({ experience }: { experience: IExperience }) => {
  const listShow = [
    {
      key: 'ID',
      value: experience._id,
    },
    {
      key: 'Tên công ty',
      value: experience.companyName,
    },
    {
      key: 'Chuyên môn',
      value: experience.category.name,
    },
    {
      key: 'Chức vụ',
      value: experience.position.vi.name,
    },
    {
      key: 'Bắt đầu',
      value: dayjs(experience.timeStartWork).format('dd/MM/YYYY'),
    },
    {
      key: 'Kết thúc',
      value: experience.timeEndWork
        ? dayjs(experience.timeEndWork).format('dd/MM/YYYY')
        : 'Chưa xác định',
    },
    {
      key: 'Tình trạng',
      value: experience.status === 1 ? 'Đang làm' : 'Đã nghỉ',
    },
    {
      key: 'Hiển thị trên hồ sơ',
      value: (
        <ToggleButtonGroup
          value={'true'}
          onChange={(e, value) => {
            // console.log('change', value);
          }}
          size="small"
        >
          <ToggleButton value="true">
            <VisibilityIcon />
          </ToggleButton>
          <ToggleButton value="false">
            <VisibilityOffIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      ),
    },
    {
      key: 'Trạng thái',
      value: experience.status === 1 ? 'Hoạt động' : 'Không hoạt động',
    },
    {
      key: (
        <Link
          component={RouterLink}
          to={'/experiences/' + experience._id}
          target="_blank"
        >
          Chi tiết
        </Link>
      ),
    },
  ];
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          {listShow.map((detail, index) => (
            <Grid key={index} item sm={12} md={6} paddingY={1}>
              <Typography variant="body2" gutterBottom>
                <Stack direction="row" alignItems="center" gap={2} height={40}>
                  <strong>{detail.key}</strong> {detail.value}
                </Stack>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
