import { useEffect, useState } from 'react';

// ** MUI Imports
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// ** Third Party Imports

// ** Icons Imports
import { zodResolver } from '@hookform/resolvers/zod';
import { Autocomplete, Avatar, Chip, debounce, Paper } from '@mui/material';
import UserOption from 'app/components/AutocompleteComponents/UserOption';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useGetExpertsMutation } from 'redux/services/experts.services';
import {
  NotificationInput,
  notificationSchema,
} from 'schema/notification.schema';
import { AppUser } from 'types/AppUser';
import { IExpert } from 'types/Expert';
import { NotifyScope } from 'types/Notification.type';
import Dialoghandle from 'app/components/DialogHandle';
import ControlledRadioButtonsGroup from 'app/components/ControlledRadioButtonsGroup';
import { useCreateNotificationMutation } from 'redux/services/notification.service';
import { notifyError, notifySuccess } from 'app/components/Toast';
import { SYSTEM_CODE_AC } from 'constants/systemCode';
import { useGetCategoriesMutation } from 'redux/services/categories.services';
import { typography } from '@mui/system';
import CategoriesOption from 'app/components/AutocompleteComponents/CategoriesOption';
import { OPTION_NOTI_ROUTE } from 'constants/notification.constants';

const FormLayoutsSeparator = () => {
  const methods = useForm<NotificationInput>({
    resolver: zodResolver(notificationSchema),
    mode: 'onChange',
    defaultValues: {
      title: '',
      body: '',
      route: '',
      to: [],
      expert: '',
      target: 0,
      isRecord: false,
    },
  });
  const {
    handleSubmit,
    control,
    clearErrors,
    watch,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = methods;

  const [expertsPicked, setExpertsPicked] = useState<Array<IExpert>>([]);
  const [userPicked, setUserPicked] = useState<Array<IExpert>>([]);
  const [categoriesPicked, setCategoriesPicked] = useState<Array<any>>([]);
  const [createNotification, { isError: errorCreate }] =
    useCreateNotificationMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [getExperts, { isLoading: isLoadingExperts, data: listExperts }] =
    useGetExpertsMutation();
  const [getUsers, { isLoading: isLoadingUsers, data: listUsers }] =
    useGetExpertsMutation();
  const [
    getCategories,
    { isLoading: isLoadingCategories, data: listCategories },
  ] = useGetCategoriesMutation();

  const notifyScope = watch('target');

  useEffect(() => {
    setUserPicked([]);
    setCategoriesPicked([]);
  }, [notifyScope]);

  const checkCanDelete = (deleteFn: (event: any) => void, user: AppUser) => {
    return undefined;
  };

  const submitForm: SubmitHandler<NotificationInput> = async data => {
    if (!data.expert) delete data.expert;
    if (!data.route) delete data.route;
    await createNotification(data)
      .unwrap()
      .then(() => {
        notifySuccess('Thêm mới thành công');
        //  handleClose();
        reset();
        setValue('isRecord', false);
        setCategoriesPicked([]);
        setUserPicked([]);
        setExpertsPicked([]);
      })
      .catch(e => {
        notifyError(SYSTEM_CODE_AC[e.data?.systemCode]);
      });
    setOpen(false);
  };
  const HandleSendNotiForm = () => {
    handleSubmit(submitForm)();
  };

  const HandleOpenForm = () => {
    setOpen(true);
  };

  return (
    <Card>
      <CardHeader
        title="Tạo thông báo"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider sx={{ margin: 0 }} />
      <form onSubmit={handleSubmit(HandleOpenForm)}>
        <CardContent>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                1. Chi tiết thông báo
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Tiêu đề
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ControlledTextField
                fullWidth
                name="title"
                control={control}
                type="text"
                placeholder="Nhập tiêu đề thông báo"
                size="small"
                inputProps={{
                  style: { fontSize: 15, padding: 12, lineHeight: 20 },
                }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                error={!!errors.title}
                helperText={errors.title?.message}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Nội dung thông báo
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <ControlledTextField
                fullWidth
                control={control}
                name="body"
                multiline
                minRows={3}
                placeholder="Nội dung thông báo..."
                sx={{ '& .MuiOutlinedInput-root': { alignItems: 'baseline' } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                error={!!errors.body}
                helperText={errors.body?.message}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Route chuyển hướng
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              {/* <ControlledTextField
                fullWidth
                control={control}
                name="router"
                placeholder="https://abc.com"
                sx={{ '& .MuiOutlinedInput-root': { alignItems: 'baseline' } }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                error={!!errors.router}
                helperText={errors.router?.message}
              /> */}
              <Grid item xs={12} sm={9}>
                <FormControl style={{ maxWidth: '600px', minWidth: '300px' }}>
                  <Controller
                    name="route"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        value={field.value ?? ''}
                        error={!!errors.route}
                        size="small"
                        input={
                          <OutlinedInput
                            // label="lựa chọn"
                            sx={{ fontSize: '15px' }}
                          />
                        }
                      >
                        <MenuItem value="">Không lựa chọn</MenuItem>
                        {OPTION_NOTI_ROUTE.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item?.title}>
                              {item?.desc} - {item?.title}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Đính kèm chuyên gia
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Autocomplete
                freeSolo
                multiple
                value={expertsPicked}
                onChange={(e, newValue) => {
                  const mappedValue = newValue.map(u => {
                    if (typeof u === 'string') {
                      return {
                        _id: u,
                        fullname: u,
                      } as IExpert;
                    }
                    return u;
                  });
                  setExpertsPicked(mappedValue);
                  // setValue(
                  //   'expert',
                  //   mappedValue.map(e => e._id),
                  // );
                  setValue('expert', mappedValue[0]._id);
                }}
                PaperComponent={props => <Paper {...props} elevation={24} />}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.fullname
                }
                renderTags={(experts, getTagProps, state) =>
                  experts.map((u, index) => (
                    <Chip
                      key={u._id ?? u}
                      variant="outlined"
                      avatar={
                        <Avatar alt={u.fullname} src={u.avatar?.location} />
                      }
                      label={u.fullname ?? u}
                      onDelete={checkCanDelete(
                        getTagProps({ index }).onDelete,
                        u,
                      )}
                    ></Chip>
                  ))
                }
                onInputChange={debounce(
                  (event, newInputEvent) =>
                    newInputEvent &&
                    getExperts({
                      search: newInputEvent,
                      limit: 20,
                    }),
                  500,
                )}
                filterOptions={x => x}
                loading={isLoadingExperts}
                isOptionEqualToValue={(option, value) =>
                  value._id === option._id
                }
                renderInput={params => (
                  <TextField
                    {...params}
                    multiline
                    // label="Người dùng"
                    error={!!errors.expert}
                    helperText={errors.expert?.message}
                  />
                )}
                renderOption={(props, option) => (
                  <UserOption {...props} expert={option} />
                )}
                options={listExperts?.listData || []}
                noOptionsText={'Không tìm thấy chuyên gia'}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ marginBottom: 0 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                2. Thông tin người nhận
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Hình thức
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Controller
                name="isRecord"
                control={control}
                render={({ field, fieldState }) => (
                  <ControlledRadioButtonsGroup
                    setValueRecord={setValue}
                    name="isRecord"
                    valueRadio={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                Phạm vi
              </Typography>
            </Grid>

            <Grid item xs={12} sm={9}>
              <FormControl style={{ maxWidth: '600px', minWidth: '300px' }}>
                <Controller
                  name="target"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={field.value ?? ''}
                      error={!!errors.target}
                      size="small"
                      input={<OutlinedInput sx={{ fontSize: '15px' }} />}
                    >
                      <MenuItem value={NotifyScope.AllUser}>
                        Tất cả người dùng
                      </MenuItem>
                      <MenuItem value={NotifyScope.SomeUser}>
                        Một số người dùng
                      </MenuItem>
                      <MenuItem value={NotifyScope.AllTopics}>
                        Theo chủ đề
                      </MenuItem>
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            {notifyScope === NotifyScope.SomeUser && (
              <>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Danh sách người nhận
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Autocomplete
                    freeSolo
                    multiple
                    value={userPicked}
                    onChange={(e, newValue) => {
                      const mappedValue = newValue.map(u => {
                        if (typeof u === 'string') {
                          return {
                            _id: u,
                            fullname: u,
                          } as AppUser;
                        }
                        return u;
                      });
                      setUserPicked(mappedValue);
                      setValue(
                        'to',
                        mappedValue.map(e => e._id),
                      );
                      if ((mappedValue?.length || 0) > 0) {
                        clearErrors('to');
                      } else {
                        trigger('to');
                      }
                    }}
                    PaperComponent={props => (
                      <Paper {...props} elevation={24} />
                    )}
                    getOptionLabel={option =>
                      typeof option === 'string' ? option : option.fullname
                    }
                    renderTags={(users, getTagProps, state) =>
                      users.map((u, index) => (
                        <Chip
                          key={u._id ?? u}
                          variant="outlined"
                          avatar={
                            <Avatar alt={u.fullname} src={u.avatar?.location} />
                          }
                          label={u.fullname ?? u}
                          onDelete={checkCanDelete(
                            getTagProps({ index }).onDelete,
                            u,
                          )}
                        ></Chip>
                      ))
                    }
                    onInputChange={debounce(
                      (event, newInputEvent) =>
                        newInputEvent &&
                        getUsers({
                          search: newInputEvent,
                          limit: 20,
                        }),
                      500,
                    )}
                    filterOptions={x => x}
                    loading={isLoadingUsers}
                    isOptionEqualToValue={(option, value) =>
                      value._id === option._id
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        multiline
                        // label="Người dùng"
                        error={!!errors.to}
                        helperText={errors.to?.message}
                      />
                    )}
                    renderOption={(props, option) => (
                      <UserOption {...props} expert={option} />
                    )}
                    options={listUsers?.listData || []}
                    noOptionsText={'Không tìm thấy người dùng'}
                  />
                </Grid>
              </>
            )}
            {notifyScope === NotifyScope.AllTopics && (
              <>
                <Grid item xs={12} sm={3}>
                  <Typography variant="body2" sx={{ fontWeight: 600 }}>
                    Danh sách người nhận
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Autocomplete
                    freeSolo
                    multiple
                    value={categoriesPicked}
                    onChange={(e, newValue) => {
                      const mappedValue = newValue.map(u => {
                        if (typeof u === 'string') {
                          return {
                            _id: u,
                            name: u,
                          } as any;
                        }
                        return u;
                      });
                      setCategoriesPicked(mappedValue);
                      setValue(
                        'categories',
                        mappedValue.map(e => e._id),
                      );
                      if ((mappedValue?.length || 0) > 0) {
                        clearErrors('categories');
                      } else {
                        trigger('categories');
                      }
                    }}
                    PaperComponent={props => (
                      <Paper {...props} elevation={24} />
                    )}
                    getOptionLabel={option =>
                      typeof option === 'string' ? option : option.name
                    }
                    onInputChange={debounce(
                      (event, newInputEvent) =>
                        newInputEvent &&
                        getCategories({
                          search: newInputEvent,
                          limit: 20,
                        })
                          .unwrap()
                          .then(res => {
                            // console.log('res: ', res);
                          }),
                      500,
                    )}
                    filterOptions={x => x}
                    loading={isLoadingCategories}
                    isOptionEqualToValue={(option, value) =>
                      value._id === option._id
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        multiline
                        // label="Người dùng"
                        error={!!errors.categories}
                        helperText={errors.categories?.message}
                      />
                    )}
                    renderOption={(props, option) => (
                      // <UserOption {...props} expert={option} />
                      <CategoriesOption {...props} categories={option} />
                    )}
                    options={listCategories || []}
                    noOptionsText={'Không tìm thấy người dùng'}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
        <Divider sx={{ margin: 0 }} />
        <CardActions>
          <Button
            size="medium"
            type="submit"
            sx={{ mr: 2 }}
            variant="contained"
          >
            Gửi thông báo
          </Button>
          <Button size="medium" color="error" variant="outlined">
            Xóa
          </Button>
        </CardActions>
      </form>
      {open && (
        <Dialoghandle
          title="Lưu ý"
          body="Thông báo sẽ được gửi tới người nhận, bạn có muốn tiếp tục không!"
          open={open}
          onClose={() => setOpen(false)}
          onHandle={() => HandleSendNotiForm()}
        />
      )}
    </Card>
  );
};

export default FormLayoutsSeparator;
