import RoutePermission from 'app/components/RouteGuard/RoutePermission';
import PermissionPage from 'app/pages/Permission/PermissionGroup';
import PermissionGroupDetails from 'app/pages/Permission/PermissionGroupDetails';
import RolesPage from 'app/pages/Permission/Roles';
import { Route, RouteObject } from 'react-router-dom';

const PermissionRoutes: RouteObject = {
  children: [
    {
      path: 'permission-groups',
      element: (
        <RoutePermission permissions={[10]}>
          <PermissionPage />
        </RoutePermission>
      ),
    },
    {
      path: 'permission-groups/create',
      element: (
        <RoutePermission permissions={[10]}>
          <PermissionGroupDetails />
        </RoutePermission>
      ),
    },
    {
      path: 'permission-groups/:id',
      element: (
        <RoutePermission permissions={[10]}>
          <PermissionGroupDetails />
        </RoutePermission>
      ),
    },
    {
      path: 'roles',
      element: (
        <RoutePermission permissions={[5]}>
          <RolesPage />
        </RoutePermission>
      ),
    },
  ],
};

export default PermissionRoutes;
