import RoutePermission from 'app/components/RouteGuard/RoutePermission';
import ExperiencesPage from 'app/pages/Experiences';
import ExperienceDetailPage from 'app/pages/Experiences/ExperienceDetail';
import ExpertDetailPage from 'app/pages/Experts/ExpertDetail';
import { RouteObject } from 'react-router-dom';

const ExperiencesRoutes: RouteObject = {
  path: 'experiences',
  children: [
    {
      index: true,
      element: (
        <RoutePermission permissions={[]}>
          <ExperiencesPage />
        </RoutePermission>
      ),
    },
    {
      path: 'create',
      element: <RoutePermission permissions={[]}>Chưa tạo</RoutePermission>,
    },
    {
      path: ':experienceId',
      element: (
        <RoutePermission permissions={[]}>
          <ExperienceDetailPage />
        </RoutePermission>
      ),
    },
  ],
};

export default ExperiencesRoutes;
