import { IRole } from './Role';

export interface IUser {
  readonly _id: string;
  username: string;
  status: AccountStatus;
  email: string;
  phone: string;
  roles: Array<IRole>;
  deletedAt: null | string;
}

export interface UserDTO {
  username: string;
  status: AccountStatus;
  email: string;
  phone: string;
  roles: Array<string>;
}

export enum AccountStatus {
  Inactive = 0,
  Active = 1,
}
