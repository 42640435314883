import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';
import { VoucherType } from 'types/Voucher';
import { NotificationDTO } from 'types/DTO/NotificationDTO';

export interface ListNotificationRequest {
  skip: number;
  limit: number;
  sort: -1 | 1 | 'asc' | 'ascending' | 'desc' | 'descending';
  status: number;
  voucherName: string;
  voucherType: VoucherType;
  code: string;
  discountAmount: number;
  startDate: Date;
  endDate: Date;
}

const url = EndPoint.notification;

export const notificationsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'notificationsApi',
  tagTypes: ['Notification'],
  endpoints: builder => ({
    createNotification: builder.mutation<
      IGenericResponse<any>,
      Partial<NotificationDTO>
    >({
      query: notificationData => ({
        url: '',
        method: 'POST',
        body: notificationData,
      }),
      invalidatesTags: ['Notification'],
    }),
    getAllNotifications: builder.query<IListResponse<any>, Partial<any>>({
      query: params => {
        const { page, ...payload } = params as any;
        return {
          url: '',
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Notification'],
      transformResponse: (result: IGenericResponse<IListResponse<any>>) => {
        return result.data;
      },
    }),
  }),
});

export const { useCreateNotificationMutation, useGetAllNotificationsQuery } =
  notificationsApi;
