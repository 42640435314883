import { configureStore, Middleware } from '@reduxjs/toolkit';
import { accountsApi } from 'redux/services/accounts.services';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlice';
import { vouchersApi } from 'redux/services/voucher.services';
import { permissionGroupsApi } from 'redux/services/permissionGroups.services';
import { rolesApi } from 'redux/services/roles.services';
import { permissionsApi } from 'redux/services/permissions.service';
import { featuresApi } from 'redux/services/features.services';
import { expertsApi } from 'redux/services/experts.services';
import { rtkQueryErrorLogger } from 'redux/middlewares/RtkQueryErrorLogger';
import { categoriesApi } from 'redux/services/categories.services';
import { notificationsApi } from 'redux/services/notification.service';
import { transactionsApi } from 'redux/services/transactions.services';
import { salesApi } from 'redux/services/sale.services';

export const store = configureStore({
  reducer: {
    [accountsApi.reducerPath]: accountsApi.reducer,
    [vouchersApi.reducerPath]: vouchersApi.reducer,
    [permissionGroupsApi.reducerPath]: permissionGroupsApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
    [featuresApi.reducerPath]: featuresApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [expertsApi.reducerPath]: expertsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    [transactionsApi.reducerPath]: transactionsApi.reducer,
    [salesApi.reducerPath]: salesApi.reducer,
    authState: authReducer,
    userState: userReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware()
      .concat(accountsApi.middleware)
      .concat(vouchersApi.middleware)
      .concat(permissionGroupsApi.middleware)
      .concat(permissionsApi.middleware)
      .concat(featuresApi.middleware)
      .concat(rolesApi.middleware)
      .concat(expertsApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(notificationsApi.middleware)
      .concat(transactionsApi.middleware)
      .concat(salesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
