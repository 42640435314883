import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ICardMedia } from 'types/LayoutHome.type';
import { CardActionArea } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';

interface IDataCardMedia {
  data?: ICardMedia;
  onEdit?: () => void;
  onDelete?: () => void;
}

const MediaCard = (props: IDataCardMedia) => {
  const {
    data,
    onEdit = () => {
      return;
    },
    onDelete = () => {
      return;
    },
  } = props;
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={data?.thumbnail}
          alt="green iguana"
        />
        <CardContent style={{ padding: '10px' }}>
          <Typography
            style={{ fontSize: '14px', fontWeight: 'bold' }}
            variant="body2"
            color="text.secondary"
          >
            {data?.tag}
          </Typography>
          <Typography
            style={{ fontSize: '16px' }}
            gutterBottom
            variant="h6"
            component="div"
          >
            {data?.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ padding: '10px' }}>
        <Button
          type="button"
          onClick={onDelete}
          size="small"
          variant="outlined"
          startIcon={<DeleteIcon />}
        >
          Xóa
        </Button>
        <Button type="button" onClick={onEdit} size="small" variant="contained">
          Chỉnh sửa
        </Button>
      </CardActions>
    </Card>
  );
};

export default MediaCard;
