import { PropsWithChildren } from 'react';
import { useCookies } from 'react-cookie';

const PermissionWrapper: React.FC<
  PropsWithChildren & { permissions: number[] }
> = ({ permissions, children }) => {
  const [cookies] = useCookies(['permissions']);
  const accountPermissions = cookies.permissions || [];
  if (!permissions.some(p => accountPermissions.includes(p))) {
    return <></>;
  }
  return <>{children}</>;
};

export default PermissionWrapper;
