import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { Cookies } from 'react-cookie';
import { toast } from 'react-toastify';
import { RootState } from 'redux/store/store';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const baseQuery = (url: string) => {
  return fetchBaseQuery({
    baseUrl: `${BASE_URL}/${url}/`,
    prepareHeaders: (headers, { getState }) => {
      const cookies = new Cookies();
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = cookies.get('token');
      headers.set('Content-Type', `application/json`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  });
};

export const onQueryStartedErrorToast = async (args, { queryFulfilled }) => {
  try {
    await queryFulfilled;
  } catch (error) {
    // if ((error as any)?.error?.data?.message) {
    //   toast.error((error as any)?.error?.data?.message);
    // } else {
    //   toast.error('Đã xảy ra lỗi');
    // }
  }
};
