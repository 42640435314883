import { forwardRef, useState } from 'react';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

// ** Third Party Imports
import DatePicker from 'react-datepicker';

// ** Styled Components
import DatePickerWrapper from '@core/styles/libs/react-datepicker';
import React from 'react';
import RichTextEditor from 'app/components/RichTextEditor';
import { IExpert } from 'types/Expert';
import { Box } from '@mui/system';
import {
  useApproveExpertIntroductionMutation,
  useUpdateExpertMutation,
} from 'redux/services/experts.services';
import { useGetCategoriesMutation } from 'redux/services/categories.services';
import CategoriesOption from 'app/components/AutocompleteComponents/CategoriesOption';
import { Autocomplete, Paper, Stack, debounce } from '@mui/material';
import { notifyError, notifySuccess } from 'app/components/Toast';

const TabInfo = ({ info }: { info?: IExpert }) => {
  // ** State
  const [value, setValue] = useState(info?.introduce);
  const [valueTemp, setValueTemp] = useState(info?.introduce);
  const [updateExpert] = useUpdateExpertMutation();
  const [approve] = useApproveExpertIntroductionMutation();
  const updateIntroduce = () => {
    if (info?._id) {
      updateExpert({
        id: info?._id,
        payload: {
          introduce: value,
        },
      })
        .then(() => {
          notifySuccess('Cập nhật thành công');
        })
        .catch(() => {
          notifyError('Đã xảy ra lỗi');
        });
    }
  };
  const approveIntroduceTemp = () => {
    if (info?._id) {
      approve({
        _id: info?._id,
        introduceTemp: valueTemp || '',
        status: 1,
      })
        .then(res => {
          notifySuccess('Duyệt thành công');
        })
        .catch(() => {
          notifyError('Đã xảy ra lỗi');
        });
    }
  };
  const declineIntroduceTemp = () => {
    if (info?._id) {
      approve({
        _id: info?._id,
        introduceTemp: valueTemp || '',
        status: -1,
      })
        .then(() => {
          notifySuccess('Từ chối thành công');
        })
        .catch(() => {
          notifyError('Đã xảy ra lỗi');
        });
    }
  };
  return (
    <CardContent>
      <form>
        <Grid container spacing={7}>
          <Grid item xs={12} sx={{ marginTop: 4.8 }}>
            <RichTextEditor
              id="intro"
              data={info?.introduce}
              onChange={v => {
                setValue(v);
              }}
            />
            <Button
              sx={{ marginTop: 2 }}
              variant="contained"
              onClick={updateIntroduce}
            >
              Cập nhật
            </Button>
          </Grid>
          {info?.introduceTemp && (
            <>
              <Grid item xs={12} sx={{ marginTop: 4.8 }}>
                <RichTextEditor
                  id="intro"
                  data={info?.introduceTemp}
                  onChange={v => {
                    setValueTemp(v);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack gap={2} direction={'row'}>
                  <Button variant="contained" onClick={approveIntroduceTemp}>
                    Duyệt
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={declineIntroduceTemp}
                  >
                    Từ chối
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
          {/* <Grid item xs={12} sx={{ marginTop: 4.8 }}>
            <RichTextEditor
              id="tempintro"
              data={info?.introduceTemp}
              onChange={v => {
                setValue(v);
              }}
              parseHTML={false}
            />
            <Button variant="contained" onClick={save}>
              save
            </Button>
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <DatePickerWrapper>
              <DatePicker
                selected={date}
                showYearDropdown
                showMonthDropdown
                id="account-settings-date"
                placeholderText="MM-DD-YYYY"
                customInput={<CustomInput />}
                onChange={(date: Date) => setDate(date)}
              />
            </DatePickerWrapper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              type="number"
              label="Phone"
              placeholder="(123) 456-7890"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Website"
              placeholder="https://example.com/"
              defaultValue="https://themeselection.com/"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Country</InputLabel>
              <Select label="Country" defaultValue="USA">
                <MenuItem value="USA">USA</MenuItem>
                <MenuItem value="UK">UK</MenuItem>
                <MenuItem value="Australia">Australia</MenuItem>
                <MenuItem value="Germany">Germany</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="form-layouts-separator-multiple-select-label">
                Languages
              </InputLabel>
              <Select
                multiple
                defaultValue={['English']}
                id="account-settings-multiple-select"
                labelId="account-settings-multiple-select-label"
                input={
                  <OutlinedInput
                    label="Languages"
                    id="select-multiple-language"
                  />
                }
              >
                <MenuItem value="English">English</MenuItem>
                <MenuItem value="French">French</MenuItem>
                <MenuItem value="Spanish">Spanish</MenuItem>
                <MenuItem value="Portuguese">Portuguese</MenuItem>
                <MenuItem value="Italian">Italian</MenuItem>
                <MenuItem value="German">German</MenuItem>
                <MenuItem value="Arabic">Arabic</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl>
              <FormLabel sx={{ fontSize: '0.875rem' }}>Gender</FormLabel>
              <RadioGroup
                row
                defaultValue="male"
                aria-label="gender"
                name="account-settings-info-radio"
              >
                <FormControlLabel
                  value="male"
                  label="Male"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="female"
                  label="Female"
                  control={<Radio />}
                />
                <FormControlLabel
                  value="other"
                  label="Other"
                  control={<Radio />}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" sx={{ marginRight: 3.5 }}>
              Save Changes
            </Button>
            <Button
              type="reset"
              variant="outlined"
              color="secondary"
              onClick={() => setDate(null)}
            >
              Reset
            </Button>
          </Grid> */}
        </Grid>
      </form>
    </CardContent>
  );
};

export default TabInfo;
