// ** React Imports
import { MouseEvent, useEffect, useRef, useState } from 'react';

// ** Next Imports

// ** MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import MuiCard, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MuiFormControlLabel, {
  FormControlLabelProps,
} from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import EyeOutline from 'mdi-material-ui/EyeOutline';

// ** Configs
import themeConfig from 'configs/themeConfig';

// ** Layout Import

// ** Demo Imports
import React from 'react';
import FooterIllustrationsV1 from 'views/pages/auth/FooterIllustration';

import { zodResolver } from '@hookform/resolvers/zod';
import { FormHelperText } from '@mui/material';
import ControlledOutlinedInput from 'app/components/Form/ControlledOutlinedInput';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useLoginSendOTPMutation } from 'redux/services/accounts.services';
import { object, string, TypeOf } from 'zod';
import { notifyError, notifySuccess } from 'app/components/Toast';
import BoxOtpLogin from './BoxOtpLogin';
import SendOTPLogin from './BoxOtpLogin';
import dayjs from 'dayjs';

const loginSchema = object({
  username: string().min(1, 'User name is required'),
  password: string().min(1, 'Password is required'),
});

export type LoginInput = TypeOf<typeof loginSchema>;

// ** Styled Components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: '28rem' },
}));

const LinkStyled = styled('a')(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    '& .MuiFormControlLabel-label': {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
    },
  }),
);

const LoginPage = () => {
  // ** State
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const otpSubmitBtnRef = useRef<{ handleOTP: () => Promise<boolean> }>(null);

  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const from =
    ((location.state as any)?.from.pathname as string) || '/dashboard';
  // const [login, { isLoading, isError, error, isSuccess }] = useLoginMutation();
  const [loginSendOTP, { isLoading, isError, error, isSuccess }] =
    useLoginSendOTPMutation();
  // ** Hook
  const theme = useTheme();
  const [isOtp, setOtp] = useState<{ otpValue: string; active: boolean }>({
    otpValue: '',
    active: false,
  });
  const [stepAction, setStepAction] = useState<number>(1);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmitHandler: SubmitHandler<LoginInput> = async data => {
    // ? Executing the loginUser Mutation
    // login(data);
    loginSendOTP(data)
      .unwrap()
      .then(payload => {
        setOtp({
          otpValue: payload.data?._id || '',
          active: true,
        });
      })
      .catch(error => console.error('rejected', error));
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);
  const [resendTimer, setResendTimer] = useState(60);
  const timerRef = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (resendTimer > 0) {
      timerRef.current = setTimeout(() => {
        setResendTimer(t => t - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [resendTimer]);
  useEffect(() => {
    if (isSuccess) {
      notifySuccess('Gửi mã otp thành công');
      setResendTimer(58);
      setStepAction(2);
    }

    if (isError) {
      if (Array.isArray((error as any).data.error)) {
        (error as any).data.error.forEach((el: any) => notifyError(el.message));
      } else {
        notifyError((error as any).data.message);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Box className="content-center">
      <Card sx={{ zIndex: 1 }}>
        {stepAction == 1 && (
          <CardContent
            sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}
          >
            <Box
              sx={{
                mb: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                src="https://dxe6lti90zltq.cloudfront.net/images/eed81e50-d928-4984-a24e-8e7911277e37.png"
                alt="admin"
                className="w-[40px]"
              />
              <Typography
                variant="h6"
                sx={{
                  ml: 3,
                  lineHeight: 1,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  fontSize: '1.5rem !important',
                }}
              >
                ADMIN ASKANY
              </Typography>
            </Box>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <ControlledTextField
                control={control}
                autoFocus
                fullWidth
                name="username"
                label="Username"
              />
              <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                <FormHelperText error sx={{ marginInline: 0 }}>
                  {errors.username?.message}
                </FormHelperText>
              </Box>
              <FormControl fullWidth>
                <InputLabel htmlFor="password-input">Password</InputLabel>
                <ControlledOutlinedInput
                  id="password-input"
                  control={control}
                  label="Password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <Box sx={{ marginTop: 2, marginBottom: 2 }}>
                  <FormHelperText error sx={{ marginInline: 0 }}>
                    {errors.password?.message}
                  </FormHelperText>
                </Box>
              </FormControl>
              <Box
                sx={{
                  mb: 4,
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <FormControlLabel control={<Checkbox />} label="Remember Me" />
                <LinkStyled href="/" onClick={e => e.preventDefault()}>
                  Forgot Password?
                </LinkStyled>
              </Box>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginBottom: 7 }}
                type="submit"
              >
                Login
              </Button>
            </form>
          </CardContent>
        )}
        {stepAction == 2 && (
          <CardContent
            sx={{ padding: theme => `${theme.spacing(12, 9, 7)} !important` }}
          >
            <Box
              sx={{
                marginTop: 4,
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                height: 200,
              }}
            >
              <Typography
                variant="h6"
                align="center"
                sx={{
                  // ml: 3,
                  lineHeight: 1,
                  fontWeight: 600,
                  textTransform: 'uppercase',
                  fontSize: '1.2rem !important',
                  marginBottom: '1.2rem',
                }}
              >
                Nhập mã OTP
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <Box
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <SendOTPLogin
                    ref={otpSubmitBtnRef}
                    idAccount={isOtp?.otpValue || ''}
                  />
                </Box>
              </Box>
              <Button
                fullWidth
                size="large"
                variant="contained"
                sx={{ marginBottom: 7 }}
                type="button"
                onClick={() => {
                  if (stepAction === 2 && resendTimer > 0) {
                    otpSubmitBtnRef.current?.handleOTP().then(result => {
                      if (result) {
                        notifySuccess('Đăng nhập tài khoản thành công');
                        navigate(from);
                      } else {
                        notifyError('Xác thực otp thất bại');
                      }
                    });
                  } else {
                    setStepAction(1);
                  }
                }}
              >
                {resendTimer > 0
                  ? `Tiếp tục (${dayjs()
                      .set('minute', 0)
                      .set('second', resendTimer)
                      .format('mm:ss')})`
                  : 'Quay lại'}
              </Button>
            </Box>
          </CardContent>
        )}
      </Card>
      <FooterIllustrationsV1 />
    </Box>
  );
};

export default LoginPage;
