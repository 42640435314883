import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import { formatMoneyToFixed } from 'helpers/price.hepler';
import {
  IVoucher,
  VoucherDiscountType,
  VoucherScope,
  VoucherType,
} from 'types/Voucher';
import VoucherOffer from './VoucherTableOffer';

const VoucherHistoryDetail = ({
  voucherDetail,
  offerDetail,
}: {
  voucherDetail: IVoucher;
  offerDetail: any;
}) => {
  return (
    <Card>
      <CardHeader
        title="Chi tiết voucher"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider sx={{ margin: 0 }} />
      <CardContent>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              1. Chi tiết voucher
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Mã voucher
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail?.code || ''}
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Tên voucher
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail?.voucherName || ''}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Ngân sách
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {formatMoneyToFixed(voucherDetail.budget)}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Số lượng
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail.quantity}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Trạng thái
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {/* {!!prop?.route ? prop?.route : 'N/A'} */}
            Hoạt động
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              2. Hình thức giảm giá
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Đối tượng
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail.voucherType === VoucherType.Expert
              ? 'Chuyên gia'
              : 'Người dùng'}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Phạm vi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail.scope === VoucherScope.AllUser
              ? 'Tất cả người dùng'
              : '1 số người dùng'}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Loại hình giảm giá
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {voucherDetail.discountType === VoucherDiscountType.Percent
              ? 'Giảm theo phần trăm'
              : 'Giảm tiền trực tiếp'}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Book ít nhất
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {/* {prop?.author?.email || ''} */}
            {formatMoneyToFixed(voucherDetail?.minBookingPrice || 0)}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Giảm tối đa
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {formatMoneyToFixed(voucherDetail?.maxDiscountAmount || 0)}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              3. Các offer đã sử dụng
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <VoucherOffer offerDetail={offerDetail} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VoucherHistoryDetail;
