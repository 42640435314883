import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import CustomInputOutDate from 'app/components/Form/CustomInputDate';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TypeOf, ZodType, z } from 'zod';
import { VoucherStatus, VoucherType } from '../../../../types/Voucher';

// ** Third Party Imports

// ** Styled Components
import DatePickerWrapper from '@core/styles/libs/react-datepicker';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { VoucherFilterDTO } from 'types/DTO/VoucherFilterDTO';
import { zodResolver } from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import { VoucherStatusText, VoucherTypeText } from 'constants/voucher.constant';

const filterSchema: ZodType<VoucherFilterDTO> = z.object({
  code: z.string().nullable(),
  voucherName: z.string().nullable(),
  discountPercentage: z.coerce
    .number()
    .transform(n => n / 100)
    .nullable(),
  discountAmount: z.coerce.number().nullable(),
  status: z.nativeEnum(VoucherStatus).nullable(),
  voucherType: z.nativeEnum(VoucherType).nullable(),
  startDate: z.coerce.string().nullable(),
  endDate: z.coerce.string().nullable(),
});

type FilterInput = TypeOf<typeof filterSchema>;

const VoucherFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '-1',
  });
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FilterInput>({
    resolver: zodResolver(filterSchema),
  });
  useEffect(() => {
    let percent =
      (searchParams.get('discountPercentage') as unknown as number) || null;
    if (percent) {
      percent = 100 * percent;
    }
    reset({
      code: searchParams.get('code'),
      voucherName: searchParams.get('voucherName'),
      discountPercentage: percent,
      discountAmount:
        (searchParams.get('discountAmount') as unknown as number) || null,
      status: (searchParams.get('status') as unknown as number) || null,
      startDate: searchParams.get('startDate'),
      endDate: searchParams.get('endDate'),
    });
  }, [reset, searchParams]);
  const handleSubmitFilter: SubmitHandler<FilterInput> = data => {
    setSearchParams(
      params => {
        Object.keys(data).forEach(key => {
          if (data[key] || data[key] === 0) {
            params.set(key, data[key]);
          } else {
            params.delete(key);
          }
        });
        params.set('page', '0');
        return params;
      },
      // { replace: true },
    );
  };
  const clearFilter = () => {
    reset({});
    resetField('status', {
      defaultValue: null,
    });
    setSearchParams({});
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitFilter)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>Filter</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}></Grid>
          </Grid>
        </Grid>
        {/* <Grid item xs={12} lg={3} md={4} sm={6}>
          <ControlledTextField
            size="small"
            name="userId"
            control={control}
            fullWidth
            variant="outlined"
            label="Id người dùng"
          />
        </Grid>
        <Grid item xs={12} lg={3} md={4} sm={6}>
          <ControlledTextField
            size="small"
            name="username"
            control={control}
            fullWidth
            variant="outlined"
            label="Tên người dùng"
          />
        </Grid> */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledTextField
            size="small"
            control={control}
            fullWidth
            name="code"
            type="text"
            label="Mã Voucher"
            placeholder="Nhập mã voucher"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledTextField
            size="small"
            control={control}
            fullWidth
            name="voucherName"
            type="text"
            label="Tên Voucher"
            placeholder="Nhập tên voucher"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DriveFileRenameOutlineIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledTextField
            size="small"
            control={control}
            fullWidth
            type="number"
            name="discountPercentage"
            label="Phần trăm giảm"
            placeholder="10"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledTextField
            size="small"
            control={control}
            fullWidth
            type="number"
            name="discountAmount"
            label="Số tiền giảm"
            placeholder="10"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel size="small">Trạng thái</InputLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  label="Trạng thái"
                  {...field}
                  value={field.value ?? ''}
                  error={!!errors.status}
                  size="small"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        setValue('status', null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <MenuItem value={VoucherStatus.Working}>
                    {VoucherStatusText[VoucherStatus.Working]}
                  </MenuItem>
                  <MenuItem value={VoucherStatus.Hidden}>
                    {VoucherStatusText[VoucherStatus.Hidden]}
                  </MenuItem>
                  <MenuItem value={VoucherStatus.Deleted}>
                    {VoucherStatusText[VoucherStatus.Deleted]}
                  </MenuItem>
                  <MenuItem value={VoucherStatus.Lock}>
                    {VoucherStatusText[VoucherStatus.Lock]}
                  </MenuItem>
                  <MenuItem value={VoucherStatus.Expired}>
                    {VoucherStatusText[VoucherStatus.Expired]}
                  </MenuItem>
                  <MenuItem value={VoucherStatus.OutOf}>
                    {VoucherStatusText[VoucherStatus.OutOf]}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel size="small">Loại voucher</InputLabel>
            <Controller
              name="voucherType"
              control={control}
              render={({ field }) => (
                <Select
                  label="Loại voucher"
                  {...field}
                  value={field.value ?? ''}
                  error={!!errors.voucherType}
                  size="small"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        setValue('voucherType', null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <MenuItem value={VoucherType.Expert}>
                    {VoucherTypeText[VoucherType.Expert]}
                  </MenuItem>
                  <MenuItem value={VoucherType.User}>
                    {VoucherTypeText[VoucherType.User]}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="startDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePickerWrapper>
                <DatePicker
                  selected={value ? new Date(value) : undefined}
                  showYearDropdown
                  showMonthDropdown
                  showTimeSelect
                  timeFormat="HH:mm"
                  placeholderText="HH:mm DD/MM/YYYY"
                  dateFormat="HH:mm dd/MM/yyyy"
                  customInput={
                    <CustomInputOutDate
                      size="small"
                      label="Ngày bắt đầu"
                      error={!!error}
                      helperText={error?.message}
                    />
                  }
                  onChange={onChange}
                />
              </DatePickerWrapper>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Controller
            name="endDate"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <DatePickerWrapper>
                <DatePicker
                  selected={value ? new Date(value) : undefined}
                  showYearDropdown
                  showMonthDropdown
                  showTimeSelect
                  timeFormat="HH:mm"
                  placeholderText="HH:mm DD/MM/YYYY"
                  dateFormat="HH:mm dd/MM/yyyy"
                  customInput={
                    <CustomInputOutDate
                      size="small"
                      value={value}
                      label="Ngày hết hạn"
                      error={!!error}
                      helperText={error?.message}
                    />
                  }
                  onChange={onChange}
                />
              </DatePickerWrapper>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid container justifyContent={'space-between'} gap={5}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Button type="submit" variant="contained">
                  Lọc
                </Button>
                <Button type="button" variant="outlined" onClick={clearFilter}>
                  Reset
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel size="small">Sắp xếp</InputLabel>
                <Select
                  label="Sắp xếp"
                  size="small"
                  value={searchParams.get('sort') || '-1'}
                  onChange={e =>
                    setSearchParams(params => {
                      params.set('sort', e.target.value);
                      return params;
                    })
                  }
                >
                  <MenuItem value={'1'}>Cũ nhất</MenuItem>
                  <MenuItem value={'-1'}>Mới nhất</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default VoucherFilter;
