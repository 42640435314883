import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemProps,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { IExpert } from 'types/Expert';

const UserOption = ({
  expert,
  ...props
}: { expert: IExpert } & ListItemProps) => {
  return (
    <ListItem {...props}>
      <ListItemAvatar>
        <Avatar alt={expert?.fullname} src={expert?.avatar?.location} />
      </ListItemAvatar>
      <ListItemText
        primary={expert?.fullname}
        secondary={
          <React.Fragment>
            <Typography sx={{ typography: 'caption' }}>
              {expert?.expertname}
            </Typography>
            <Typography sx={{ typography: 'caption' }}>
              {expert?._id}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default UserOption;
