import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Grid,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { StatusOffers } from 'app/components/StatusOffer';
import AutoGenerateTable from 'app/components/Table/AutoGenerateTable';
import dayjs from 'dayjs';
import { formatMoneyToFixed } from 'helpers/price.hepler';
import useTablePage from 'hooks/useTablePage';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetHistoryQuery } from 'redux/services/voucher.services';
import { IListResponse } from 'types/GenericResponse';
import { getTitleOffer } from 'utils/Offer';
import paramsToObject from 'utils/paramToObject';
import { SortOrder, getComparator } from 'utils/sort';
import { IVoucher, VoucherDiscountType } from '../../../../types/Voucher';
import VoucherHistoryFilter from '../Component/SearchVoucherHistory';
import VoucherHistoryDetail from '../Component/VoucherHistoryDetail';
const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxWidth: '50vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const VoucherHistoryPage = () => {
  const [searchParams] = useSearchParams('?offer=1');
  const { handleChangePage, handleChangeRowsPerPage, limit, page, skip } =
    useTablePage();
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IVoucher | undefined>();
  const [listData, setListData] = useState<IListResponse<IVoucher>>({
    isOver: true,
    listData: [],
    total: 0,
  });
  const [voucherData, setVoucherData] = useState<undefined | IVoucher>(
    undefined,
  );
  const [offerData, setOfferData] = useState<any>(undefined);
  const [showFilter, setShowFilter] = useState(false);
  const { data, isFetching } = useGetHistoryQuery({
    ...paramsToObject(searchParams),
    skip: page * limit,
    limit: limit,
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    window.location.hash = 'modal';
  };
  const handleClose = () => {
    setOpen(false);
    setVoucherData(undefined);
    setOfferData(undefined);
  };
  window.onhashchange = () => {
    if (!window.location.hash) handleClose();
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof IVoucher,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler =
    (property: keyof IVoucher) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  const visibleRows = React.useMemo(() => {
    if (orderBy) {
      const comparator = getComparator(order, orderBy);
      return listData.listData.slice().sort(comparator);
    } else {
      return listData.listData;
    }
  }, [order, orderBy, listData]);

  const getTotalOffer = offerItem => {
    const dataOffer = offerItem as any;
    const dataRequest = (offerItem as any)?.offeredRequest;
    const dataPayment = (offerItem as any)?.price;
    const startTime = (offerItem as any)?.startTime;
    const endTime = (offerItem as any)?.endTime;

    const toMoney = () => {
      let price = 0;
      switch (dataRequest?.type) {
        case 2:
          if (dataRequest.contactForm === 'meet') {
            price =
              (dataPayment?.cost || dataOffer?.author?.meetPrice?.cost || 0) /
              (dataOffer?.author?.meetPrice?.totalMinutes ||
                dataPayment?.totalMinutes ||
                1);
          } else if (dataRequest.contactForm === 'call') {
            price =
              (dataPayment?.cost || dataOffer?.author?.callPrice?.cost || 0) /
              (dataOffer?.author?.callPrice?.totalMinutes ||
                dataPayment?.totalMinutes ||
                1);
          } else if (
            dataRequest.contactForm === 'show' &&
            dataOffer?.author?.showPrice
          ) {
            price =
              (dataPayment?.cost || dataOffer?.author?.showPrice?.cost || 0) /
              (dataOffer?.author?.showPrice?.totalMinutes ||
                dataPayment?.cost ||
                1);
          }
          break;
        default:
          price = (dataPayment?.cost || 0) / (dataPayment?.totalMinutes || 1);
          break;
      }
      return Math.floor(
        price *
          (dayjs(endTime).diff(dayjs(startTime), 'm') ||
            dataPayment?.totalMinutes ||
            60),
      );
    };

    const TimeSelect =
      (endTime && startTime
        ? dayjs(endTime).diff(dayjs(startTime), 'm')
        : dataPayment?.totalMinutes) || 0;
    const participants = dataRequest?.participantsCount || 0;
    const priceBook =
      ((dataPayment?.cost || 0) * TimeSelect) /
      (dataPayment?.totalMinutes || 1);
    const serviceFee = participants >= 3 ? priceBook * 1.5 - priceBook : 0;
    const beforeFee = toMoney();
    const afterServiceFee = beforeFee + serviceFee;
    return afterServiceFee;
  };

  const headCells: Array<any> = [
    {
      id: '_id',
      label: 'ID',
      dataType: 'custom',
      renderer: info => {
        return (
          <Tooltip title="Sao chép">
            <Button
              style={{ maxWidth: 100 }}
              onClick={() => {
                navigator.clipboard.writeText(info);
              }}
            >
              <Typography noWrap>{info}</Typography>
            </Button>
          </Tooltip>
        );
      },
    },
    {
      id: 'author',
      label: 'tên người dùng',
      dataType: 'custom',
      renderer: info => {
        return (
          <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
            <Avatar
              alt={info?.fullname}
              src={info?.avatar?.location}
              sx={{ width: 28, height: 28 }}
            />
            <span>{info?.fullname}</span>
          </Stack>
        );
      },
    },
    {
      id: 'offer',
      label: 'Tên gói dịch vụ',
      width: '20',
      dataType: 'custom',
      renderer: infoOffer => {
        return (
          <span>
            {getTitleOffer(
              infoOffer.offeredRequest.type,
              infoOffer?.author?.fullname,
              infoOffer?.offeredRequest,
            )}
          </span>
        );
      },
    },

    {
      id: 'offer',
      label: 'Giá gói tư vấn',
      width: '60',
      dataType: 'custom',
      renderer: info => {
        return <span>{formatMoneyToFixed(getTotalOffer(info))}</span>;
      },
    },
    {
      id: 'amount',
      label: 'Số tiền được giảm giá',
      width: '20',
      dataType: 'custom',
      renderer: info => {
        return <span>{formatMoneyToFixed(info)}</span>;
      },
    },
    {
      id: 'voucher',
      label: 'Mã voucher',
      dataType: 'custom',
      renderer: info => {
        return <span>{info?.code}</span>;
      },
    },
    {
      id: 'voucher',
      label: 'Loại voucher',
      dataType: 'custom',
      renderer: info => {
        return (
          <span>
            {info.voucherType === VoucherDiscountType.FlatMoney
              ? 'Giảm trực tiếp'
              : 'Giảm theo %'}
          </span>
        );
      },
    },
    {
      id: 'offer',
      label: 'Trạng thái',
      dataType: 'custom',
      renderer: info => {
        return <StatusOffers status={info?.status || 3} />;
      },
    },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={12} sm={5}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Lịch sử voucher
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button
            sx={{ marginLeft: 3 }}
            type="button"
            variant="contained"
            onClick={() => setShowFilter(f => !f)}
            endIcon={<FilterAltIcon />}
          >
            Lọc
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showFilter}>
        <Paper sx={{ padding: 5 }}>
          <VoucherHistoryFilter />
        </Paper>
      </Collapse>

      <AutoGenerateTable
        headCells={headCells}
        listData={{
          isOver: listData.isOver,
          listData: listData.listData,
          total: listData.total,
        }}
        isLoading={isFetching}
        modalLabel="Chi tiết voucher"
        iconHandle={false}
      />
      {voucherData && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModal}>
            <VoucherHistoryDetail
              voucherDetail={voucherData}
              offerDetail={offerData}
            />
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default VoucherHistoryPage;
