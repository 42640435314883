export interface ITransaction {
  _id: string;
  name: string;
  location: string;
}

export enum TransactionStatus {
  //   '-3': 'hết hạn 10 phút',
  // '-1': 'Đã hủy',
  //   0: 'Chờ xử lý',
  //   1: 'Thành công',
  Expired = -3,
  Refuse = -2,
  Faild = -1,
  Pending = 0,
  Success = 1,
}

export interface TransactionFilter {
  status: number;
}
