import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Cookies } from 'react-cookie';
import { IUser } from 'types/User';

interface IUserState {
  user: IUser | null;
}

const initialState: IUserState = {
  user: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'user',
  reducers: {
    logout: () => {
      const cookie = new Cookies();
      cookie.remove('_id');
      cookie.remove('token');
      cookie.remove('refresh-token');
      return initialState;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;
