// ** Types Import
import { Settings } from '@core/context/settingsContext';
import {
  NavDropDownGroup,
  NavLink,
  NavSectionTitle,
  VerticalNavItemsType,
} from '@core/layouts/types';

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink';
import VerticalNavSectionTitle from './VerticalNavSectionTitle';
import React from 'react';
import VerticalNavGroupDropDown from './VerticalNavGroupDropDown';
import { store } from 'redux/store/store';
import { Cookies, useCookies } from 'react-cookie';

interface Props {
  settings: Settings;
  navVisible?: boolean;
  groupActive: string[];
  currentActiveGroup: string[];
  verticalNavItems?: VerticalNavItemsType;
  saveSettings: (values: Settings) => void;
  setGroupActive: (value: string[]) => void;
  setCurrentActiveGroup: (item: string[]) => void;
}

const resolveNavItemComponent = (
  item: NavLink | NavSectionTitle | NavDropDownGroup,
) => {
  const cookies = new Cookies();
  const permissions = cookies.get('permissions') || [];
  if (
    item.permissions &&
    !item.permissions?.some(i => permissions.includes(i))
  ) {
    return () => <></>;
  }
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle;
  if ((item as NavDropDownGroup).groupTitle) return VerticalNavGroupDropDown;

  return VerticalNavLink;
};

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props;

  const RenderMenuItems = verticalNavItems?.map(
    (item: NavLink | NavSectionTitle | NavDropDownGroup, index: number) => {
      const TagName: any = resolveNavItemComponent(item);

      return <TagName {...props} key={index} item={item} />;
    },
  );

  return <>{RenderMenuItems}</>;
};

export default VerticalNavItems;
