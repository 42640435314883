import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';
import { IVoucher, VoucherType } from 'types/Voucher';
import { VoucherFilterDTO } from 'types/DTO/VoucherFilterDTO';
import { VoucherDTO } from 'types/DTO/VoucherDTO';
import { NotificationDTO } from 'types/DTO/NotificationDTO';

export interface ListNotificationRequest {
  skip: number;
  limit: number;
  sort: -1 | 1 | 'asc' | 'ascending' | 'desc' | 'descending';
  status: number;
  voucherName: string;
  voucherType: VoucherType;
  code: string;
  discountAmount: number;
  startDate: Date;
  endDate: Date;
}

const url = EndPoint.transactions;

export const transactionsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'transactionsApi',
  tagTypes: ['Transaction'],
  endpoints: builder => ({
    approveTransaction: builder.mutation<
      IGenericResponse<any>,
      Partial<any> & { _id: string }
    >({
      query: transactionData => {
        const { _id } = transactionData;
        return {
          url: `/approve-deposit/${_id}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Transaction'],
    }),
    cancelTransaction: builder.mutation<
      IGenericResponse<any>,
      Partial<any> & { _id: string }
    >({
      query: transactionData => {
        const { _id } = transactionData;
        return {
          url: `/refuse-deposit/${_id}`,
          method: 'PUT',
        };
      },
      invalidatesTags: ['Transaction'],
    }),
    getAllTransactions: builder.query<IListResponse<any>, Partial<any>>({
      query: params => {
        const { page, ...payload } = params as any;
        return {
          url: '/history/deposits',
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Transaction'],
      transformResponse: (result: IGenericResponse<IListResponse<any>>) => {
        return result.data;
      },
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useApproveTransactionMutation,
  useCancelTransactionMutation,
} = transactionsApi;
