import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';

// ** Third Party Imports

// ** Styled Components
import { InputAdornment } from '@mui/material';
import React from 'react';

const CustomInputOutDate = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CalendarMonthIcon />
            </InputAdornment>
          ),
        }}
        inputRef={ref}
        fullWidth
        {...props}
      />
    );
  },
);

export default CustomInputOutDate;
