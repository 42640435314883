import { EContactForm, EFlashSaleStatus } from './enum';
import { IExpert } from './Expert';

export interface ISale {
  _id: string;
  expert: any;
  saleList: Array<ISaleDetail>;
}

export interface ISaleDetail {
  _id: string;
  contactForm: 'call' | 'meet' | 'show';
  newPrice: number;
  note: string;
  countdownTime: string;
  dateTiltle: string;
  discountAmount: number;
  name: string;
  reference: IExpert;
  saleEndTime: string;
  saleStartTime: string;
  skill: any;
  status: number;
  countdownStep: number;
}

export interface SaleHeadCell {
  id: keyof ISale;
  label: string;
  width?: string | number;
}

export interface ICreateFlashSaleParams {
  reference: string;
  name?: string;
  contactForm: EContactForm;
  dateTiltle?: string;
  discountAmount: number;
  status: EFlashSaleStatus;
  skill?: string;
  saleEndTime: string;
  saleStartTime: string;
  countdownTime: string;
  countdownStep: number;
}

export enum SaleStatus {
  Deleted = -2,
  Expired = -1,
  Pending = 0,
  Working = 1,
}
