import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useGetAccountsQuery,
  useUpdateAccountMutation,
} from 'redux/services/accounts.services';
import { useGetRolesQuery } from 'redux/services/roles.services';
import { IUser } from 'types/User';
import UpdateAccount from './update';
import PermissionWrapper from 'app/components/Permission/PermissionWrapper';
import useCookiePermission from 'hooks/useCookiePermission';
import { PermissionCode } from 'constants/permissionsCode';

const AccountsPage = () => {
  const { havePermission } = useCookiePermission();
  const [searchParams, setSearchParams] = useSearchParams({
    page: '0',
    limit: '10',
    sort: '1',
  });
  const page = Number(searchParams.get('page') || 0);
  const limit = Number(searchParams.get('limit') || 10);
  const skip = page * limit;
  const [userData, setUserData] = useState<undefined | IUser>(undefined);
  const [openDrawer, setOpenDrawer] = useState(false);

  const { data: listAccount } = useGetAccountsQuery();
  const { data: listRole, isLoading: isLoadingRoles } = useGetRolesQuery();
  const [updateAccount, { isLoading }] = useUpdateAccountMutation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Danh sách tài khoản
          </Typography>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%', minWidth: 'min-content' }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>id</TableCell>
              <TableCell>username</TableCell>
              <TableCell>email</TableCell>
              <TableCell>phone</TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>trạng thái</TableCell>
              {listRole?.map(role => {
                return (
                  <TableCell sx={{ whiteSpace: 'nowrap' }} key={role._id}>
                    {role.roleName}
                  </TableCell>
                );
              })}
              <TableCell
                align="center"
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  bgcolor: 'background.paper',
                  minWidth: 100,
                }}
              >
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listAccount?.map((data, index) => (
              <TableRow
                key={data._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{skip + index + 1}</TableCell>
                <TableCell sx={{ maxWidth: 100 }}>
                  <Tooltip title="Sao chép">
                    <Button
                      fullWidth
                      onClick={() => {
                        navigator.clipboard.writeText(data._id);
                      }}
                    >
                      <Typography noWrap>{data._id}</Typography>
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Typography noWrap>{data.username}</Typography>
                </TableCell>
                <TableCell>
                  <Typography noWrap>{data.email}</Typography>
                </TableCell>
                <TableCell>{data.phone}</TableCell>
                <TableCell align="center">
                  <Chip
                    color={data.status === 1 ? 'success' : 'error'}
                    label={data.status === 1 ? 'Hoạt động' : 'Không hoạt động'}
                  ></Chip>
                </TableCell>
                {listRole?.map(role => {
                  return (
                    <TableCell align="center" key={role._id}>
                      <Checkbox
                        defaultChecked={data.roles.some(
                          userRole => userRole._id === role._id,
                        )}
                        disabled={!havePermission(PermissionCode.UpdateAccount)}
                        onChange={e => {
                          if (e.target.checked) {
                            const roles = data.roles.map(r => r._id);
                            roles.push(role._id);
                            const payload = {
                              ...data,
                              roles,
                            };
                            updateAccount(payload);
                          } else {
                            const roles = data.roles
                              .map(r => r._id)
                              .filter(r => r !== role._id);
                            const payload = {
                              ...data,
                              roles,
                            };
                            updateAccount(payload);
                          }
                        }}
                      />
                    </TableCell>
                  );
                })}

                <TableCell
                  align="center"
                  sx={{
                    position: 'sticky',
                    right: 0,
                    zIndex: 1,
                    bgcolor: 'background.paper',
                  }}
                >
                  {/* <IconButton
                    LinkComponent={'a'}
                    href={'/accounts/' + data._id}
                    target="_blank"
                  >
                    <OpenInNewIcon />
                  </IconButton> */}
                  <PermissionWrapper
                    permissions={[
                      PermissionCode.UpdateAccount,
                      PermissionCode.DeleteAccount,
                    ]}
                  >
                    <IconButton
                      onClick={() => {
                        setOpenDrawer(true);
                        setUserData(data);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </PermissionWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
          setUserData(undefined);
        }}
      >
        <DialogContent>
          <UpdateAccount
            accountData={userData}
            handleClose={() => {
              setOpenDrawer(false);
              setUserData(undefined);
            }}
          />
        </DialogContent>
        {/* <PermissionPanel userData={useData} /> */}
      </Dialog>
    </Box>
  );
};

export default AccountsPage;
