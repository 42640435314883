import { Box, CardContent, Divider, Grid, Typography } from '@mui/material';
import AutoGenerateTable from 'app/components/Table/AutoGenerateTable';
import { HeadCell } from 'app/components/Table/types';
import useTablePage from 'hooks/useTablePage';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetAllNotificationsQuery } from 'redux/services/notification.service';
import { NotifyScope } from 'types/Notification.type';

interface INotification {
  _id: string;
  title: string;
  body: string;
  target: number;
  createdAt: string;
  route: string;
  author: {
    email: string;
    phone: string;
    username: string;
  };
  expert: string;
}

const NotificationsPage = () => {
  const [listDataNoti, setListDataNoti] = useState<{
    isOver: boolean;
    listData: Array<any>;
    total: number;
  }>({
    isOver: true,
    listData: [],
    total: 0,
  });

  const headCells: Array<HeadCell<INotification>> = [
    {
      id: '_id',
      label: 'ID',
      dataType: 'string',
      width: '10',
    },
    {
      id: 'title',
      label: 'Tiêu đề',
      dataType: 'string',
    },
    {
      id: 'body',
      label: 'Nội dung',
      dataType: 'string',
      width: '20',
    },
    {
      id: 'target',
      label: 'Hình thức',
      width: '20',
      dataType: 'custom',
      renderer: target => {
        return (
          <span>
            {target === NotifyScope.AllUser
              ? 'Tất cả người dùng'
              : target === NotifyScope.SomeUser
              ? '1 số người dùng'
              : 'Theo chủ đề'}
          </span>
        );
      },
    },
    {
      id: 'createdAt',
      label: 'Thời gian tạo',
      dataType: 'date',
      width: '20',
    },
  ];
  const { limit, page, skip } = useTablePage();
  const [searchParams] = useSearchParams();

  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const { data, isFetching } = useGetAllNotificationsQuery({
    ...paramsToObject(searchParams),
    skip: page * limit,
    limit: limit,
  });

  useEffect(() => {
    if (data) {
      setListDataNoti({
        listData: data?.listData || [],
        isOver: data.isOver,
        total: (data as any)?.count || data?.total,
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
        Lịch sử thông báo
      </Typography>
      <AutoGenerateTable
        headCells={headCells}
        listData={{
          isOver: listDataNoti.isOver,
          listData: listDataNoti.listData,
          total: listDataNoti.total,
        }}
        isLoading={isFetching}
        modalLabel="Chi tiết thông báo"
        detailDialogContent={DetailDialogContent}
      />
    </Box>
  );
};

export default NotificationsPage;

const DetailDialogContent: React.FC<INotification> = prop => {
  return (
    <>
      <CardContent style={{ paddingTop: 0, paddingLeft: 0 }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              1. Chi tiết thông báo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Tiêu đề
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.title}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Nội dung thông báo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.body}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              URL liên kết
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {!!prop?.route ? prop?.route : 'N/A'}
          </Grid>
          {!!prop?.expert && (
            <>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Chuyên gia đính kém
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {prop?.expert}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              2. Thông tin người gửi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Họ tên
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.author?.username || ''}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.author?.email || ''}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              3. Thông tin người nhận
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Phạm vi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop.target === NotifyScope.AllUser
              ? 'Tất cả người dùng'
              : prop.target === NotifyScope.SomeUser
              ? '1 số người dùng'
              : 'Theo chủ đề'}
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
