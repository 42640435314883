export const OPTION_NOTI_ROUTE = [
  {
    title: 'category',
    desc: 'Trang danh mục',
  },
  {
    title: 'expert',
    desc: 'Trang chuyên gia',
  },
  {
    title: 'release',
    desc: 'Trang lịch sử phiên bản',
  },
];
