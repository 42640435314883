import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Avatar,
  Box,
  Button,
  CardContent,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogHandle from 'app/components/DialogHandle';
import AutoGenerateTable from 'app/components/Table/AutoGenerateTable';
import { HeadCell } from 'app/components/Table/types';
import { notifyError, notifySuccess } from 'app/components/Toast';
import { SYSTEM_CODE } from 'constants/systemCode';
import useTablePage from 'hooks/useTablePage';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useDeleteSaleMutation,
  useGetAllQuery,
} from 'redux/services/sale.services';
import { EContactForm, EFlashSaleStatus } from 'types/enum';
import { IExpert } from 'types/Expert';
import { NotifyScope } from 'types/Notification.type';
import { ISaleDetail } from 'types/Sale';
import { IVoucher } from 'types/Voucher';
import AddSale from './AddSale';
import EditSale from './EditSale';
import BoxFilterSale from './components/BoxFilterSale';

interface INotification {
  _id: string;
  title: string;
  body: string;
  target: number;
  createdAt: string;
  route: string;
  author: {
    email: string;
    phone: string;
    username: string;
  };
  expert: string;
}

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const FlashSalePage = () => {
  const [listDataNoti, setListDataNoti] = useState<{
    isOver: boolean;
    listData: Array<any>;
    total: number;
  }>({
    isOver: true,
    listData: [],
    total: 0,
  });
  const [showFilter, setShowFilter] = useState(false);

  const [voucherData, setVoucherData] = useState<undefined | IVoucher>(
    undefined,
  );
  const [openCancel, setOpenCancel] = useState<{
    active: boolean;
    idItem: string;
  }>({
    active: false,
    idItem: '',
  });
  const [open, setOpen] = React.useState(false);
  const [editData, setEditData] = React.useState<ISaleDetail>();
  const handleOpen = () => {
    setOpen(true);
    window.location.hash = 'modal';
  };
  const handleClose = () => {
    setOpen(false);
    setVoucherData(undefined);
  };
  const handleCloseEdit = () => {
    setEditData(undefined);
  };

  const [cancelSale] = useDeleteSaleMutation();

  const renderStatusSale = (status: EFlashSaleStatus) => {
    switch (status) {
      case EFlashSaleStatus.ACTIVED:
        return <Chip label="Hoạt động" color="success" variant="filled" />;
      case EFlashSaleStatus.DELETED:
        return <Chip label="Đã xóa" color="error" variant="filled" />;
      case EFlashSaleStatus.EXPIRED:
        return <Chip label="Hết hạn" color="default" variant="filled" />;
      default:
        return <Chip label="Đã khóa" color="error" variant="filled" />;
    }
  };

  const headCells: Array<HeadCell<ISaleDetail>> = [
    {
      id: '_id',
      label: 'ID',
      dataType: 'custom',
      renderer: idSale => {
        const idFlashSale = idSale as string;
        return (
          <Tooltip title="Sao chép">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(idFlashSale);
              }}
              className="w-32"
            >
              <Typography noWrap>{idFlashSale}</Typography>
            </Button>
          </Tooltip>
        );
      },
    },
    {
      id: 'reference',
      label: 'Chuyên gia',
      dataType: 'custom',
      renderer: reference => {
        const getInfoExpert = reference as IExpert;
        return (
          <Stack
            flexDirection="row"
            gap={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Avatar
              alt="Remy Sharp"
              src={getInfoExpert?.avatar?.location || ''}
              sx={{ width: 28, height: 28 }}
            />
            <span>{getInfoExpert?.fullname}</span>
          </Stack>
        );
      },
    },
    {
      id: 'skill',
      label: 'Gói booking',
      dataType: 'custom',
      width: '20',
      renderer: skill => {
        return (
          <span className="font-medium line-clamp-2">
            {!!skill
              ? `Kỹ năng: "${(skill as any)?.name || ''}"`
              : 'Giá gói tư vấn'}
          </span>
        );
      },
    },
    {
      id: 'contactForm',
      label: 'hình thức',
      dataType: 'custom',
      width: '20',
      renderer: target => {
        return (
          <span>{target === EContactForm.CALL ? 'Gọi điện' : 'Gặp mặt'}</span>
        );
      },
    },
    {
      id: 'status',
      label: 'Trạng thái',
      dataType: 'custom',
      width: '10',
      renderer: status => {
        const statusSale = status as EFlashSaleStatus;
        return <>{renderStatusSale(statusSale)}</>;
      },
    },
    {
      id: 'saleStartTime',
      label: 'Thời gian bắt đầu',
      dataType: 'date',
      width: '20',
    },
    {
      id: 'saleEndTime',
      label: 'Thời gian kết thúc',
      dataType: 'date',
      width: '20',
    },
  ];
  const { limit, page, skip } = useTablePage();
  const [searchParams] = useSearchParams();

  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const handleCancel = async (idSale: string) => {
    await cancelSale(idSale)
      .unwrap()
      .then(() => {
        notifySuccess('Từ chối thành công');
        setOpenCancel({ ...openCancel, active: false });
      })
      .catch(e => {
        notifyError(SYSTEM_CODE[e.data?.systemCode]);
      });
  };

  const { data, isFetching } = useGetAllQuery({
    ...paramsToObject(searchParams),
    skip: page * limit,
    limit: limit,
  });

  useEffect(() => {
    if (data) {
      setListDataNoti({
        listData: data?.listData || [],
        isOver: data.isOver,
        total: (data as any)?.count || data?.total,
      });
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={12} sm={5}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Danh sách Flash Sale
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button type="button" variant="contained" onClick={handleOpen}>
            Tạo
          </Button>
          <Button
            sx={{ marginLeft: 3 }}
            type="button"
            variant="contained"
            onClick={() => setShowFilter(f => !f)}
            endIcon={<FilterAltIcon />}
          >
            Lọc
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showFilter}>
        <Paper sx={{ padding: 5 }}>
          <BoxFilterSale />
        </Paper>
      </Collapse>
      <AutoGenerateTable
        headCells={headCells}
        listData={{
          isOver: listDataNoti.isOver,
          listData: listDataNoti.listData,
          total: listDataNoti.total,
        }}
        iconHandle={false}
        isLoading={isFetching}
        modalLabel="Chi tiết thông báo"
        detailDialogContent={DetailDialogContent}
        actions={[
          {
            name: '',
            component: item => {
              if (
                item.status === EFlashSaleStatus.ACTIVED ||
                item.status === EFlashSaleStatus.BLOCKED
              )
                return (
                  <Stack
                    direction={'row'}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <IconButton onClick={() => setEditData(item)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenCancel({
                          idItem: item?._id,
                          active: true,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                );
              return <></>;
            },
          },
        ]}
      />
      {openCancel.active && (
        <DialogHandle
          title="Lưu ý"
          body="Bạn có muốn xóa flash sale này không!"
          open={openCancel.active}
          onClose={() => setOpenCancel({ ...openCancel, active: false })}
          onHandle={() => handleCancel(openCancel?.idItem)}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          <AddSale handleClose={handleClose} />
        </Box>
      </Modal>
      <Modal
        open={!!editData}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {editData ? (
          <Box sx={styleModal}>
            <EditSale dataSale={editData} handleClose={handleCloseEdit} />
          </Box>
        ) : (
          <></>
        )}
      </Modal>
    </Box>
  );
};

export default FlashSalePage;

const DetailDialogContent: React.FC<INotification> = prop => {
  return (
    <>
      <CardContent style={{ paddingTop: 0, paddingLeft: 0 }}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              1. Chi tiết thông báo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Tiêu đề
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.title}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Nội dung thông báo
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.body}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              URL liên kết
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {!!prop?.route ? prop?.route : 'N/A'}
          </Grid>
          {!!prop?.expert && (
            <>
              <Grid item xs={12} sm={3}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Chuyên gia đính kém
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {prop?.expert}
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              2. Thông tin người gửi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Họ tên
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.author?.username || ''}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Email
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop?.author?.email || ''}
          </Grid>
          <Grid item xs={12}>
            <Divider sx={{ marginBottom: 0 }} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              3. Thông tin người nhận
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Phạm vi
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            {prop.target === NotifyScope.AllUser
              ? 'Tất cả người dùng'
              : prop.target === NotifyScope.SomeUser
              ? '1 số người dùng'
              : 'Theo chủ đề'}
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};
