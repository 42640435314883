import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IFeature } from 'types/Feature';
import { IGenericResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';

const url = EndPoint.features;

export const featuresApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'featuresApi',
  tagTypes: ['Features', 'Permissions'],
  endpoints: builder => ({
    get: builder.query<
      Array<IFeature>,
      {
        skip?: number;
        limit?: number;
      } | void
    >({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: ['Permissions', 'Features'],
      transformResponse: (result: IGenericResponse<Array<IFeature>>) => {
        return result.data;
      },
    }),
  }),
});

export const { useGetQuery: useGetFeaturesQuery } = featuresApi;
