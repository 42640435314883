import { Box, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import { Cookies, useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { accountsApi } from 'redux/services/accounts.services';

const RequireLogin = () => {
  const [cookies] = useCookies(['_id']);
  const location = useLocation();
  const { isLoading, isFetching } =
    accountsApi.endpoints.getAccountById.useQuery(cookies._id, {
      skip: false,
      pollingInterval: 1000 * 60 * 60,
      refetchOnMountOrArgChange: true,
    });
  const loading = isLoading || isFetching;
  const { user } = accountsApi.endpoints.getAccountById.useQueryState(
    cookies._id,
    {
      selectFromResult: ({ data }) => ({ user: data }),
    },
  );

  const cookie = new Cookies();
  const updateRoleWhenReload = () => {
    if (!user) return;
    let codePermisstion: any = [];
    user.roles.forEach(element => {
      if (element.permissionGroups.length <= 0) return;
      element.permissionGroups.forEach(item => {
        if (item.permissions.length <= 0) return;
        item.permissions.forEach(itemCode => {
          const codeUser = itemCode?.code;
          codePermisstion.push(codeUser);
        });
      });
    });

    cookie.set('permissions', codePermisstion, { path: '/' });
    return codePermisstion;
  };

  useEffect(() => {
    updateRoleWhenReload();
  }, [user]);

  if (loading && !user) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100dvh',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  return user ? (
    <Outlet />
  ) : user ? (
    <Navigate to="/404" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireLogin;
