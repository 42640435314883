import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TypeOf, ZodType, z } from 'zod';

// ** Third Party Imports

// ** Styled Components
import { zodResolver } from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

enum VoucherHistoryStatus {
  Success = 1,
  Unfinished = -1,
}
const filterSchema: ZodType<any> = z.object({
  offer: z.nativeEnum(VoucherHistoryStatus).nullable(),
});

type FilterInput = TypeOf<typeof filterSchema>;

const VoucherHistoryFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '-1',
  });
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FilterInput>({
    resolver: zodResolver(filterSchema),
  });
  useEffect(() => {
    reset({
      offer: (searchParams.get('offer') as unknown as number) || null,
    });
  }, [reset, searchParams]);
  const handleSubmitFilter: SubmitHandler<FilterInput> = data => {
    setSearchParams(
      params => {
        Object.keys(data).forEach(key => {
          if (data[key] || data[key] === 0) {
            params.set(key, data[key]);
          } else {
            params.delete(key);
          }
        });
        params.set('page', '0');
        return params;
      },
      // { replace: true },
    );
  };
  const clearFilter = () => {
    reset({});
    resetField('offer', {
      defaultValue: null,
    });
    setSearchParams({});
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitFilter)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>Filter</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}></Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel size="small">Trạng thái</InputLabel>
            <Controller
              name="offer"
              control={control}
              render={({ field }) => (
                <Select
                  label="Trạng thái"
                  {...field}
                  value={field.value ?? ''}
                  error={!!errors.status}
                  size="small"
                  endAdornment={
                    <IconButton
                      onClick={() => {
                        setValue('offer', null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  }
                >
                  <MenuItem value={1}>Hoàn thành</MenuItem>
                  <MenuItem value={-1}>Chưa hoàn thành</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid container justifyContent={'space-between'} gap={5}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Button type="submit" variant="contained">
                  Lọc
                </Button>
                <Button type="button" variant="outlined" onClick={clearFilter}>
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default VoucherHistoryFilter;
