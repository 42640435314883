import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TypeOf, ZodType, z } from 'zod';

// ** Third Party Imports

// ** Styled Components
import { zodResolver } from '@hookform/resolvers/zod';
import ClearIcon from '@mui/icons-material/Clear';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { TransactionStatus } from 'types/Transaction';
import { SaleStatus } from 'types/Sale';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import { EFlashSaleStatus } from 'types/enum';

const SaleStatusText: { [key: string]: string } = {
  '-2': 'Hết hạn',
  '-1': 'Đã xóa',
  0: 'Khóa',
  1: 'Hoạt động',
};

const filterSchema: ZodType<any> = z.object({
  status: z.nativeEnum(SaleStatus).nullable().optional(),
  contactForm: z.string().optional().nullable(),
  expertName: z.string().optional(),
});

type FilterInput = TypeOf<typeof filterSchema>;

const BoxFilterSale = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: '-1',
  });
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FilterInput>({
    resolver: zodResolver(filterSchema),
  });
  useEffect(() => {
    reset({
      status: (searchParams.get('status') as unknown as number) || null,
      contactForm:
        (searchParams.get('contactForm') as unknown as number) || null,
      expertName:
        (searchParams.get('expertName') as unknown as number) || undefined,
    });
  }, [reset, searchParams]);
  const handleSubmitFilter: SubmitHandler<FilterInput> = data => {
    setSearchParams(
      params => {
        Object.keys(data).forEach(key => {
          if (data[key] || data[key] === 0) {
            params.set(key, data[key]);
          } else {
            params.delete(key);
          }
        });
        params.set('page', '0');
        return params;
      },
      // { replace: true },
    );
  };
  const clearFilter = () => {
    reset({});
    resetField('status', {
      defaultValue: null,
    });
    setSearchParams({});
  };
  return (
    <form onSubmit={handleSubmit(handleSubmitFilter)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography>Filter</Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <ControlledTextField
            control={control}
            name="expertName"
            fullWidth
            size="small"
            label="Tên chuyên gia"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel size="small">Hình thức</InputLabel>
            <Controller
              name="contactForm"
              control={control}
              render={({ field }) => (
                <Select
                  label="Trạng thái"
                  {...field}
                  value={field.value ?? ''}
                  error={!!errors.contactForm}
                  size="small"
                >
                  <MenuItem value={'call'}>Gọi điện</MenuItem>
                  <MenuItem value={'meet'}>Gặp mặt</MenuItem>
                  <MenuItem value={'show'}>Show</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <FormControl fullWidth>
            <InputLabel size="small">Trạng thái</InputLabel>
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Select
                  label="Trạng thái"
                  {...field}
                  value={field.value ?? ''}
                  error={!!errors.status}
                  size="small"
                >
                  <MenuItem value={EFlashSaleStatus.DELETED}>
                    {SaleStatusText[EFlashSaleStatus.DELETED]}
                  </MenuItem>
                  <MenuItem value={EFlashSaleStatus.EXPIRED}>
                    {SaleStatusText[EFlashSaleStatus.EXPIRED]}
                  </MenuItem>
                  <MenuItem value={EFlashSaleStatus.BLOCKED}>
                    {SaleStatusText[EFlashSaleStatus.BLOCKED]}
                  </MenuItem>
                  <MenuItem value={EFlashSaleStatus.ACTIVED}>
                    {SaleStatusText[EFlashSaleStatus.ACTIVED]}
                  </MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
            <InputLabel size="small">Sắp xếp</InputLabel>
            <Select
              label="Sắp xếp"
              size="small"
              value={searchParams.get('sort') || '-1'}
              onChange={e =>
                setSearchParams(params => {
                  params.set('sort', e.target.value);
                  return params;
                })
              }
            >
              <MenuItem value={'1'}>Cũ nhất</MenuItem>
              <MenuItem value={'-1'}>Mới nhất</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-between' }}
        >
          <Grid container justifyContent={'space-between'} gap={5}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: 'flex', gap: 4 }}>
                <Button type="submit" variant="contained">
                  Lọc
                </Button>
                <Button type="button" variant="outlined" onClick={clearFilter}>
                  Reset
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default BoxFilterSale;
