import { NotifyScope } from 'types/Notification.type';
import { TypeOf, z } from 'zod';

export const notificationSchema = z
  .object({
    title: z
      .string()
      .trim()
      .min(1, 'Tiêu đề thông báo không được để trống')
      .max(40, 'Tên đề thông báo không được dài quá 40 ký tự'),
    body: z
      .string()
      .trim()
      .min(1, 'Nội dung không được để trống')
      .max(100, 'nội dung không được dài hơn 100 ký tự'),
    route: z.string().trim().optional(),
    isRecord: z.boolean(),
    to: z.array(z.string()).optional(),
    expert: z.string().trim().optional(),
    categories: z.array(z.string()).optional(),
    target: z.nativeEnum(NotifyScope, {
      invalid_type_error: 'Vui lòng chọn đối tượng',
    }),
  })
  .refine(
    data =>
      !(data.target === NotifyScope.SomeUser && (data.to?.length || 0) <= 0),
    {
      message: 'Vui lòng thêm danh sách người nhận!',
      path: ['to'],
    },
  )
  .refine(
    data =>
      !(
        data.target === NotifyScope.AllTopics &&
        (data.categories?.length || 0) <= 0
      ),
    {
      message: 'Vui lòng thêm danh sách chủ đề nhận thông báo!',
      path: ['categories'],
    },
  );

export type NotificationInput = TypeOf<typeof notificationSchema>;
