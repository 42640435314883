export enum PermissionCode {
  CreateAccount,
  ReadAccount,
  UpdateAccount,
  DeleteAccount,
  CreateRole,
  ReadRole,
  UpdateRole,
  DeleteRole,
  ReadPermission,
  CreatePermissionGroup,
  ReadPermissionGroup,
  UpdatePermissionGroup,
  DeletePermissionGroup,
}
