import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { setCredentials } from 'redux/features/auth/authSlice';
import { IGenericResponse } from 'types/GenericResponse';
import { IUser } from 'types/User';
import { baseQuery } from './fetchQuery';
import { setUser } from 'redux/features/user/userSlice';
import { Cookies } from 'react-cookie';
import { IPermission, IPerrmissionGroup } from 'types/Role';

const url = EndPoint.permissions;

export const permissionsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'permissionsApi',
  tagTypes: ['Permissions'],
  endpoints: builder => ({
    get: builder.query<
      Array<IPermission>,
      {
        skip?: number;
        limit?: number;
      } | void
    >({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: ['Permissions'],
      transformResponse: (result: IGenericResponse<Array<IPermission>>) => {
        return result.data;
      },
    }),
  }),
});

export const { useGetQuery: useGetPermissionsQuery } = permissionsApi;
