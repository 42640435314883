import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IExpert } from 'types/Expert';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';

export interface ExpertsRequest {
  skip?: number;
  limit?: number;
  sort?: 1 | -1;
  search?: string;
  isExpert?: boolean;
}

const url = EndPoint.experts;

export const expertsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'expertsApi',
  tagTypes: ['Experts'],
  endpoints: builder => ({
    getExpertList: builder.query<IListResponse<IExpert>, ExpertsRequest>({
      query: query => ({
        url: '',
        method: 'GET',
        params: query,
      }),
      transformResponse: (result: IGenericResponse<IListResponse<IExpert>>) => {
        return result.data;
      },
      providesTags: ['Experts'],
    }),
    getExperts: builder.mutation<IListResponse<IExpert>, ExpertsRequest>({
      query: query => ({
        url: '',
        method: 'GET',
        params: query,
      }),
      transformResponse: (result: IGenericResponse<IListResponse<IExpert>>) => {
        return result.data;
      },
    }),
    getExpertById: builder.mutation<IListResponse<IExpert>, any>({
      query: query => ({
        url: `/${query}`,
        method: 'GET',
      }),
      transformResponse: (result: IGenericResponse<IListResponse<IExpert>>) => {
        return result.data;
      },
      invalidatesTags: ['Experts'],
    }),
    getExpertId: builder.query<IExpert, string>({
      query: id => ({
        url: id,
        method: 'GET',
      }),
      transformResponse: (result: IGenericResponse<IExpert>) => {
        return result.data;
      },
      providesTags: ['Experts'],
    }),
    updateExpert: builder.mutation<
      IExpert,
      { id: string; payload: Partial<IExpert> }
    >({
      query: ({ id, payload }) => ({
        url: id,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['Experts'],
      transformResponse: (result: IGenericResponse<IExpert>) => {
        return result.data;
      },
    }),
    approveExpertIntroduction: builder.mutation<
      any,
      Pick<IExpert, 'introduceTemp' | '_id'> & { status: 1 | 0 | -1 }
    >({
      query: payload => {
        const { _id, ...body } = payload;
        return {
          url: `/introduce/${payload._id}`,
          method: 'PUT',
          body: body,
        };
      },
      invalidatesTags: ['Experts'],
    }),
  }),
});

export const {
  useGetExpertsMutation,
  useGetExpertByIdMutation,
  useGetExpertListQuery,
  useGetExpertIdQuery,
  useUpdateExpertMutation,
  useApproveExpertIntroductionMutation,
} = expertsApi;
