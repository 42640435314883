import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import CustomPagingAction from 'app/components/Table/CustomPagingAction';
import dayjs from 'dayjs';
import useTablePage from 'hooks/useTablePage';
import React, { Fragment } from 'react';
import { SortOrder, getComparator } from 'utils/sort';
import { HeadCell, TableProps } from './types';

const AutoGenerateTable = <DataType extends { _id: string }>({
  listData = {
    isOver: true,
    listData: [],
    total: 0,
  },
  headCells = [],
  isLoading = true,
  modalLabel = 'Chi tiết',
  detailDialogContent,
  isPagination = true,
  iconHandle = true,
  actions = [],
}: TableProps<DataType>) => {
  const { handleChangePage, handleChangeRowsPerPage, limit, page, skip } =
    useTablePage();
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof DataType | undefined>();
  const [focusLine, setFocusLine] = React.useState<DataType | undefined>();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DataType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const createSortHandler =
    (property: keyof DataType) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };
  const visibleRows = React.useMemo(() => {
    if (orderBy) {
      const comparator = getComparator(order, orderBy);
      return listData.listData.slice().sort(comparator);
    } else {
      return listData.listData;
    }
  }, [order, orderBy, listData]);

  const cellRenderer = (data: DataType, cell: HeadCell<DataType>) => {
    if (cell.dataType === 'string' || cell.dataType === 'number') {
      return data[cell.id as string] as string;
    }
    if (cell.dataType === 'boolean') {
      return data[cell.id as string] as boolean;
    }
    if (cell.dataType === 'date') {
      try {
        return dayjs(data[cell.id as string] as string).format(
          'DD-MM-YYYY HH:mm',
        );
      } catch {
        return '';
      }
    }
    if (cell.dataType === 'custom') {
      try {
        return cell.renderer(data[cell.id]);
      } catch {
        return '';
      }
    }
    if (cell.dataType === 'multi') {
      try {
        return cell.renderer(data);
      } catch {
        return '';
      }
    }
    return '';
  };

  return (
    <Box>
      {/* {isPagination && (
        <TablePagination
          sx={{
            '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
              justifyContent: 'end',
              flexWrap: 'wrap',
            },
          }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} trong ${
              count !== -1 ? count : `hơn ${to}`
            } kết quả`;
          }}
          labelRowsPerPage="Số dòng trong trang"
          count={listData.total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={CustomPagingAction}
        />
      )} */}

      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: 'min-content' }}
          aria-label="table in dashboard"
        >
          <TableHead>
            <TableRow>
              {headCells.map((headCell, index) => (
                <TableCell key={index} sx={{ whiteSpace: 'nowrap' }}>
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc'
                          ? 'sorted descending'
                          : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              {(iconHandle || actions.length > 0) && (
                <TableCell width={1}></TableCell>
              )}
            </TableRow>
          </TableHead>

          {isLoading ? (
            <TableBody sx={{ textAlign: 'center' }}>
              <TableRow>
                <TableCell colSpan={11}>
                  <Box
                    sx={{
                      display: 'flex',
                      padding: '20px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <>
              {visibleRows.length > 0 ? (
                <TableBody>
                  {visibleRows?.map((data, index) => (
                    <TableRow
                      hover
                      key={data._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      {headCells.map((cell, index) => {
                        return (
                          <TableCell key={index}>
                            {cellRenderer(data, cell)}
                          </TableCell>
                        );
                      })}
                      <TableCell align="center">
                        <Stack direction={'row'}>
                          {iconHandle && (
                            <Tooltip
                              title="Thông tin chi tiết"
                              placement="right-start"
                            >
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setFocusLine(data);
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                >
                                  <circle cx="12" cy="12" r="10" />
                                  <path d="M12 16v-4" />
                                  <path d="M12 8h.01" />
                                </svg>
                              </IconButton>
                            </Tooltip>
                          )}
                          {actions.map((a, index) => (
                            <Fragment key={index}>{a.component(data)}</Fragment>
                          ))}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody sx={{ textAlign: 'center' }}>
                  <TableRow>
                    <TableCell colSpan={11}>
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '10px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <img src="/images/nodata/empty-table.svg" alt="" />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      {isPagination && (
        <TablePagination
          sx={{
            '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
              justifyContent: 'end',
              flexWrap: 'wrap',
            },
          }}
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} trong ${
              count !== -1 ? count : `hơn ${to}`
            } kết quả`;
          }}
          labelRowsPerPage="Số dòng trong trang"
          count={listData.total}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={CustomPagingAction}
        />
      )}
      <Dialog
        fullWidth
        maxWidth="md"
        open={!!focusLine}
        onClose={() => {
          setFocusLine(undefined);
        }}
      >
        <DialogTitle>{modalLabel}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setFocusLine(undefined);
          }}
          size="small"
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider sx={{ margin: 0 }} />
        <DialogContent>
          {detailDialogContent && focusLine && detailDialogContent(focusLine)}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AutoGenerateTable;
