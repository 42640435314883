import DatePickerWrapper from '@core/styles/libs/react-datepicker';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import ControlledNumberTextField from 'app/components/Form/ControlledNumberTextField';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import CustomInputOutDate from 'app/components/Form/CustomInputDate';
import { notifyError, notifySuccess } from 'app/components/Toast';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  Controller,
  FieldNamesMarkedBoolean,
  FieldValues,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { useEditSaleMutation } from 'redux/services/sale.services';
import { EContactForm, EFlashSaleStatus } from 'types/enum';
import { ISaleDetail } from 'types/Sale';
import { TypeOf, z } from 'zod';
import SaleOtp from './SaleOtp';

const saleSchema = z.object({
  name: z.string(),
  contactForm: z.enum(['call', 'meet', 'show']),
  status: z.number().optional(),
  dateTitle: z.string(),
  discountAmount: z.number(),
  saleEndTime: z.coerce.string(),
  saleStartTime: z.coerce.string(),
  countdownTime: z.coerce.string(),
  countdownStep: z.coerce.number().optional(),
  skill: z.string().optional(),
  dateTiltle: z.string().optional(),
});

export type SaleInput = TypeOf<typeof saleSchema>;

const EditSale = ({
  dataSale,
  handleClose,
}: {
  dataSale: ISaleDetail;
  handleClose: () => void;
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [updateSale, { isError: errorEdit }] = useEditSaleMutation();

  // const get

  const methods = useForm<SaleInput>({
    resolver: zodResolver(saleSchema),
    mode: 'onChange',
    defaultValues: {
      contactForm: dataSale.contactForm,
      countdownTime: dataSale.countdownTime,
      dateTitle: dataSale.dateTiltle,
      saleEndTime: dataSale.saleEndTime,
      saleStartTime: dataSale.saleStartTime,
      discountAmount: dataSale.discountAmount,
      dateTiltle: dataSale.dateTiltle,
      countdownStep: dataSale.countdownStep,
      name: dataSale.name,
      status: dataSale?.status,
    },
  });

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { errors, isDirty, dirtyFields },
  } = methods;
  const saleStartTime = watch('saleStartTime');
  const [activeStep, setActiveStep] = useState(1);
  const otpSubmitBtnRef = useRef<{ handleOTP: () => Promise<boolean> }>(null);

  const SaleStatusText: { [key: string]: string } = {
    '-2': 'Hết hạn',
    '-1': 'Đã xóa',
    0: 'Khóa',
    1: 'Hoạt động',
  };

  const submitForm: SubmitHandler<SaleInput> = async data => {
    const payloadEdit = getDirtyFields(data, dirtyFields);

    if (!!payloadEdit?.saleStartTime) {
      const formatStartTime = dayjs(payloadEdit?.saleStartTime).utc().format();
      data.saleStartTime = formatStartTime;
    }
    if (!!payloadEdit?.saleEndTime) {
      const formatEndTime = dayjs(payloadEdit?.saleEndTime).utc().format();
      data.saleEndTime = formatEndTime;
    }
    if (!!payloadEdit?.countdownStep) {
      const addTimeCountDown = dayjs(
        payloadEdit?.saleStartTime || dataSale?.saleStartTime,
      )
        .hour(dayjs().hour())
        .minute(dayjs().minute())
        .add(payloadEdit?.countdownStep || 0, 'hour');
      data.countdownTime = addTimeCountDown.utc().format();
    }

    const payload = data;

    try {
      updateSale({ _id: dataSale._id, ...payload })
        .unwrap()
        .then(() => {
          handleClose();
          notifySuccess('Cập nhập thành công');
        });
      return;
    } catch (error) {
      notifyError('Cập nhập thất bại');
      return;
    }
  };

  const detailSale = dataSale;

  const getPriceDataSale = () => {
    if (dataSale.contactForm === EContactForm.CALL) {
      return (
        dataSale?.skill?.callPriceCost ||
        dataSale?.reference?.callPriceCost ||
        0
      );
    }
    if (dataSale.contactForm === EContactForm.MEET) {
      return (
        dataSale?.skill?.meetPriceCost ||
        dataSale?.reference?.meetPriceCost ||
        0
      );
    }
    return 0;
  };

  const handleOpen = async () => {
    const valid = await trigger();
    if (!valid) return;
    setActiveStep(2);
  };
  return (
    <Card>
      <CardHeader
        title={'Chỉnh sửa flash sale'}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider sx={{ margin: 0 }} />
      {activeStep !== 2 && (
        <form onSubmit={handleSubmit(submitForm)}>
          <CardContent>
            <Grid container spacing={5}>
              {/* <Grid item sm={12} md={12}>
              <Typography
                fontWeight={600}
                variant="button"
                gutterBottom
                width="100%"
              >
                Chuyên gia
              </Typography>
            </Grid> */}

              {/* <Grid item sm={12} md={12}>
                <Typography
                  fontWeight={600}
                  variant="button"
                  gutterBottom
                  width="100%"
                >
                  Thông tin
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Tiêu đề
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <ControlledTextField
                  name="name"
                  size="small"
                  fullWidth
                  control={control}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Nội dung ngày
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <ControlledTextField
                  name="dateTiltle"
                  size="small"
                  fullWidth
                  control={control}
                  disabled
                />
              </Grid> */}
              <Grid item xs={12} sm={2}>
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  Trạng thái
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel size="small">Hình thức</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Trạng thái"
                        {...field}
                        value={field.value ?? ''}
                        error={!!errors?.status}
                        size="small"
                      >
                        {/* <MenuItem value={EFlashSaleStatus.DELETED}>
                          {SaleStatusText[EFlashSaleStatus.DELETED]}
                        </MenuItem>
                        <MenuItem value={EFlashSaleStatus.EXPIRED}>
                          {SaleStatusText[EFlashSaleStatus.EXPIRED]}
                        </MenuItem> */}
                        <MenuItem value={EFlashSaleStatus.BLOCKED}>
                          {SaleStatusText[EFlashSaleStatus.BLOCKED]}
                        </MenuItem>
                        <MenuItem value={EFlashSaleStatus.ACTIVED}>
                          {SaleStatusText[EFlashSaleStatus.ACTIVED]}
                        </MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Divider sx={{ margin: 0 }} />
              <Grid item sm={12} md={12}>
                <Typography
                  fontWeight={600}
                  variant="button"
                  gutterBottom
                  width="100%"
                >
                  Giá tiền
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <NumericFormat
                  size="small"
                  fullWidth
                  label="Giá cũ"
                  allowLeadingZeros={false}
                  value={getPriceDataSale()}
                  customInput={TextField}
                  thousandSeparator
                  allowNegative={false}
                  suffix=" VND"
                  disabled
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <ControlledNumberTextField
                  decimalScale={0}
                  size="small"
                  control={control}
                  fullWidth
                  label="Giá mới"
                  name="discountAmount"
                  value={detailSale?.discountAmount || 0}
                  suffix=" VND"
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography
                  fontWeight={600}
                  variant="button"
                  gutterBottom
                  width="100%"
                >
                  Thời gian
                </Typography>
              </Grid>
              <Grid item sm={12} md={6}>
                <Controller
                  name="saleStartTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DatePickerWrapper>
                      <DatePicker
                        selected={value ? new Date(value) : undefined}
                        showYearDropdown
                        showMonthDropdown
                        showTimeSelect
                        timeFormat="HH:mm"
                        placeholderText="HH:mm DD/MM/YYYY"
                        dateFormat="HH:mm dd/MM/yyyy"
                        customInput={
                          <CustomInputOutDate
                            size="small"
                            label="Thời gian bắt đầu"
                            error={!!error}
                            helperText={error?.message}
                          />
                        }
                        onChange={onChange}
                      />
                    </DatePickerWrapper>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Controller
                  name="saleEndTime"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <DatePickerWrapper>
                      <DatePicker
                        selected={value ? new Date(value) : undefined}
                        showYearDropdown
                        showMonthDropdown
                        showTimeSelect
                        timeFormat="HH:mm"
                        placeholderText="HH:mm DD/MM/YYYY"
                        dateFormat="HH:mm dd/MM/yyyy"
                        customInput={
                          <CustomInputOutDate
                            size="small"
                            label="Thời gian kết thúc"
                            error={!!error}
                            helperText={error?.message}
                          />
                        }
                        onChange={onChange}
                      />
                    </DatePickerWrapper>
                  )}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <DatePickerWrapper>
                  <DatePicker
                    disabled
                    selected={new Date(saleStartTime)}
                    showYearDropdown
                    showMonthDropdown
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <CustomInputOutDate
                        size="small"
                        label="Thời gian đếm ngược"
                      />
                    }
                    onChange={() => {}}
                  />
                </DatePickerWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ControlledTextField
                  name="countdownStep"
                  size="small"
                  fullWidth
                  label="Thời gian đếm ngược flash sale"
                  control={control}
                  value={detailSale?.countdownStep || 0}
                  type="number"
                />
              </Grid>
            </Grid>
          </CardContent>
        </form>
      )}

      {/* <CardActions>
        <Grid container>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ marginRight: 3.5 }}
              onClick={handleSubmit(submitForm)}
            >
              Lưu
            </Button>
            <Button type="reset" variant="outlined" color="secondary">
              Hủy
            </Button>
          </Grid>
        </Grid>
      </CardActions> */}
      {activeStep === 2 && (
        <CardContent>
          <Box
            sx={{
              marginTop: 4,
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              height: 200,
            }}
          >
            <Typography sx={{ typography: 'body' }} align="center">
              Nhập mã OTP
            </Typography>
            <Box
              sx={{
                flexGrow: 1,
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Box
                sx={{
                  maxWidth: 400,
                }}
              >
                <SaleOtp ref={otpSubmitBtnRef} />
              </Box>
            </Box>
          </Box>
        </CardContent>
      )}

      <CardActions>
        <Grid container>
          <Grid item xs={12}>
            {activeStep > 1 && (
              <Button
                onClick={() => setActiveStep(s => s - 1)}
                disabled={activeStep <= 1}
                variant="contained"
                sx={{ marginRight: 3.5 }}
              >
                quay lại
              </Button>
            )}
            <Button
              onClick={() => {
                if (activeStep >= 2) {
                  otpSubmitBtnRef.current?.handleOTP().then(result => {
                    if (result) {
                      handleSubmit(submitForm)();
                    }
                  });
                } else {
                  handleOpen();
                }
              }}
              disabled={!isDirty}
              variant="contained"
              sx={{ marginRight: 3.5 }}
            >
              Tiếp tục
            </Button>
            <Button
              type="reset"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Hủy
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

function getDirtyFields<T extends FieldValues>(
  values: T,
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<T>>>,
) {
  const payload: Partial<T> = {};
  Object.keys(dirtyFields).forEach(key => {
    if (dirtyFields[key as keyof typeof dirtyFields]) {
      payload[key as keyof T] = values[key as keyof T];
    }
  });
  return payload;
}

export default EditSale;
