import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
}

const ControlledTextField = <T extends FieldValues>(
  props: Props<T> & TextFieldProps,
) => {
  const { control, name, ...textFieldProps } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...textFieldProps}
          value={field.value || ''}
          onBlur={e => {
            field.onBlur();
            textFieldProps.onBlur?.(e);
          }}
          onChange={e => {
            field.onChange(e);
            textFieldProps.onChange?.(e.target.value as any);
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default ControlledTextField;
