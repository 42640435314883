import NotificationsPage from 'app/pages/Notifications';
import CreateNotifyPage from 'app/pages/Notifications/CreateNotify';
import { RouteObject } from 'react-router-dom';

const NotificationRoutes: RouteObject = {
  path: '/notifications',
  children: [
    {
      index: true,
      element: <NotificationsPage />,
    },
    {
      path: 'create',
      element: <CreateNotifyPage />,
    },
  ],
};

export default NotificationRoutes;
