import { AppUser } from './AppUser';

export enum VoucherStatus {
  Hidden = 2,
  Deleted = -1,
  Lock = 0,
  Working = 1,
  Expired = -2,
  OutOf = -3,
}

export enum VoucherType {
  User,
  Expert,
}

export enum VoucherScope {
  SomeUser = 0,
  AllUser = 1,
}

export enum VoucherDiscountType {
  Percent = 0,
  FlatMoney = 1,
  DISCOUNT_BY_TIME = 3,
}

export interface IVoucher {
  _id: string;
  voucherName: string;
  code: string;
  maxDiscountAmount?: number;
  discountPercentage?: number;
  discountAmount?: number;
  status: VoucherStatus;
  voucherType: VoucherType;
  discountType: VoucherDiscountType;
  minBookingPrice?: number;
  quantity: number;
  budget: number;
  expert?: string;
  startDate: string;
  endDate: string;
  scope?: VoucherScope;
  users?: Array<AppUser>;
  totalUsed?: number;
  totalDiscount?: number;
  createdAt: string;
  consultingTime?: number;
}

export interface ResHistoryVoucher {
  _id: string;
  amount: number;
  offer: any;
  voucher: IVoucher;
  status: number;
}

export interface VoucherHeadCell {
  id: keyof IVoucher;
  label: string;
  width?: string | number;
}

export interface VoucherHistory {
  _id: string;
  amount: number;
  author: {
    _id: string;
    fullname: string;
    avatar?: {
      location?: string;
    };
  };
  offer: any;
  status: number;
  transaction: string;
  usedAt: string;
  voucher: IVoucher;
}
