export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const ahaveValue =
    typeof a[orderBy] === 'number' || typeof a[orderBy] === 'string';
  const bhaveValue =
    typeof b[orderBy] === 'number' || typeof b[orderBy] === 'string';

  if (ahaveValue && bhaveValue) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (ahaveValue) {
      return -1;
    }
    if (bhaveValue) {
      return 1;
    }
    return 0;
  }
}

export type SortOrder = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: SortOrder,
  orderBy: Key,
): (a: { [key in Key]?: any }, b: { [key in Key]?: any }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
