import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';

const ExperienceDetailPage = () => {
  const { experienceId } = useParams();
  return (
    <Stack gap={4}>
      <Typography sx={{ typography: 'h5' }}>Chi tiết kinh nghiệm</Typography>
      <Card variant="outlined">
        <CardContent>{experienceId}</CardContent>
        <CardActions>
          <Button size="small" variant="contained">
            Cập nhật
          </Button>
        </CardActions>
      </Card>
    </Stack>
  );
};

export default ExperienceDetailPage;
