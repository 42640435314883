import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';
import { IVoucher, VoucherType } from 'types/Voucher';
import { VoucherFilterDTO } from 'types/DTO/VoucherFilterDTO';
import { VoucherDTO } from 'types/DTO/VoucherDTO';
import { ICreateFlashSaleParams, ISaleDetail } from 'types/Sale';

export interface ListVoucherRequest {
  skip: number;
  limit: number;
  sort: -1 | 1 | 'asc' | 'ascending' | 'desc' | 'descending';
  status: number;
  voucherName: string;
  voucherType: VoucherType;
  code: string;
  discountAmount: number;
  startDate: Date;
  endDate: Date;
}

interface SaleEditRequest {
  name: string;
  contactForm: string;
  dateTiltle: string;
  discountAmount: number;
  status: number;
  saleEndTime: string;
  saleStartTime: string;
  countdownTime: string;
}
const url = EndPoint.sale;

export const salesApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'salesApi',
  tagTypes: ['Sale'],
  endpoints: builder => ({
    createSale: builder.mutation<
      IGenericResponse<any>,
      Partial<ICreateFlashSaleParams>[] | Partial<ICreateFlashSaleParams>
    >({
      query: payloadFlashSale => ({
        url: '',
        method: 'POST',
        body: payloadFlashSale,
      }),
      invalidatesTags: ['Sale'],
    }),
    createMultiSale: builder.mutation<
      IGenericResponse<any>,
      Partial<ICreateFlashSaleParams>[] | Partial<ICreateFlashSaleParams>
    >({
      query: payloadFlashSale => ({
        url: '/multiple',
        method: 'POST',
        body: { flashSales: payloadFlashSale },
      }),
      invalidatesTags: ['Sale'],
    }),
    getAll: builder.query<
      IListResponse<ISaleDetail>,
      Partial<VoucherFilterDTO>
    >({
      query: params => {
        const { page, ...payload } = params as any;
        return {
          url: '',
          method: 'GET',
          params: payload,
        };
      },
      providesTags: ['Sale'],
      transformResponse: (
        result: IGenericResponse<IListResponse<ISaleDetail>>,
      ) => {
        return result.data;
      },
    }),
    editSale: builder.mutation<
      IGenericResponse<any>,
      Partial<SaleEditRequest> & { _id: string }
    >({
      query: voucherData => {
        const { _id, ...payload } = voucherData;
        return {
          url: `/${_id}`,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Sale'],
    }),
    deleteSale: builder.mutation<IGenericResponse<any>, string>({
      query: id => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sale'],
      // onQueryStarted: async (id, { dispatch, queryFulfilled }) => {
      //   await queryFulfilled;
      // },
    }),
    sendVerifyOtpSale: builder.mutation<IGenericResponse<any>, void>({
      query: () => ({
        url: '/sms',
        method: 'POST',
      }),
    }),
    sendCodeOtpSale: builder.mutation<IGenericResponse<any>, string>({
      query: code => {
        return {
          url: `/sms`,
          method: 'GET',
          params: { code },
        };
      },
    }),
  }),
});

export const {
  useGetAllQuery,
  useEditSaleMutation,
  useCreateSaleMutation,
  useCreateMultiSaleMutation,
  useDeleteSaleMutation,
  useLazyGetAllQuery,
  useSendCodeOtpSaleMutation,
  useSendVerifyOtpSaleMutation,
} = salesApi;
