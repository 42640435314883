import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Divider,
  Grid,
  Modal,
  Stack,
} from '@mui/material';
import React from 'react';
import { ICardMedia } from 'types/LayoutHome.type';
import MediaCard from './Components/MediaCard';
import DialogHandle from 'app/components/DialogHandle';

const fakeData: Array<ICardMedia> = [
  {
    _id: '1',
    title: 'Tư vấn hướng dẫn seo google map TOP 1',
    tag: '@SEO MAP',
    url_tag: 'https://askany.com/ky-nang?slug=%20map',
    thumbnail:
      'https://dxe6lti90zltq.cloudfront.net/images/c728679f-bd97-404b-b26e-04c248fbab0f.png',
    url_contact: 'https://askany.com/chi-tiet-ky-nang/63e5fb3d43c3521704dd8e1a',
    idTracking: 'LienheseomapHannie',
    author: 'hannie',
  },
  {
    _id: '2',
    title: 'Tư vấn hướng dẫn seo google map TOP 1',
    tag: '@SEO MAP',
    url_tag: 'https://askany.com/ky-nang?slug=%20map',
    thumbnail:
      'https://dxe6lti90zltq.cloudfront.net/images/c728679f-bd97-404b-b26e-04c248fbab0f.png',
    url_contact: 'https://askany.com/chi-tiet-ky-nang/63e5fb3d43c3521704dd8e1a',
    idTracking: 'LienheseomapHannie',
    author: 'hannie',
  },
  {
    _id: '3',
    title: 'Tư vấn hướng dẫn seo google map TOP 1',
    tag: '@SEO MAP',
    url_tag: 'https://askany.com/ky-nang?slug=%20map',
    thumbnail:
      'https://dxe6lti90zltq.cloudfront.net/images/c728679f-bd97-404b-b26e-04c248fbab0f.png',
    url_contact: 'https://askany.com/chi-tiet-ky-nang/63e5fb3d43c3521704dd8e1a',
    idTracking: 'LienheseomapHannie',
    author: 'hannie',
  },
  {
    _id: '4',
    title: 'Tư vấn hướng dẫn seo google map TOP 1',
    tag: '@SEO MAP',
    url_tag: 'https://askany.com/ky-nang?slug=%20map',
    thumbnail:
      'https://dxe6lti90zltq.cloudfront.net/images/c728679f-bd97-404b-b26e-04c248fbab0f.png',
    url_contact: 'https://askany.com/chi-tiet-ky-nang/63e5fb3d43c3521704dd8e1a',
    idTracking: 'LienheseomapHannie',
    author: 'hannie',
  },
];

const styleModalDetail = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const LayoutsPage = () => {
  const [isEdit, setEdit] = React.useState<{
    open: boolean;
    detail?: ICardMedia;
  }>({
    open: false,
    detail: undefined,
  });

  const [isDelete, setDelete] = React.useState<boolean>(false);

  return (
    <div>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <CardHeader
            title="Dịch vụ tư vấn phổ biến"
            titleTypographyProps={{ variant: 'h6' }}
          />
          <div>
            <Button style={{ fontWeight: 600 }} variant="contained">
              {' '}
              + Thêm
            </Button>
          </div>
        </Stack>

        <Divider sx={{ margin: 0 }} />
        <CardContent>
          <Grid
            container
            spacing={{ xs: 2, md: 5 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {fakeData.map(item => {
              return (
                <Grid item xs={2} sm={3} md={3} key={item._id}>
                  <MediaCard
                    onEdit={() => {
                      setEdit({ open: true, detail: item });
                    }}
                    data={item}
                  />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      {/* Modal */}

      {isEdit.open && (
        <Modal
          open={isDelete}
          onClose={() => setDelete(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleModalDetail}>he</Box>
        </Modal>
      )}
      {isDelete && (
        <DialogHandle
          title="Lưu ý"
          body="Bạn có muốn xóa dịch vụ này không!"
          open={isDelete}
          onClose={() => setDelete(false)}
          onHandle={() => {
            return;
          }}
        />
      )}
    </div>
  );
};

export default LayoutsPage;
