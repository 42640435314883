import { TextField, TextFieldProps } from '@mui/material';
import React, { ForwardedRef, forwardRef } from 'react';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
}
type InputProps<T extends FieldValues> = Props<T> &
  TextFieldProps &
  Omit<NumericFormatProps, 'size'>;

const ControlledNumberTextField = <T extends FieldValues>(
  props: InputProps<T>,
) => {
  const { control, name, ...inputProps } = props;
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, name, value, ref, onBlur },
        fieldState: { error },
      }) => (
        <NumericFormat
          {...inputProps}
          getInputRef={ref}
          name={name}
          onValueChange={value => {
            onChange(value.floatValue);
          }}
          allowLeadingZeros={inputProps.allowLeadingZeros ?? false}
          defaultValue={value}
          customInput={TextField}
          thousandSeparator
          allowNegative={false}
          onBlur={e => {
            onBlur();
            inputProps.onBlur?.(e);
          }}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
};

export default ControlledNumberTextField;
