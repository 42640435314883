import { Box, Stack } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetAccountByIdQuery } from 'redux/services/accounts.services';

const AccountDetailPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data: userData, isError } = useGetAccountByIdQuery(
    userId || 'undefined',
  );
  useEffect(() => {
    if (isError) {
      navigate('/404');
    }
  }, [isError, navigate]);

  return (
    <Box>
      <Stack>{userData?.username}</Stack>
      <Stack>{userData?.email}</Stack>
    </Box>
  );
};

export default AccountDetailPage;
