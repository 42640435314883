import { read, utils, writeFile, writeFileXLSX } from 'xlsx';
import { util } from 'zod';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { headCellsExpert } from 'constants/voucher.constant';

interface ExportExcelOptions {
  header: string[];
  headerName: string[];
}

const BoxExportFile = ({ data }) => {
  const exportExcel = <T,>(data: Array<T>, options: ExportExcelOptions) => {
    const worksheet = utils.json_to_sheet(data, {
      // dateNF: 'HH:mm DD/MM/YYYY',
      // header: options?.header,
    });
    const workbook = utils.book_new();
    utils.sheet_add_aoa(worksheet, [options.headerName]);
    utils.book_append_sheet(workbook, worksheet);
    writeFileXLSX(workbook, 'testsheet.xlsx');
  };
  // const importExcel = async (file: File) => {
  //   const data = await file.arrayBuffer();
  //   const workbook = read(data);
  //   const rs = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {});
  //   console.log(rs);
  // };
  const headerNameValue = headCellsExpert.map(item => item.label);
  const headerValue = headCellsExpert.map(item => item.id);

  return (
    <Button
      sx={{ marginLeft: 3 }}
      type="button"
      variant="contained"
      onClick={() =>
        exportExcel(data, { header: headerValue, headerName: headerNameValue })
      }
      endIcon={<FileDownloadIcon />}
    >
      Xuất file excel
    </Button>
  );
};

export default BoxExportFile;
