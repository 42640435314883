import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IGenericResponse } from 'types/GenericResponse';
import { baseQuery, onQueryStartedErrorToast } from './fetchQuery';
import { IRoleResponse } from 'types/Role';
import { toast } from 'react-toastify';

const url = EndPoint.roles;

export const rolesApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'rolesApi',
  tagTypes: ['Roles', 'Accounts'],
  endpoints: builder => ({
    get: builder.query<Array<IRoleResponse>, void>({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: ['Roles'],
      transformResponse: (result: IGenericResponse<Array<IRoleResponse>>) => {
        return result.data;
      },
      onQueryStarted: onQueryStartedErrorToast,
    }),
    create: builder.mutation<IGenericResponse<any>, Omit<IRoleResponse, '_id'>>(
      {
        query: data => ({
          url: '',
          method: 'POST',
          body: data,
        }),
        invalidatesTags: ['Roles'],
        onQueryStarted: onQueryStartedErrorToast,
      },
    ),
    update: builder.mutation<any, IRoleResponse>({
      query: data => {
        const { _id, ...payload } = data;
        return {
          url: _id,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['Roles', 'Accounts'],
      onQueryStarted: onQueryStartedErrorToast,
    }),
    delete: builder.mutation<any, string>({
      query: id => ({
        url: id,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles', 'Accounts'],
      onQueryStarted: onQueryStartedErrorToast,
    }),
  }),
});

export const {
  useGetQuery: useGetRolesQuery,
  useCreateMutation: useCreateRoleMutation,
  useDeleteMutation: useDeleteRoleMutation,
  useUpdateMutation: useUpdateRoleMutation,
} = rolesApi;
