import { toast } from 'react-toastify';

export const notifyError = (msg: string, duration?: number) =>
  toast.error(msg, {
    position: 'top-right',
    autoClose: duration || 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

export const notifySuccess = (msg: string, duration?: number) =>
  toast.success(msg, {
    position: 'top-right',
    autoClose: duration || 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
