import { useCookies } from 'react-cookie';

const useCookiePermission = () => {
  const [cookies] = useCookies(['permissions']);
  const permissions = cookies.permissions;
  const havePermission = (p: number) => {
    if (!permissions || !Array.isArray(permissions)) return false;
    return permissions?.includes?.(p);
  };
  const haveSomePermssion = (ps: number[]) => {
    if (!permissions || !Array.isArray(permissions)) return false;
    return ps.some(v => permissions?.includes?.(v));
  };
  return {
    permissions,
    havePermission,
    haveSomePermssion,
  };
};

export default useCookiePermission;
