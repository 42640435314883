import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { useEffect } from 'react';

// ** Third Party Imports
import DatePicker from 'react-datepicker';

// ** Styled Components
import DatePickerWrapper from '@core/styles/libs/react-datepicker';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  FormHelperText,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import UserOption from 'app/components/AutocompleteComponents/UserOption';
import ControlledNumberTextField from 'app/components/Form/ControlledNumberTextField';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import CustomInputOutDate from 'app/components/Form/CustomInputDate';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useGetExpertByIdMutation,
  useGetExpertsMutation,
} from 'redux/services/experts.services';
import {
  useCreateVoucherMutation,
  useDeleteVoucherMutation,
  useEditVoucherMutation,
} from 'redux/services/voucher.services';
import {
  IVoucher,
  VoucherDiscountType,
  VoucherScope,
  VoucherStatus,
  VoucherType,
} from '../../../../types/Voucher';
// import { VoucherInput, voucherSchema } from './VoucherSchemas';
import VoucherOtpStep from '../Component/BoxOtpVoucher/VoucherOtpStep';
import { IExpert } from 'types/Expert';
import { notifyError, notifySuccess } from 'app/components/Toast';
import { SYSTEM_CODE, SYSTEM_CODE_AC } from 'constants/systemCode';
import { AppUser } from 'types/AppUser';
import { VoucherInput, voucherSchema } from 'schema/voucher.schema';
import {
  DiscountTypeText,
  VoucherStatusText,
} from 'constants/voucher.constant';

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const VoucherDetail = ({
  defaultVoucherData,
  handleClose = () => {
    return;
  },
}: {
  defaultVoucherData?: IVoucher;
  handleClose?: () => void;
}) => {
  const modalType: 'edit' | 'add' = defaultVoucherData ? 'edit' : 'add';
  const methods = useForm<VoucherInput>({
    resolver: zodResolver(voucherSchema),
    mode: 'onBlur',
    defaultValues: {
      ...defaultVoucherData,
      expert: defaultVoucherData?._id,
      consultingTime: defaultVoucherData?.consultingTime,
      users: defaultVoucherData?.users?.map(u => u._id),
      discountPercentage: defaultVoucherData?.discountPercentage
        ? defaultVoucherData?.discountPercentage * 100
        : undefined,
      startDate: defaultVoucherData?.startDate || dayjs().toISOString(),
      endDate:
        defaultVoucherData?.endDate || dayjs().add(1, 'week').toISOString(),
    },
  });
  const {
    handleSubmit,
    control,
    clearErrors,
    watch,
    getValues,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = methods;
  const watchQuantity = useWatch({
    control: control,
    name: ['maxDiscountAmount', 'budget', 'discountAmount'],
  });
  const [activeStep, setActiveStep] = useState(1);
  const [createVoucher, { isError: errorCreate }] = useCreateVoucherMutation();
  const [updateVoucher, { isError: errorEdit }] = useEditVoucherMutation();
  const errorVoucherPost = errorCreate || errorEdit;
  const [deleteVoucher] = useDeleteVoucherMutation();
  const otpSubmitBtnRef = useRef<{ handleOTP: () => Promise<boolean> }>(null);
  const [expertsPicked, setExpertsPicked] = useState<IExpert>();
  const [userPicked, setUserPicked] = useState<Array<IExpert>>([]);
  const [getExperts, { isLoading: isLoadingExperts, data: listExperts }] =
    useGetExpertsMutation();
  const [getUsers, { isLoading: isLoadingUsers, data: listUsers }] =
    useGetExpertsMutation();
  const [getById, { data: dataById }] = useGetExpertByIdMutation();
  const voucherType = useWatch({ control: control, name: 'voucherType' });
  const quantity = useWatch({ control: control, name: 'quantity' });
  const discountType = useWatch({ control: control, name: 'discountType' });
  const status = useWatch({ control: control, name: 'status' });

  useEffect(() => {
    setUserPicked(defaultVoucherData?.users || []);
  }, [defaultVoucherData]);

  useEffect(() => {
    if (voucherType === VoucherType.Expert) {
      setValue('discountType', discountType || VoucherDiscountType.FlatMoney);
    }
  }, [setValue, voucherType]);

  useEffect(() => {
    const budget = getValues('budget');
    const amount = getValues('discountAmount');
    const maxAmount = getValues('maxDiscountAmount');
    const type = getValues('discountType');
    const typeVoucher = getValues('voucherType');
    const timeConsulting = getValues('consultingTime');
    const listExpertVoucher = getValues('expert');
    const listUserVoucher = getValues('users');
    const scopeVoucher = getValues('scope');

    if (type === VoucherDiscountType.Percent && maxAmount) {
      const quantity = Math.floor(budget / maxAmount);
      setValue('quantity', quantity);
    }
    if (type === VoucherDiscountType.FlatMoney && amount) {
      const quantity = Math.floor(budget / amount);
      setValue('quantity', quantity);
    }

    if (typeVoucher === VoucherType.Expert && modalType === 'edit') {
      setExpertsPicked({
        _id: listExpertVoucher || '',
        expertname: listExpertVoucher || '',
        fullname: listExpertVoucher || '',
        avatar: undefined,
      } as any);
    }
  }, [getValues, setValue, watchQuantity]);

  const listSearchExpert: IExpert[] = (listExperts?.listData || []).concat(
    dataById?.listData || [],
  );
  const [open, setOpen] = React.useState(false);
  const handleOpenWarning = () => {
    setOpen(true);
  };
  const handleCloseWarning = () => {
    setOpen(false);
  };

  useEffect(() => {
    setActiveStep(1);
  }, [errorVoucherPost]);

  const submitForm: SubmitHandler<VoucherInput> = async data => {
    if (data.discountType === VoucherDiscountType.FlatMoney) {
      delete data.maxDiscountAmount;
    }
    if (data.discountType === VoucherDiscountType.DISCOUNT_BY_TIME) {
      delete data.discountAmount;
      delete data.discountPercentage;
      delete data.minBookingPrice;
      delete data.scope;
    }
    if (data.voucherType === VoucherType.Expert) {
      delete data.users;
    }
    if (data.voucherType === VoucherType.User) {
      delete data.expert;
      if (data.scope === VoucherScope.AllUser) {
        delete data.users;
      }
    }
    data.code = data.code.toUpperCase();
    const { ...payload } = data;

    try {
      if (!defaultVoucherData) {
        await createVoucher({
          ...payload,
        })
          .unwrap()
          .then(() => {
            isDirty && notifySuccess('Thêm mới thành công');
            handleClose();
          })
          .catch(e => {
            // console.log('e', e);
            notifyError(SYSTEM_CODE_AC[e.data?.systemCode]);
          });
      } else {
        await updateVoucher({
          ...defaultVoucherData,
          status: payload.status,
        } as any)
          .unwrap()
          .then(() => {
            isDirty && notifySuccess('Chỉnh sửa thành công');
            handleClose();
          })
          .catch(e => {
            notifyError(SYSTEM_CODE[e.data?.systemCode]);
          });
      }
    } catch {}
  };
  const handleOpen = async () => {
    const valid = await trigger();
    if (!valid) return;
    setActiveStep(2);
  };
  const handleDeleteVoucher = () => {
    if (defaultVoucherData) {
      try {
        deleteVoucher(defaultVoucherData._id)
          .unwrap()
          .then(() => {
            toast.success('Xóa thành công');
            handleClose();
          })
          .catch(() => {
            toast.error('Đã xảy ra lỗi');
          });
      } catch {}
    }
  };
  const checkCanDelete = (deleteFn: (event: any) => void, user: AppUser) => {
    if (!defaultVoucherData?.users?.some(u => u._id === user._id))
      return deleteFn;
    return undefined;
  };
  // const uniqueId = () => parseInt(Date.now() * Math.random()).toString();
  const HandleRandomCode = () => {
    const codeDefault = 'ASKANY';
    const uniqueId = Math.random().toString(36).substring(2, 6).toUpperCase();
    setValue('code', codeDefault + uniqueId);
  };

  useEffect(() => {
    if (modalType === 'add') {
      HandleRandomCode();
    }
    return;
  }, []);

  return (
    <Card>
      <CardHeader
        title={modalType === 'add' ? 'Thêm voucher' : 'Chi tiết voucher'}
        titleTypographyProps={{ variant: 'h6' }}
      />
      <Divider sx={{ margin: 0 }} />
      {activeStep !== 2 && (
        <form>
          <CardContent>
            <Grid container spacing={5}>
              <Grid item xs={12} md={6}>
                <ControlledTextField
                  control={control}
                  size="small"
                  fullWidth
                  sx={{
                    input: {
                      textTransform: 'uppercase',
                    },
                    'input::placeholder': {
                      textTransform: 'none',
                    },
                  }}
                  name="code"
                  type="text"
                  disabled={true}
                  label="Mã Voucher"
                  // placeholder="Nhập mã voucher"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <ControlledTextField
                  size="small"
                  control={control}
                  fullWidth
                  name="voucherName"
                  type="text"
                  label="Tên Voucher"
                  placeholder="Nhập tên voucher"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DriveFileRenameOutlineIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ControlledNumberTextField
                  decimalScale={0}
                  size="small"
                  control={control}
                  fullWidth
                  name="budget"
                  label="Ngân sách"
                  placeholder="100,000"
                  suffix=" VND"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel size="small">Trạng thái</InputLabel>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        label="Trạng thái"
                        {...field}
                        value={field.value ?? ''}
                        error={!!errors.status}
                        size="small"
                      >
                        <MenuItem value={VoucherStatus.Working}>
                          {VoucherStatusText[VoucherStatus.Working]}
                        </MenuItem>
                        <MenuItem value={VoucherStatus.Hidden}>
                          {VoucherStatusText[VoucherStatus.Hidden]}
                        </MenuItem>
                        <MenuItem value={VoucherStatus.Deleted}>
                          {VoucherStatusText[VoucherStatus.Deleted]}
                        </MenuItem>
                        <MenuItem value={VoucherStatus.Lock}>
                          {VoucherStatusText[VoucherStatus.Lock]}
                        </MenuItem>
                        <MenuItem value={VoucherStatus.Expired}>
                          {VoucherStatusText[VoucherStatus.Expired]}
                        </MenuItem>
                        <MenuItem value={VoucherStatus.OutOf}>
                          {VoucherStatusText[VoucherStatus.OutOf]}
                        </MenuItem>
                      </Select>
                    )}
                  />
                  <FormHelperText error>
                    {errors.status?.message}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={7} rowSpacing={4}>
                  <Grid item xs={12}>
                    <Typography sx={{ typography: 'caption' }}>
                      Loại voucher
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel size="small">Loại voucher</InputLabel>
                      <Controller
                        name="voucherType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            label="Loại voucher"
                            {...field}
                            value={field.value ?? ''}
                            error={!!errors.voucherType}
                            size="small"
                          >
                            <MenuItem value={VoucherType.Expert}>
                              Chuyên gia
                            </MenuItem>
                            <MenuItem value={VoucherType.User}>
                              Người dùng
                            </MenuItem>
                          </Select>
                        )}
                      />
                      <FormHelperText error>
                        {errors.voucherType?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {voucherType === VoucherType.Expert && (
                    <Grid item xs={12} sm={6}>
                      <Autocomplete
                        freeSolo
                        multiple
                        value={expertsPicked ? [expertsPicked] : []}
                        // ListboxProps={{
                        //   onScroll: (event: React.SyntheticEvent) => {
                        //     const listboxNode = event.currentTarget;
                        //     if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                        //       getExperts({
                        //         search: newInputEvent,
                        //         isExpert: true,
                        //         limit: 20,
                        //       }),
                        //     }
                        //   }
                        // }}
                        onChange={(event, value) => {
                          if (value.length <= 0) {
                            setExpertsPicked(undefined);
                            setValue('expert', undefined);
                          } else {
                            const newest = [...value].pop();
                            if (newest) {
                              if (typeof newest !== 'string') {
                                setExpertsPicked(newest);
                              } else {
                                setExpertsPicked({
                                  _id: newest,
                                  fullname: newest,
                                  expertname: newest,
                                } as IExpert);
                              }
                            }
                            setValue(
                              'expert',
                              typeof newest === 'string' ? newest : newest?._id,
                            );
                          }
                        }}
                        getOptionLabel={option =>
                          typeof option === 'string' ? option : option.fullname
                        }
                        loading={isLoadingExperts}
                        onInputChange={debounce(
                          (event, newInputEvent) =>
                            newInputEvent &&
                            getExperts({
                              search: newInputEvent,
                              isExpert: true,
                              limit: 20,
                            }),
                          500,
                        )}
                        filterOptions={x => x}
                        isOptionEqualToValue={(option, value) =>
                          value._id === option._id
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            size="small"
                            label="Chuyên gia"
                            error={!!errors.expert}
                            helperText={errors.expert?.message}
                          />
                        )}
                        PaperComponent={props => (
                          <Paper {...props} elevation={24} />
                        )}
                        renderTags={(users, getTagProps, state) =>
                          users.map((u, index) => (
                            <Chip
                              key={u._id ?? u}
                              variant="outlined"
                              avatar={
                                <Avatar
                                  alt={u.fullname}
                                  src={u.avatar?.location}
                                />
                              }
                              label={u.fullname ?? u}
                              onDelete={getTagProps({ index }).onDelete}
                            ></Chip>
                          ))
                        }
                        renderOption={(props, option) => (
                          <UserOption {...props} expert={option} />
                        )}
                        noOptionsText={'Không tìm thấy chuyên gia'}
                        options={listSearchExpert}
                      />
                    </Grid>
                  )}
                  {voucherType === VoucherType.User && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel size="small">Phạm vi</InputLabel>
                          <Controller
                            name="scope"
                            control={control}
                            render={({ field }) => (
                              <Select
                                label="Phạm vi"
                                {...field}
                                value={field.value ?? ''}
                                error={!!errors.voucherType}
                                size="small"
                              >
                                <MenuItem value={VoucherScope.AllUser}>
                                  Tất cả người dùng
                                </MenuItem>
                                <MenuItem value={VoucherScope.SomeUser}>
                                  Một số người dùng
                                </MenuItem>
                              </Select>
                            )}
                          />
                          <FormHelperText error>
                            {errors.scope?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {watch('scope') === VoucherScope.SomeUser && (
                        <Grid item xs={12}>
                          <Autocomplete
                            freeSolo
                            multiple
                            value={userPicked}
                            onChange={(e, newValue) => {
                              const mappedValue = newValue.map(u => {
                                if (typeof u === 'string') {
                                  return {
                                    _id: u,
                                    fullname: u,
                                  } as AppUser;
                                }
                                return u;
                              });
                              setUserPicked(mappedValue);
                              setValue(
                                'users',
                                mappedValue.map(e => e._id),
                              );
                            }}
                            PaperComponent={props => (
                              <Paper {...props} elevation={24} />
                            )}
                            getOptionLabel={option =>
                              typeof option === 'string'
                                ? option
                                : option.fullname
                            }
                            renderTags={(users, getTagProps, state) =>
                              users.map((u, index) => (
                                <Chip
                                  key={u._id ?? u}
                                  variant="outlined"
                                  avatar={
                                    <Avatar
                                      alt={u.fullname}
                                      src={u.avatar?.location}
                                    />
                                  }
                                  label={u.fullname ?? u}
                                  onDelete={checkCanDelete(
                                    getTagProps({ index }).onDelete,
                                    u,
                                  )}
                                ></Chip>
                              ))
                            }
                            onInputChange={debounce(
                              (event, newInputEvent) =>
                                newInputEvent &&
                                getUsers({
                                  search: newInputEvent,
                                  limit: 20,
                                }),
                              500,
                            )}
                            filterOptions={x => x}
                            loading={isLoadingUsers}
                            isOptionEqualToValue={(option, value) =>
                              value._id === option._id
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                multiline
                                label="Người dùng"
                                error={!!errors.users}
                                helperText={errors.users?.message}
                              />
                            )}
                            renderOption={(props, option) => (
                              <UserOption {...props} expert={option} />
                            )}
                            options={listUsers?.listData || []}
                            noOptionsText={'Không tìm thấy người dùng'}
                          />
                        </Grid>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={7} rowSpacing={4}>
                  <Grid item xs={12}>
                    <Typography sx={{ typography: 'caption' }}>
                      Loại hình giảm giá
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel size="small">Loại hình giảm giá</InputLabel>
                      <Controller
                        name="discountType"
                        control={control}
                        render={({ field }) => (
                          <Select
                            label="Loại hình giảm giá"
                            {...field}
                            onChange={e => {
                              clearErrors();
                              field.onChange(e);
                            }}
                            value={discountType || ''}
                            error={!!errors.discountType}
                            size="small"
                          >
                            {voucherType !== VoucherType.Expert && (
                              <MenuItem value={VoucherDiscountType.Percent}>
                                {DiscountTypeText[VoucherDiscountType.Percent]}
                              </MenuItem>
                            )}
                            <MenuItem value={VoucherDiscountType.FlatMoney}>
                              {DiscountTypeText[VoucherDiscountType.FlatMoney]}
                            </MenuItem>
                            <MenuItem
                              value={VoucherDiscountType.DISCOUNT_BY_TIME}
                            >
                              {
                                DiscountTypeText[
                                  VoucherDiscountType.DISCOUNT_BY_TIME
                                ]
                              }
                            </MenuItem>
                          </Select>
                        )}
                      />
                      <FormHelperText error>
                        {errors.discountType?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  {discountType === VoucherDiscountType.Percent && (
                    <Grid item xs={12} sm={6}>
                      <ControlledNumberTextField
                        decimalScale={0}
                        control={control}
                        fullWidth
                        name="discountPercentage"
                        label="Phần trăm giảm"
                        placeholder="10"
                        suffix="%"
                        size="small"
                      />
                    </Grid>
                  )}
                  {discountType === VoucherDiscountType.FlatMoney && (
                    <Grid item xs={12} sm={6}>
                      <ControlledNumberTextField
                        decimalScale={0}
                        size="small"
                        control={control}
                        fullWidth
                        name="discountAmount"
                        label="Số tiền giảm"
                        placeholder="100,000 VND"
                        suffix=" VND"
                      />
                    </Grid>
                  )}
                  {discountType === VoucherDiscountType.DISCOUNT_BY_TIME && (
                    <Grid item xs={12} sm={6}>
                      <ControlledNumberTextField
                        decimalScale={0}
                        control={control}
                        fullWidth
                        name="consultingTime"
                        label="Số phút miễn phí"
                        placeholder="10"
                        size="small"
                      />
                    </Grid>
                  )}
                  {discountType !== VoucherDiscountType.DISCOUNT_BY_TIME &&
                    status === VoucherStatus.Hidden && (
                      <Grid item xs={12} sm={6}>
                        <ControlledNumberTextField
                          decimalScale={0}
                          control={control}
                          fullWidth
                          name="consultingTime"
                          label="Thời gian tối thiểu"
                          placeholder="10"
                          size="small"
                        />
                      </Grid>
                    )}
                </Grid>
              </Grid>
              {discountType === VoucherDiscountType.Percent && (
                <Grid item xs={12} sm={6}>
                  <ControlledNumberTextField
                    decimalScale={0}
                    size="small"
                    control={control}
                    fullWidth
                    name="maxDiscountAmount"
                    label="Số tiền giảm tối đa"
                    placeholder="100,000 VND"
                    suffix=" VND"
                  />
                </Grid>
              )}
              {discountType !== VoucherDiscountType.DISCOUNT_BY_TIME && (
                <Grid item xs={12} sm={6}>
                  <ControlledNumberTextField
                    decimalScale={0}
                    size="small"
                    control={control}
                    fullWidth
                    name="minBookingPrice"
                    label="Book ít nhất"
                    placeholder="100,000 VND"
                    suffix=" VND"
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <Grid container spacing={7} rowSpacing={4}>
                  <Grid item xs={12} sm={6}>
                    <ControlledNumberTextField
                      decimalScale={0}
                      control={control}
                      disabled={
                        discountType === VoucherDiscountType.DISCOUNT_BY_TIME
                          ? false
                          : true
                      }
                      value={quantity}
                      size="small"
                      fullWidth
                      name="quantity"
                      label="Số lượng"
                      placeholder="100"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={7} rowSpacing={4}>
                  <Grid item xs={12}>
                    <Typography sx={{ typography: 'caption' }}>
                      Thời gian
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePickerWrapper>
                          <DatePicker
                            selected={value ? new Date(value) : undefined}
                            showYearDropdown
                            showMonthDropdown
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText="HH:mm DD/MM/YYYY"
                            dateFormat="HH:mm dd/MM/yyyy"
                            customInput={
                              <CustomInputOutDate
                                size="small"
                                label="Ngày bắt đầu"
                                error={!!error}
                                helperText={error?.message}
                              />
                            }
                            onChange={onChange}
                            onMonthChange={onChange}
                            onYearChange={onChange}
                          />
                        </DatePickerWrapper>
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <DatePickerWrapper>
                          <DatePicker
                            selected={value ? new Date(value) : undefined}
                            showYearDropdown
                            showMonthDropdown
                            showTimeSelect
                            timeFormat="HH:mm"
                            placeholderText="HH:mm DD/MM/YYYY"
                            dateFormat="HH:mm dd/MM/yyyy"
                            customInput={
                              <CustomInputOutDate
                                size="small"
                                value={value}
                                label="Ngày hết hạn"
                                error={!!error}
                                helperText={error?.message}
                              />
                            }
                            onChange={onChange}
                            onMonthChange={onChange}
                            onYearChange={onChange}
                          />
                        </DatePickerWrapper>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </form>
      )}
      {activeStep === 2 && (
        <Box
          sx={{
            marginTop: 4,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            height: 200,
          }}
        >
          <Typography sx={{ typography: 'body' }} align="center">
            Nhập mã OTP
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: 'grid',
              placeItems: 'center',
            }}
          >
            <Box
              sx={{
                maxWidth: 400,
              }}
            >
              <VoucherOtpStep ref={otpSubmitBtnRef} />
            </Box>
          </Box>
        </Box>
      )}
      <Divider sx={{ margin: 0 }} />
      <CardActions>
        <Grid container>
          <Grid item xs={12}>
            {activeStep > 1 && (
              <Button
                onClick={() => setActiveStep(s => s - 1)}
                disabled={activeStep <= 1}
                variant="contained"
                sx={{ marginRight: 3.5 }}
              >
                quay lại
              </Button>
            )}
            <Button
              onClick={() => {
                if (activeStep >= 2) {
                  otpSubmitBtnRef.current?.handleOTP().then(result => {
                    if (result) {
                      handleSubmit(submitForm)();
                    }
                  });
                } else {
                  handleOpen();
                }
              }}
              disabled={!isDirty}
              variant="contained"
              sx={{ marginRight: 3.5 }}
            >
              {activeStep === 2
                ? modalType === 'add'
                  ? 'Thêm'
                  : 'Lưu thay đổi'
                : 'Tiếp tục'}
            </Button>
            {defaultVoucherData && activeStep === 1 && (
              <Button
                sx={{ marginRight: 3.5 }}
                type="reset"
                variant="contained"
                color="error"
                onClick={handleOpenWarning}
              >
                Xóa voucher
              </Button>
            )}
            <Button
              type="reset"
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Hủy
            </Button>
          </Grid>
        </Grid>
      </CardActions>
      {/* {openOTP && (
        <BoxOtpVoucher
          open={openOTP}
          handleClose={() => setOpenOTP(false)}
          onClose={() => setOpenOTP(false)}
          onHandle={() => {
            handleSubmit(submitForm);
          }}
          keepMounted={false}
        />
      )} */}
      <Modal
        open={open}
        onClose={handleCloseWarning}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleModal}>
          Bạn có chắc chắn muốn xóa Voucher này?
          <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'end' }}>
            <Button
              variant="contained"
              color="error"
              sx={{ marginRight: 2 }}
              onClick={() => {
                handleDeleteVoucher();
                handleCloseWarning();
              }}
            >
              Xóa
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCloseWarning}
            >
              Hủy
            </Button>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
};

export default VoucherDetail;
