import {
  Box,
  Card,
  CardContent,
  Grid,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { IExperience, IExpert, ISkill } from 'types/Expert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';

const TabSkill = ({ info }: { info?: IExpert }) => {
  return (
    <Stack gap={4}>
      {info?.skills.map(skill => (
        <CardSkill key={skill._id} skill={skill} />
      ))}
    </Stack>
  );
};

export default TabSkill;

const CardSkill = ({ skill }: { skill: ISkill }) => {
  const listShow = [
    {
      key: 'ID',
      value: skill._id,
    },
    {
      key: 'Tên kỹ năng',
      value: skill.name,
    },
  ];
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container>
          {listShow.map((detail, index) => (
            <Grid key={index} item sm={12} md={6} paddingY={1}>
              <Typography variant="body2" gutterBottom>
                <Stack direction="row" alignItems="center" gap={2} height={40}>
                  <strong>{detail.key}</strong> {detail.value}
                </Stack>
              </Typography>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
