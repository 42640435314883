import RoutePermission from 'app/components/RouteGuard/RoutePermission';
import AccountsPage from 'app/pages/Accounts';
import CreateAccountPage from 'app/pages/Accounts/create';
import AccountDetailPage from 'app/pages/Accounts/details';
import ExpertPage from 'app/pages/Experts';
import ExpertDetailPage from 'app/pages/Experts/ExpertDetail';
import FlashSalePage from 'app/pages/FlashSale';
import { RouteObject } from 'react-router-dom';

const ExpertRoutes: RouteObject = {
  path: 'experts',
  children: [
    {
      index: true,
      element: (
        <RoutePermission permissions={[]}>
          <ExpertPage />
        </RoutePermission>
      ),
    },
    {
      path: 'sale',
      element: (
        <RoutePermission permissions={[]}>
          <FlashSalePage />
        </RoutePermission>
      ),
    },
    {
      path: 'create',
      element: (
        <RoutePermission permissions={[]}>
          <CreateAccountPage />
        </RoutePermission>
      ),
    },
    {
      path: ':expertId',
      element: (
        <RoutePermission permissions={[]}>
          <ExpertDetailPage />
        </RoutePermission>
      ),
    },
  ],
};

export default ExpertRoutes;
