import { zodResolver } from '@hookform/resolvers/zod';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import ControlledOutlinedInput from 'app/components/Form/ControlledOutlinedInput';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import AccountOutline from 'mdi-material-ui/AccountOutline';
import EmailOutline from 'mdi-material-ui/EmailOutline';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import Phone from 'mdi-material-ui/Phone';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useCreateAccountMutation } from 'redux/services/accounts.services';
import { TypeOf, ZodType, z } from 'zod';

const createUserSchema: ZodType<{
  username: string;
  password: string;
  email?: string;
  phone?: string;
}> = z.object({
  username: z.string().min(1, 'Vui lòng nhập username'),
  password: z.string().min(1, 'Vui lòng nhập password'),
  email: z.string().optional(),
  phone: z.string().optional(),
});

type CreateInput = TypeOf<typeof createUserSchema>;

const CreateAccountPage = () => {
  const [createAccount, { isLoading }] = useCreateAccountMutation();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<CreateInput>({
    resolver: zodResolver(createUserSchema),
    defaultValues: {
      username: undefined,
      password: undefined,
      email: undefined,
      phone: undefined,
    },
  });
  const submitCreate: SubmitHandler<CreateInput> = data => {
    createAccount(data)
      .unwrap()
      .then(() => {
        reset();
        toast.success('Tạo thành công');
      });
  };
  return (
    <Card style={{ maxWidth: '600px' }}>
      <CardHeader
        title="Tạo tài khoản"
        titleTypographyProps={{ variant: 'h6' }}
      />
      <CardContent>
        {/* <Typography sx={{ typography: 'h5' }}>Tạo tài khoản</Typography> */}
        <Grid
          component={'form'}
          container
          spacing={7}
          rowSpacing={4}
          marginTop={2}
          onSubmit={handleSubmit(submitCreate)}
        >
          <Grid item xs={12} sm={12}>
            <ControlledTextField
              fullWidth
              size="medium"
              control={control}
              name="username"
              label="Họ và tên"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountOutline />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              fullWidth
              size="medium"
              control={control}
              type="email"
              name="email"
              label="Email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutline />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              fullWidth
              size="medium"
              control={control}
              name="phone"
              label="Số điện thoại"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              fullWidth
              size="medium"
              control={control}
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="Mật khẩu"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isLoading}
            >
              Thêm
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CreateAccountPage;
