import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { setCredentials } from 'redux/features/auth/authSlice';
import { IGenericResponse } from 'types/GenericResponse';
import { IUser } from 'types/User';
import { baseQuery } from './fetchQuery';
import { setUser } from 'redux/features/user/userSlice';
import { Cookies } from 'react-cookie';
import { IPerrmissionGroup, IPerrmissionGroupRequest } from 'types/Role';

const url = EndPoint.permissionGroups;

export const permissionGroupsApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'permissionGroupsApi',
  tagTypes: ['PermissionGroups'],
  endpoints: builder => ({
    get: builder.query<
      Array<IPerrmissionGroup>,
      {
        skip?: number;
        limit?: number;
      } | void
    >({
      query: () => ({
        url: '',
        method: 'GET',
      }),
      providesTags: ['PermissionGroups'],
      transformResponse: (
        result: IGenericResponse<Array<IPerrmissionGroup>>,
      ) => {
        return result.data;
      },
    }),
    create: builder.mutation<any, Omit<IPerrmissionGroupRequest, '_id'>>({
      query: data => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['PermissionGroups'],
    }),
    update: builder.mutation<any, IPerrmissionGroupRequest>({
      query: data => {
        const { _id, ...payload } = data;
        return {
          url: _id,
          method: 'PUT',
          body: payload,
        };
      },
      invalidatesTags: ['PermissionGroups'],
    }),
    delete: builder.mutation<any, string>({
      query: id => ({
        url: id,
        method: 'DELETE',
      }),
      invalidatesTags: ['PermissionGroups'],
    }),
  }),
});

export const {
  useGetQuery: useGetPermissionGroupsQuery,
  useCreateMutation: useCreatePermissionGroupMutation,
  useDeleteMutation: useDeletePermissionGroupMutation,
  useUpdateMutation: useUpdatePermissionGroupMutation,
} = permissionGroupsApi;
