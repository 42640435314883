import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import DialogHandle from 'app/components/DialogHandle';
import CustomPagingAction from 'app/components/Table/CustomPagingAction';
import { notifyError, notifySuccess } from 'app/components/Toast';
import { SYSTEM_CODE } from 'constants/systemCode';
import dayjs from 'dayjs';
import { formatMoneyToFixed } from 'helpers/price.hepler';
import useTablePage from 'hooks/useTablePage';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useApproveTransactionMutation,
  useCancelTransactionMutation,
  useGetAllTransactionsQuery,
} from 'redux/services/transactions.services';
import BoxTransactionFilter from './BoxFilterAccountant';

const styleModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 2,
  width: 800,
  maxWidth: '100vw',
  maxHeight: '100vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AccountantPage = () => {
  const [listDataTransaction, setListDataTransaction] = useState<{
    isOver: boolean;
    listData: Array<any>;
    total: number;
  }>({
    isOver: true,
    listData: [],
    total: 0,
  });
  const [showFilter, setShowFilter] = useState(false);
  const { handleChangePage, handleChangeRowsPerPage, limit, page, skip } =
    useTablePage();
  const [searchParams] = useSearchParams('?status=0');

  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [approveDeposit, isError] = useApproveTransactionMutation();
  const [cancelDeposit] = useCancelTransactionMutation();
  const { data, isFetching } = useGetAllTransactionsQuery({
    ...paramsToObject(searchParams),
    skip: page * limit,
    limit: limit,
  });
  const headerCells = [
    {
      id: '_id',
      label: 'ID',
    },
    {
      id: 'fullName',
      label: 'Tên người dùng',
    },
    {
      id: 'amount',
      label: 'Tiền nạp',
    },
    {
      id: 'transferContent',
      label: 'Nội dung nạp tiền',
    },
    {
      id: 'createdAt',
      label: 'Thời gian',
    },
    {
      id: 'status',
      label: 'Trạng thái',
    },
  ];

  const statusObj = {
    '-3': { color: 'error' },
    '-2': { color: 'error' },
    '-1': { color: 'error' },
    0: { color: 'warning' },
    1: { color: 'success' },
  };
  const statusText = {
    '-3': 'Hết hạn 10 phút',
    '-2': 'Từ chối',
    '-1': 'Đã hủy',
    0: 'Chờ xử lý',
    1: 'Thành công',
  };
  const [detail, setDetail] = useState<undefined | any>(undefined);

  const [open, setOpen] = useState<{ active: boolean; idItem: string }>({
    active: false,
    idItem: '',
  });
  const [openCancel, setOpenCancel] = useState<{
    active: boolean;
    idItem: string;
  }>({
    active: false,
    idItem: '',
  });
  const handleOpen = () => {
    setOpen({ ...open, active: true });
  };
  const handleClose = () => {
    setOpen({ ...open, active: false });
    setDetail(undefined);
  };

  useEffect(() => {
    if (data) {
      setListDataTransaction({
        listData: data?.listData || [],
        isOver: data.isOver,
        total: (data as any)?.count || data?.total,
      });
    }
  }, [data]);

  const handleApprove = async (idDeposit: string) => {
    await approveDeposit({ _id: idDeposit } as any)
      .unwrap()
      .then(() => {
        notifySuccess('Duyệt thành công');
        handleClose();
      })
      .catch(e => {
        notifyError(SYSTEM_CODE[e.data?.systemCode]);
      });
  };

  const handleCancel = async (idDeposit: string) => {
    await cancelDeposit({ _id: idDeposit } as any)
      .unwrap()
      .then(() => {
        notifySuccess('Từ chối thành công');
        setOpenCancel({ ...openCancel, active: false });
        setDetail(undefined);
      })
      .catch(e => {
        notifyError(SYSTEM_CODE[e.data?.systemCode]);
      });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container spacing={7}>
        <Grid item xs={12} sm={5}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Lịch sử nạp tiền
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          <Button
            sx={{ marginLeft: 3 }}
            type="button"
            variant="contained"
            onClick={() => setShowFilter(f => !f)}
            endIcon={<FilterAltIcon />}
          >
            Lọc
          </Button>
        </Grid>
      </Grid>
      <Collapse in={showFilter}>
        <Paper sx={{ padding: 5 }}>
          <BoxTransactionFilter />
        </Paper>
      </Collapse>
      <TablePagination
        sx={{
          '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
            justifyContent: 'end',
            flexWrap: 'wrap',
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} trong ${
            count !== -1 ? count : `hơn ${to}`
          } kết quả`;
        }}
        labelRowsPerPage="Số dòng trong trang"
        count={listDataTransaction.total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={CustomPagingAction}
        slot=""
      />
      <TableContainer component={Paper}>
        <Table
          sx={{ width: '100%', minWidth: 'min-content' }}
          aria-label="table in dashboard"
        >
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              {headerCells.map(headerCells => {
                return <TableCell>{headerCells.label}</TableCell>;
              })}
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  bgcolor: 'customColors.tableActionCol',
                  borderRight: 'divider',
                  textAlign: 'center',
                }}
              >
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listDataTransaction.listData.map((data, index) => {
              return (
                <TableRow
                  hover
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell sx={{ maxWidth: 100 }}>
                    <Tooltip title="Sao chép">
                      <Button
                        fullWidth
                        onClick={() => {
                          navigator.clipboard.writeText(data._id);
                        }}
                        style={{ padding: 0 }}
                      >
                        <Typography noWrap>{data._id}</Typography>
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: '300px',
                    }}
                  >
                    <Stack direction="row" spacing={3} alignItems={'center'}>
                      <Avatar
                        alt={data?.authName}
                        src={
                          data?.author?.avatar?.location ||
                          '/images/avatars/2.png'
                        }
                      />
                      <Typography fontSize={14} className="max_line-2">
                        {data?.author?.fullname || ''}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{formatMoneyToFixed(data?.amount)}</TableCell>
                  <TableCell sx={{ maxWidth: '300px' }}>
                    {data?.transferContent || ''}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {dayjs(data?.createdAt).format('DD-MM-YYYY HH:mm ')}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={statusText[data.status]}
                      color={statusObj[data.status]?.color}
                      sx={{
                        height: 24,
                        fontSize: '0.75rem',
                        textTransform: 'capitalize',
                        '& .MuiChip-label': { fontWeight: 500 },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      position: 'sticky',
                      right: 0,
                      zIndex: 1,
                      bgcolor: 'customColors.tableActionCol',
                    }}
                  >
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-evenly'}
                    >
                      {data.status === 0 ? (
                        <>
                          <Button
                            onClick={() => {
                              setDetail(data);
                              // handleOpen();
                              setOpen({ idItem: data?._id, active: true });
                            }}
                            variant="contained"
                            size="small"
                            color="info"
                            style={{ color: 'white', fontWeight: 600 }}
                          >
                            Duyệt
                          </Button>
                          <Button
                            onClick={() => {
                              setDetail(data);
                              // handleOpen();
                              setOpenCancel({
                                idItem: data?._id,
                                active: true,
                              });
                            }}
                            variant="contained"
                            size="small"
                            color="error"
                            style={{ color: 'white', fontWeight: 600 }}
                          >
                            Từ chối
                          </Button>
                        </>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            color: 'white',
                            fontWeight: 600,
                            backgroundColor: '#4a4848',
                          }}
                        >
                          Đã xử lý
                        </Button>
                      )}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={{
          '&.MuiTablePagination-root .MuiTablePagination-toolbar': {
            justifyContent: 'end',
            flexWrap: 'wrap',
          },
        }}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} trong ${
            count !== -1 ? count : `hơn ${to}`
          } kết quả`;
        }}
        labelRowsPerPage="Số dòng trong trang"
        count={listDataTransaction.total}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={CustomPagingAction}
        slot=""
      />

      {open.active && (
        <DialogHandle
          title="Lưu ý"
          body="Bạn có muốn duyệt yêu cầu này không!"
          open={open.active}
          onClose={() => setOpen({ ...open, active: false })}
          onHandle={() => handleApprove(open?.idItem)}
        />
      )}
      {openCancel.active && (
        <DialogHandle
          title="Lưu ý"
          body="Bạn có muốn từ chối yêu cầu này không!"
          open={openCancel.active}
          onClose={() => setOpenCancel({ ...openCancel, active: false })}
          onHandle={() => handleCancel(openCancel?.idItem)}
        />
      )}
    </Box>
  );
};

export default AccountantPage;
