import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  OutlinedInput,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import AutoGenerateTable from 'app/components/Table/AutoGenerateTable';
import { HeadCell, TableAction } from 'app/components/Table/types';
import useTablePage from 'hooks/useTablePage';
import { Link, useSearchParams } from 'react-router-dom';
import { useGetExpertListQuery } from 'redux/services/experts.services';
import { EAskanyStatusExpert, EStatusExpert, IExpert } from 'types/Expert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useState } from 'react';
import { CircularProgressWithLabel } from 'app/components/Progress';

const ExpertPage = () => {
  const { limit, page } = useTablePage();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isExpert, setIsExpert] = useState(false);
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const { data, isFetching } = useGetExpertListQuery({
    skip: page * limit,
    limit: limit,
    search: searchParams.get('search') || undefined,
    isExpert: searchParams.get('isExpert') === 'true' ? true : false,
  });
  const headCells: Array<HeadCell<IExpert>> = [
    {
      id: 'fullname',
      label: 'Tên',
      dataType: 'multi',
      renderer: expert => {
        return (
          <Stack direction={'row'} alignItems="center" gap={4}>
            <Avatar src={expert.avatar?.location} />
            <Typography>{expert.fullname}</Typography>
          </Stack>
        );
      },
    },
    {
      id: 'modifiedAt',
      label: 'Ngày cập nhật',
      dataType: 'date',
    },
    {
      id: 'createdAt',
      label: 'Ngày tạo',
      dataType: 'date',
    },
    {
      id: 'status',
      label: 'Trạng thái',
      dataType: 'custom',
      renderer: info => {
        return (
          <span>
            {info === EStatusExpert.ACTIVE ? (
              <Chip label="Hoạt động" color="success" />
            ) : info === EStatusExpert.BLOCK ? (
              <Chip label="Đã khóa" color="warning" />
            ) : info === EStatusExpert.REMOVE ? (
              <Chip label="Đã xóa" color="error" />
            ) : (
              <Chip label="Chưa xác thực" color="default" />
            )}
          </span>
        );
      },
    },
    {
      id: 'askanyStatus',
      label: 'Askany status',
      dataType: 'multi',
      renderer: detail => {
        let percentageComplete: number = 0;
        if (!!detail?.introduce?.trim())
          percentageComplete = percentageComplete + 25;
        if ((detail?.skills?.length || 0) > 0)
          percentageComplete = percentageComplete + 25;
        if ((detail?.languages?.length || 0) > 0)
          percentageComplete = percentageComplete + 25;
        if ((detail?.timeLine?.length || 0) > 0)
          percentageComplete = percentageComplete + 25;
        if ((detail?.workingExperience?.length || 0) > 0)
          percentageComplete = percentageComplete + 25;
        if (detail.isVerifyBasic) percentageComplete = percentageComplete + 25;
        return (
          <Stack alignItems="center" flexDirection="row" gap="15px">
            {detail?.askanyStatus === EAskanyStatusExpert.ACCEPT ? (
              <Chip label="Đã duyệt" color="success" />
            ) : detail?.askanyStatus === EAskanyStatusExpert.PENDING ? (
              <Chip label="Đang chờ duyệt" color="warning" />
            ) : detail?.askanyStatus === EAskanyStatusExpert.REJECT ? (
              <Chip label="Đã từ chối" color="error" />
            ) : (
              <Chip label="Chưa tạo hồ sơ" color="default" />
            )}
            <CircularProgressWithLabel value={percentageComplete} />
          </Stack>
        );
      },
    },
  ];
  const actions: Array<TableAction<IExpert>> = [
    {
      name: '',
      component: expert => (
        <Link to={`/experts/${expert._id}`}>
          <IconButton>
            <OpenInNewIcon />
          </IconButton>
        </Link>
      ),
    },
  ];
  return (
    <div>
      <Stack gap={2} direction="row">
        <OutlinedInput
          size="small"
          value={search}
          onChange={e => {
            setSearch(e.currentTarget.value);
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isExpert}
              onChange={e => {
                if (e.currentTarget.checked) {
                  setIsExpert(true);
                } else {
                  setIsExpert(false);
                }
              }}
            />
          }
          label="Chuyên gia"
        />
        <Button
          variant="contained"
          onClick={() => {
            setSearchParams(p => {
              if (search) {
                p.set('search', search);
              } else {
                p.delete('search');
              }
              if (isExpert) {
                p.set('isExpert', 'true');
              } else {
                p.delete('isExpert');
              }
              p.set('page', '0');
              return p;
            });
          }}
        >
          Tìm kiếm
        </Button>
      </Stack>
      <AutoGenerateTable
        listData={{
          isOver: data?.isOver || true,
          listData: data?.listData || [],
          total: data?.total || 0,
        }}
        modalLabel=""
        iconHandle={false}
        headCells={headCells}
        isLoading={isFetching}
        actions={actions}
      />
    </div>
  );
};

export default ExpertPage;
