import { createApi } from '@reduxjs/toolkit/query/react';
import { EndPoint } from 'redux/endpoint';
import { IExpert } from 'types/Expert';
import { IGenericResponse, IListResponse } from 'types/GenericResponse';
import { baseQuery } from './fetchQuery';

export interface CategoriesRequest {
  skip?: number;
  limit?: number;
  sort?: 1 | -1;
  search?: string;
  isExpert?: boolean;
}

const url = EndPoint.categories;

export const categoriesApi = createApi({
  baseQuery: baseQuery(url),
  reducerPath: 'categoriesApi',
  tagTypes: ['Categories'],
  endpoints: builder => ({
    getCategories: builder.mutation<any[], any>({
      query: query => ({
        url: '/parent',
        method: 'GET',
        params: query,
      }),
      transformResponse: (result: IGenericResponse<any[]>) => {
        return result.data;
      },
    }),
    getCategoriesById: builder.mutation<IListResponse<any>, { search: string }>(
      {
        query: query => ({
          url: '/parent',
          method: 'GET',
          params: query,
        }),
        transformResponse: (result: IGenericResponse<IListResponse<any>>) => {
          return result.data;
        },
      },
    ),
  }),
});

export const { useGetCategoriesMutation } = categoriesApi;
