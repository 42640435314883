export const SYSTEM_CODE = {
  ADMIN_0000: 'Thành công',
  ADMIN_0002: 'Unauthorized',
  ADMIN_0003: 'Forbidden',
  ADMIN_0004: 'Không tìm thấy tài khoàn',
  ADMIN_0005: 'Invalid email or password',
  ADMIN_0006: 'Email này đã tồn tại',
  ADMIN_0008: 'Tên nhóm quyền đã tồn tại',
  ADMIN_0012: 'Mã voucher đã tồn tại',
  ADMIN_0015: 'Mã OTP đã hết hạn',
  ADMIN_0020: 'Vui lòng xác thực mã OTP',
  ADMIN_5014: 'gRPC Service unavailable',
  ADMIN_5004: 'gRPC Request time out',
  ADMIN_0043: 'Flash sale đã tồn tại, vui lòng thử lại sau ',
};

export const SYSTEM_CODE_AC = {
  ACC_0000: 'Thành công',
  ACC_0012: 'Mã voucher đã tồn tại',
};
