import { useRoutes } from 'react-router-dom';
import AccountRoute from './AccountRoutes';
import RequireLogin from 'app/components/RouteGuard/RouteGuard';
import BlankLayout from '@core/layouts/BlankLayout';
import UserLayout from 'layouts/UserLayout';
import VoucherRoutes from './VoucherRoutes';
import PermissionRoutes from './PermissionRoutes';
import LoginPage from 'app/pages/Login';
import Error401 from 'app/pages/401';
import Error500 from 'app/pages/500';
import Error404 from 'app/pages/404';
import { Dashboard } from 'app/pages/Dashboard/Loadable';
import NotificationRoutes from './NotificationRouter';
import AccountantRoutes from './AccountantRoutes';
import ExpertRoutes from './ExpertRoutes';
import ExperiencesRoutes from './ExperiencesRoutes';
import LayoutRoutes from './LayoutRouter';

const AppRoutes = () => {
  const routes = useRoutes([
    {
      element: <RequireLogin />,
      children: [
        {
          element: <UserLayout />,
          children: [
            {
              path: '/',
              element: <Dashboard />,
            },
            {
              path: '/dashboard',
              element: <Dashboard />,
            },
            AccountantRoutes,
            AccountRoute,
            VoucherRoutes,
            PermissionRoutes,
            NotificationRoutes,
            LayoutRoutes,
            ExpertRoutes,
            ExperiencesRoutes,
          ],
        },
      ],
    },
    {
      element: <BlankLayout />,
      children: [
        {
          path: 'login',
          element: <LoginPage />,
        },
        {
          path: '401',
          element: <Error401 />,
        },
        {
          path: '500',
          element: <Error500 />,
        },
        {
          path: '*',
          element: <Error404 />,
        },
      ],
    },
  ]);
  return routes;
};

export default AppRoutes;
