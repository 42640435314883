import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'redux/services/accounts.services';
import { RootState } from 'redux/store/store';

type AuthState = {
  permissions: Array<number>;
  token: string | null;
  _id: string | null;
};

const slice = createSlice({
  name: 'auth',
  initialState: { permissions: [], token: null, _id: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { permissions, token, _id },
      }: PayloadAction<{
        permissions: Array<number>;
        token: string;
        _id: string;
      }>,
    ) => {
      state.permissions = permissions;
      state.token = token;
      state._id = _id;
    },
  },
});

export const { setCredentials } = slice.actions;

export default slice.reducer;

// export const selectCurrentUser = (state: RootState) => state.authState.user;
