// ** React Imports
import React, { useState } from 'react';

// ** MUI Imports
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { Avatar, Button, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { StatusOffers } from 'app/components/StatusOffer';
import AutoGenerateTable from 'app/components/Table/AutoGenerateTable';
import { formatMoneyToFixed } from 'helpers/price.hepler';
import useTablePage from 'hooks/useTablePage';
import { useSearchParams } from 'react-router-dom';
import { useGetHistoryDetailsQuery } from 'redux/services/voucher.services';
import { IListResponse } from 'types/GenericResponse';
import { IVoucher } from 'types/Voucher';
import { getTitleOffer } from 'utils/Offer';
import { SortOrder } from 'utils/sort';

interface Column {
  id: any;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'code', label: 'Mã voucher', minWidth: 80 },
  { id: 'voucherName', label: 'Tên vouhcer', minWidth: 100 },
  {
    id: 'budget',
    label: 'Ngân sách',
    minWidth: 100,
    format: (value: number) => formatMoneyToFixed(value),
  },
  {
    id: 'quantity',
    label: 'Số lượng',
    minWidth: 100,
    format: (value: number) => value.toFixed(0),
  },
  {
    id: 'voucherType',
    label: 'Loại voucher',
    minWidth: 100,
  },
  {
    id: 'loading',
    label: 'Tiến trình',
    minWidth: 100,
    align: 'right',
  },
];

interface Data {
  name: string;
  code: string;
  size: number;
  density: number;
  population: number;
}

interface ITablePreview {
  listData?: [];
  handleClose?: () => void;
  voucherData?: IVoucher;
}

const InfoDetailVoucher = (props: ITablePreview) => {
  const {
    listData = [],
    handleClose = () => {
      return;
    },
    voucherData = undefined,
  } = props;
  const [searchParams] = useSearchParams();
  const { handleChangePage, handleChangeRowsPerPage, limit, page, skip } =
    useTablePage();
  const [order, setOrder] = React.useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IVoucher | undefined>();
  const [listDataVoucher, setListData] = useState<IListResponse<IVoucher>>({
    isOver: true,
    listData: [],
    total: 0,
  });

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  const getId = !!voucherData ? voucherData?._id : '';
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { data, isFetching } = useGetHistoryDetailsQuery({
    ...paramsToObject(searchParams),
    id: getId,
    skip: page * limit,
    limit: limit,
  });

  React.useEffect(() => {
    if (data) {
      setListData(data);
    }
  }, [data]);

  const headCells: Array<any> = [
    {
      id: 'author',
      label: 'tên người dùng',
      dataType: 'custom',
      renderer: info => {
        return (
          <Stack flexDirection={'row'} gap={3} alignItems={'center'}>
            <Avatar
              alt={info?.fullname}
              src={info?.avatar?.location}
              sx={{ width: 28, height: 28 }}
            />
            <span>{info?.fullname}</span>
          </Stack>
        );
      },
    },
    {
      id: 'offer',
      label: 'Tên gói dịch vụ',
      width: '20',
      dataType: 'custom',
      renderer: infoOffer => {
        return (
          <span>
            {getTitleOffer(
              infoOffer.offeredRequest.type,
              infoOffer?.author?.fullname,
              infoOffer?.offeredRequest,
            )}
          </span>
        );
      },
    },
    {
      id: 'createdAt',
      label: 'Thời gian sử dụng',
      width: '20',
      dataType: 'date',
      // renderer: createdAt => {
      //   return (
      //     <span>
      //       {/* {dayjs()} */}
      //     </span>
      //   );
      // },
    },

    {
      id: 'amount',
      label: 'Số tiền được giảm giá',
      width: '20',
      dataType: 'custom',
      renderer: info => {
        return <span>{formatMoneyToFixed(info)}</span>;
      },
    },
    {
      id: 'offer',
      label: 'Trạng thái',
      dataType: 'custom',
      renderer: info => {
        return <StatusOffers status={info?.status || 3} />;
      },
    },
  ];

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {/* <Typography>Tổng số lượng voucher:</Typography>
      <Typography>Đã dùng: {(voucherData as any)?.remain} </Typography> */}
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" startIcon={<BookmarkIcon />}>
          Tổng số voucher: {voucherData?.quantity}
        </Button>
        <Button variant="outlined" startIcon={<BookmarkIcon />}>
          Đã sử dụng:{' '}
          {(voucherData?.quantity || 0) - (voucherData as any)?.remain}
        </Button>
      </Stack>
      <AutoGenerateTable
        headCells={headCells}
        listData={{
          isOver: listDataVoucher.isOver,
          listData: listDataVoucher.listData,
          total: listDataVoucher.total,
        }}
        isLoading={isFetching}
        modalLabel="Chi tiết thông báo"
        iconHandle={false}
        // detailDialogContent={DetailDialogContent}
      />
    </Paper>
  );
};

export default InfoDetailVoucher;
