import VouchersPage from 'app/pages/Vouchers';
import VoucherDetail from 'app/pages/Vouchers/VoucherDetail';
import VoucherExpertPage from 'app/pages/Vouchers/VoucherExperts/VoucherExpert';
import VoucherHistoryPage from 'app/pages/Vouchers/VoucherHistory/VoucherHistory';
import VoucherHistoryDetail from 'app/pages/Vouchers/Component/VoucherHistoryDetail';
import VoucherUserPage from 'app/pages/Vouchers/VoucherUsers/VoucherUser';
import AddVoucherPage from 'app/pages/Vouchers/Component/create';
import React from 'react';
import { Route, RouteObject } from 'react-router-dom';

const VoucherRoutes: RouteObject = {
  path: '/vouchers',
  children: [
    {
      index: true,
      element: <VouchersPage />,
    },
    {
      path: 'history',
      element: <VoucherHistoryPage />,
    },
    {
      path: 'expert',
      element: <VoucherExpertPage />,
    },
    {
      path: 'user',
      element: <VoucherUserPage />,
    },
    {
      path: 'create',
      element: <AddVoucherPage />,
    },
  ],
};

export default VoucherRoutes;
