export const EndPoint = {
  account: 'accounts',
  vouchers: 'vouchers',
  permissionGroups: 'permission-groups',
  permissions: 'permissions',
  features: 'features',
  roles: 'roles',
  experts: 'experts',
  notification: 'admin-notifications',
  categories: 'categories',
  transactions: 'transactions',
  sale: 'flash-sales',
};
