import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PropsWithChildren } from 'react';

const RoutePermission: React.FC<
  PropsWithChildren & { permissions: number[] }
> = ({ permissions, children }) => {
  const [cookies] = useCookies(['permissions']);
  const location = useLocation();
  const accountPermissions = cookies.permissions || [];
  const authorized =
    permissions.length <= 0 ||
    permissions.some(p => accountPermissions.includes(p));

  return authorized ? (
    <>{children}</>
  ) : (
    <Navigate to="/404" state={{ from: location }} replace />
  );
};

export default RoutePermission;
