import CloseIcon from '@mui/icons-material/Close';
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Typography,
  styled,
  Stack,
} from '@mui/material';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import Magnify from 'mdi-material-ui/Magnify';
import { useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  useCreatePermissionGroupMutation,
  useDeletePermissionGroupMutation,
  useGetPermissionGroupsQuery,
  useUpdatePermissionGroupMutation,
} from 'redux/services/permissionGroups.services';
import { useGetPermissionsQuery } from 'redux/services/permissions.service';
import { IPerrmissionGroup } from 'types/Role';
import { TypeOf, ZodType, z } from 'zod';
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetFeaturesQuery } from 'redux/services/features.services';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SYSTEM_CODE } from 'constants/systemCode';
import { notifyError, notifySuccess } from 'app/components/Toast';

const schema: ZodType<{ groupName: string; permissions: string[] }> = z.object({
  groupName: z.string().trim().min(1, 'Vui lòng nhập tên'),
  permissions: z.array(z.string()),
});
type FormInput = TypeOf<typeof schema>;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const PermissionGroupDetails = () => {
  const [permissionGroupData, setPermissionGroupData] =
    useState<IPerrmissionGroup>();
  const navigate = useNavigate();
  const {
    handleSubmit,
    reset,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(() => {
      return {
        groupName: permissionGroupData?.groupName,
        permissions: permissionGroupData?.permissions.map(p => p._id) || [],
      };
    }, [permissionGroupData]),
  });
  const [filter, setFilter] = useState('');
  const { id } = useParams();
  const { data: listPermissionGroup } = useGetPermissionGroupsQuery();
  const [openDialog, setOpenDialog] = useState(false);
  const { data: listPermission } = useGetPermissionsQuery();
  const { data: listFeature } = useGetFeaturesQuery();
  const [updateGroup, { isLoading: isUpdating }] =
    useUpdatePermissionGroupMutation();
  const [createGroup, { isLoading: isCreating }] =
    useCreatePermissionGroupMutation();
  const [deleteGroup, { isLoading: isDeleting }] =
    useDeletePermissionGroupMutation();
  const handleBackToList = () => {
    navigate('/permission-groups', { replace: true });
  };
  const submitForm: SubmitHandler<FormInput> = async data => {
    try {
      if (permissionGroupData) {
        await updateGroup({ _id: permissionGroupData._id, ...data }).unwrap();
        notifySuccess('Cập nhật công');
        handleBackToList();
      } else {
        await createGroup(data).unwrap();
        notifySuccess('Thêm mới thành công');
        handleBackToList();
      }
    } catch (e: any) {
      if (e.data.systemCode) {
        notifyError(SYSTEM_CODE[e.data.systemCode]);
      } else {
        notifyError('Đã xảy ra lỗi');
      }
    }
  };
  const handleDeleteGroup = () => {
    if (permissionGroupData) {
      deleteGroup(permissionGroupData._id)
        .unwrap()
        .then(() => {
          handleBackToList();
          notifySuccess('Xóa thành công');
        })
        .catch(() => {
          notifyError('Đã xảy ra lỗi');
        });
    }
  };
  useEffect(() => {
    if (id && listPermissionGroup) {
      const temp = listPermissionGroup?.find(p => p._id === id);
      setPermissionGroupData(temp);
      if (temp) {
        setValue('groupName', temp?.groupName);
        setValue('permissions', temp?.permissions.map(p => p._id) || []);
      }
    }
  }, [id, listPermissionGroup, setValue]);
  return (
    <Stack spacing={4} component={'form'} onSubmit={handleSubmit(submitForm)}>
      <Typography sx={{ typography: 'h6' }}>
        {permissionGroupData ? 'Chỉnh sửa' : 'Tạo mới'}
      </Typography>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              fullWidth
              size="small"
              control={control}
              label="Tên nhóm"
              name="groupName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              onChange={e => {
                setFilter(e.target.value || '');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Magnify fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <FormControl>
          <Controller
            name="permissions"
            control={control}
            render={({ field }) => {
              return (
                <FormGroup>
                  {!filter && (
                    <>
                      {listFeature?.map(feat => {
                        return (
                          <Accordion
                            defaultExpanded
                            disableGutters
                            key={feat._id}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography>{feat.featureName}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Grid container>
                                {feat.permissions?.map(p => {
                                  return (
                                    <Grid
                                      key={p._id}
                                      item
                                      xs={12}
                                      sm={6}
                                      md={4}
                                      lg={3}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            onChange={e => {
                                              if (e.target.checked) {
                                                setValue('permissions', [
                                                  ...getValues('permissions'),
                                                  p._id,
                                                ]);
                                              } else {
                                                const filtered = getValues(
                                                  'permissions',
                                                ).filter(pp => pp !== p._id);
                                                setValue(
                                                  'permissions',
                                                  filtered,
                                                );
                                              }
                                            }}
                                            checked={
                                              field.value?.includes(p._id) ||
                                              false
                                            }
                                          />
                                        }
                                        label={p.name}
                                      />
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        );
                      })}
                      <Accordion defaultExpanded disableGutters>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography>Khác</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container>
                            {listPermission
                              ?.filter(p => !p.feature)
                              .map(p => {
                                return (
                                  <Grid
                                    key={p._id}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          onChange={e => {
                                            if (e.target.checked) {
                                              setValue('permissions', [
                                                ...getValues('permissions'),
                                                p._id,
                                              ]);
                                            } else {
                                              const filtered = getValues(
                                                'permissions',
                                              ).filter(pp => pp !== p._id);
                                              setValue('permissions', filtered);
                                            }
                                          }}
                                          checked={
                                            field.value?.includes(p._id) ||
                                            false
                                          }
                                        />
                                      }
                                      label={p.name}
                                    />
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  )}
                  {filter && (
                    <List>
                      {listPermission
                        ?.filter(p =>
                          p.name.toLowerCase().includes(filter.toLowerCase()),
                        )
                        ?.map(p => {
                          return (
                            <ListItem key={p._id}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={e => {
                                      if (e.target.checked) {
                                        setValue('permissions', [
                                          ...getValues('permissions'),
                                          p._id,
                                        ]);
                                      } else {
                                        const filtered = getValues(
                                          'permissions',
                                        ).filter(pp => pp !== p._id);
                                        setValue('permissions', filtered);
                                      }
                                    }}
                                    checked={
                                      field.value?.includes(p._id) || false
                                    }
                                  />
                                }
                                label={p.name}
                              />
                            </ListItem>
                          );
                        })}
                    </List>
                  )}
                </FormGroup>
              );
            }}
          />
        </FormControl>
      </Box>
      <Box display={'flex'} justifyContent={'end'}>
        <LoadingButton
          type="submit"
          loading={isUpdating || isCreating}
          variant="contained"
          color="primary"
        >
          Lưu
        </LoadingButton>
        {permissionGroupData && (
          <LoadingButton
            loading={isUpdating || isCreating || isDeleting}
            variant="contained"
            color="error"
            onClick={() => setOpenDialog(true)}
            sx={{ marginLeft: 2 }}
          >
            Xóa
          </LoadingButton>
        )}
      </Box>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Xác nhận</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Bạn chắc chắn muốn xóa nhóm quyền này?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteGroup} variant="contained" color="error">
            Xóa
          </Button>
          <Button
            onClick={() => setOpenDialog(false)}
            variant="contained"
            color="primary"
          >
            Hủy
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default PermissionGroupDetails;
