import { IVoucher, VoucherHeadCell } from 'types/Voucher';

export const fakeData: IVoucher[] = [
  // {
  //   _id: '1',
  //   voucherName: 'Voucher 1',
  //   code: 'ABCXYZ',
  //   voucherType: 1,
  //   maxDiscountAmount: 100000,
  //   discountPercentage: 10,
  //   status: 1,
  //   budget: 1000,
  //   discountAmount: 10,
  //   discountType: 1,
  //   expert: '0918203091823',
  //   minBookingPrice: 100000,
  //   quantity: 100,
  //   endDate: '2023/12/12',
  //   startDate: '2023/10/10',
  // },
];

export const headCells: readonly VoucherHeadCell[] = [
  {
    id: '_id',
    label: 'ID',
  },
  {
    id: 'voucherName',
    label: 'Tên voucher',
  },
  {
    id: 'code',
    label: 'Mã voucher',
  },
  {
    id: 'voucherType',
    label: 'Loại voucher',
  },
  {
    id: 'status',
    label: 'Trạng thái',
  },
  {
    id: 'discountPercentage',
    label: 'Phần trăm',
  },
  {
    id: 'discountAmount',
    label: 'Số tiền giảm',
  },
  {
    id: 'maxDiscountAmount',
    label: 'Giảm tối đa',
  },
  {
    id: 'minBookingPrice',
    label: 'Giá tối thiểu',
  },
  {
    id: 'budget',
    label: 'Ngân sách',
  },
  {
    id: 'endDate',
    label: 'Hạn sử dụng',
  },
  {
    id: 'startDate',
    label: 'Ngày bắt đầu',
  },
];

export const headCellsExpert: readonly VoucherHeadCell[] = [
  {
    id: '_id',
    label: 'ID',
  },
  {
    id: 'voucherName',
    label: 'Tên voucher',
  },
  {
    id: 'code',
    label: 'Mã voucher',
  },
  {
    id: 'voucherType',
    label: 'Loại voucher',
  },
  {
    id: 'status',
    label: 'Trạng thái',
  },
  {
    id: 'discountAmount',
    label: 'Số tiền giảm',
  },
  {
    id: 'minBookingPrice',
    label: 'Giá tối thiểu',
  },
  {
    id: 'budget',
    label: 'Ngân sách',
  },
  {
    id: 'endDate',
    label: 'Hạn sử dụng',
  },
  {
    id: 'startDate',
    label: 'Ngày bắt đầu',
  },
];

export const headCellsHistory: readonly VoucherHeadCell[] = [
  {
    id: '_id',
    label: 'ID',
  },
  {
    id: 'voucherName',
    label: 'Tên voucher',
  },
  {
    id: 'code',
    label: 'Mã voucher',
  },
  {
    id: 'quantity',
    label: 'Tổng số lượng',
  },
  // {
  //   id: 'totalUsed',
  //   label: 'Số lượt sử dụng',
  // },
  // {
  //   id: 'totalDiscount',
  //   label: 'Tổng số tiền giảm',
  // },
  {
    id: 'voucherType',
    label: 'Loại voucher',
  },
  {
    id: 'status',
    label: 'Trạng thái',
  },
  {
    id: 'discountAmount',
    label: 'Số tiền giảm',
  },
  {
    id: 'minBookingPrice',
    label: 'Giá tối thiểu',
  },
  {
    id: 'budget',
    label: 'Ngân sách',
  },
  {
    id: 'endDate',
    label: 'Hạn sử dụng',
  },
  {
    id: 'startDate',
    label: 'Ngày bắt đầu',
  },
];
export const headCellsHistoryVoucherOffer: any = [
  {
    id: '_id',
    label: 'ID',
  },
  {
    id: 'fullname',
    label: 'Tên người dùng',
  },
  {
    id: 'requestTitle',
    label: 'Tên voucher',
  },
  {
    id: 'quantity',
    label: 'Tổng số lượng',
  },
  // {
  //   id: 'totalUsed',
  //   label: 'Số lượt sử dụng',
  // },
  // {
  //   id: 'totalDiscount',
  //   label: 'Tổng số tiền giảm',
  // },
  {
    id: 'voucherType',
    label: 'Loại voucher',
  },
  {
    id: 'status',
    label: 'Trạng thái',
  },
  {
    id: 'discountAmount',
    label: 'Số tiền giảm',
  },
  {
    id: 'minBookingPrice',
    label: 'Giá tối thiểu',
  },
  {
    id: 'budget',
    label: 'Ngân sách',
  },
  {
    id: 'endDate',
    label: 'Hạn sử dụng',
  },
  {
    id: 'startDate',
    label: 'Ngày bắt đầu',
  },
];

export const VoucherStatusText: { [key: string]: string } = {
  '-1': 'Xóa',
  '-3': 'Hết lượt dùng',
  '-2': 'Hết hạn',
  1: 'Hoạt động',
  2: 'Ẩn',
  0: 'Tạm khóa',
};

export const VoucherTypeText: { [key: string]: string } = {
  1: 'Chuyên gia',
  0: 'Khách hàng',
};

export const VoucherScopeText: { [key: string]: string } = {
  1: 'Toàn bộ user',
  0: 'User được chỉ định',
};

export const DiscountTypeText: { [key: string]: string } = {
  0: 'Giảm tiền theo phần trăm',
  1: 'Giảm tiền trực tiếp',
  3: 'Miễn phí thời gian gọi',
};

const VOUCHER_MINIMUM_BUDGET = 10000000;
