import DatePickerWrapper from '@core/styles/libs/react-datepicker';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from '@mui/material';
import ControlledNumberTextField from 'app/components/Form/ControlledNumberTextField';
import ControlledTextField from 'app/components/Form/ControlledTextField';
import CustomInputOutDate from 'app/components/Form/CustomInputDate';
import { formatMoneyToFixed } from 'helpers/price.hepler';
import React from 'react';
import DatePicker from 'react-datepicker';
import { Control, Controller } from 'react-hook-form';
import { ESelectBooking } from 'types/enum';
import { IExpert } from 'types/Expert';

interface IFormAddSale {
  currentExpert?: IExpert;
  control: Control<any>;
  formSale: ESelectBooking;
  formContact: string;
}

const FormAddSale = (props: IFormAddSale) => {
  const { currentExpert, control, formSale, formContact = '' } = props;

  const [valueCardSkill, setValueCardSkill] = React.useState<any>(undefined);

  const HiddenRadio = styled(Radio)(({ theme }) => ({
    display: 'none',
  }));

  const getValueCall = () => {
    if (formSale === ESelectBooking.BOOK_SKILL) {
      if (formContact === 'call') return valueCardSkill?.callPrice?.cost || 0;
      if (formContact === 'meet') return valueCardSkill?.meetPrice?.cost || 0;
      return 0;
    } else {
      if (formContact === 'call') return currentExpert?.callPriceCost || 0;
      if (formContact === 'meet') return currentExpert?.meetPriceCost || 0;
      return 0;
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={5}>
          {formSale === ESelectBooking.BOOK_SKILL && currentExpert && (
            <Grid item sm={12}>
              <Controller
                name={'skill'}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <FormControl className="w-full">
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        row
                        className="flex flex-wrap items-start justify-between w-full gap-5"
                      >
                        {(currentExpert?.skills?.length || 0) > 0 &&
                          currentExpert?.skills.map((item, index) => {
                            const isSelected = value === item._id;
                            return (
                              <Card
                                onClick={() => {
                                  setValueCardSkill(item);
                                  onChange(item._id);
                                }}
                                // onChange={onChange}
                                key={item._id}
                                style={{
                                  flexBasis: '300px',
                                }}
                                className={`${
                                  isSelected
                                    ? 'border-blue-500'
                                    : 'border-slate-500'
                                } flex-grow p-0 duration-300 border hover:bg-slate-700 w-[350px]`}
                              >
                                <FormControlLabel
                                  value={item._id}
                                  control={<HiddenRadio />}
                                  className="w-full"
                                  label={
                                    <div className="w-full p-5 space-y-5">
                                      <p className="w-full text-base font-bold line-clamp-2">
                                        {item.name}
                                      </p>
                                      {item.isSpecial ? (
                                        <div>
                                          <p>Show</p>
                                          <p>{item?.priceCost || 0}</p>
                                        </div>
                                      ) : (
                                        <div className="flex flex-wrap items-center justify-start gap-5">
                                          <div className="flex items-center justify-start gap-3">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="15"
                                              height="15"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="currentColor"
                                              strokeWidth="2"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
                                            </svg>
                                            <div>
                                              <p className="text-sm">
                                                Gọi điện
                                              </p>
                                              <p className="font-semibold ">
                                                {formatMoneyToFixed(
                                                  item?.callPrice?.cost || 0,
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          {!!item?.meetPrice?.cost && (
                                            <div className="flex items-center justify-start gap-3">
                                              <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 36 36"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M24.75 3H28.6875C29.0356 3 29.3694 3.14487 29.6156 3.40273C29.8617 3.66059 30 4.01033 30 4.375V14C30 14.3647 29.8617 14.7144 29.6156 14.9723C29.3694 15.2301 29.0356 15.375 28.6875 15.375H23.4375L19.5 19.5V15.375H10.3125C9.9644 15.375 9.63056 15.2301 9.38442 14.9723C9.13828 14.7144 9 14.3647 9 14V4.375C9 4.01033 9.13828 3.66059 9.38442 3.40273C9.63056 3.14487 9.9644 3 10.3125 3H24.75Z"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M8.97241 26.861C11.0435 26.861 12.7224 25.182 12.7224 23.111C12.7224 21.0399 11.0435 19.361 8.97241 19.361C6.90134 19.361 5.22241 21.0399 5.22241 23.111C5.22241 25.182 6.90134 26.861 8.97241 26.861Z"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M15 33C14.6923 31.7224 13.9307 30.581 12.8403 29.7632C11.75 28.9455 10.3957 28.5 9 28.5C7.60425 28.5 6.24999 28.9455 5.15966 29.7632C4.06933 30.581 3.30775 31.7224 3 33"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M26.8912 26.8608C28.9623 26.8608 30.6412 25.1819 30.6412 23.1108C30.6412 21.0398 28.9623 19.3608 26.8912 19.3608C24.8202 19.3608 23.1412 21.0398 23.1412 23.1108C23.1412 25.1819 24.8202 26.8608 26.8912 26.8608Z"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                                <path
                                                  d="M33 33C32.6923 31.7224 31.9307 30.581 30.8403 29.7632C29.75 28.9455 28.3958 28.5 27 28.5C25.6043 28.5 24.25 28.9455 23.1597 29.7632C22.0693 30.581 21.3077 31.7224 21 33"
                                                  stroke="currentColor"
                                                  strokeWidth="2"
                                                  strokeLinecap="round"
                                                  strokeLinejoin="round"
                                                />
                                              </svg>
                                              <div>
                                                <p>Gặp mặt</p>
                                                <p>
                                                  {formatMoneyToFixed(
                                                    item?.meetPrice?.cost || 0,
                                                  )}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  }
                                  checked={isSelected}
                                  onChange={() => setValueCardSkill(item)}
                                />
                              </Card>
                            );
                          })}
                      </RadioGroup>
                    </FormControl>
                  );
                }}
              />
            </Grid>
          )}
          {/* <Grid item sm={12} md={12}>
            <Typography
              fontWeight={600}
              variant="button"
              gutterBottom
              width="100%"
            >
              Thông tin
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Tiêu đề
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <ControlledTextField
              name="name"
              size="small"
              fullWidth
              label="Tiêu đề giảm giá"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              Nội dung ngày
            </Typography>
          </Grid>
          <Grid item xs={12} sm={10}>
            <ControlledTextField
              name="dateTiltle"
              size="small"
              fullWidth
              label="Nội dung ngày"
              control={control}
            />
          </Grid> */}
          <Grid item sm={12} md={12}>
            <Typography
              fontWeight={600}
              variant="button"
              gutterBottom
              width="100%"
            >
              Giá tiền
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <ControlledNumberTextField
              decimalScale={0}
              size="small"
              control={control}
              fullWidth
              label="Giá cũ"
              name="oldPirce"
              placeholder="100,000"
              suffix=" VND"
              value={getValueCall()}
              disabled
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <ControlledNumberTextField
              decimalScale={0}
              size="small"
              control={control}
              fullWidth
              label="Giá mới"
              name="discountAmount"
              placeholder="100,000"
              suffix=" VND"
            />
          </Grid>
          <Grid item sm={12} md={12}>
            <Typography
              fontWeight={600}
              variant="button"
              gutterBottom
              width="100%"
            >
              Thời gian
            </Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <Controller
              name="saleStartTime"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePickerWrapper>
                  <DatePicker
                    selected={value ? new Date(value) : undefined}
                    showYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    timeFormat="HH:mm"
                    placeholderText="HH:mm DD/MM/YYYY"
                    dateFormat="HH:mm dd/MM/yyyy"
                    customInput={
                      <CustomInputOutDate
                        size="small"
                        label="Thời gian bắt đầu"
                        error={!!error}
                        helperText={error?.message}
                      />
                    }
                    onChange={onChange}
                  />
                </DatePickerWrapper>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Controller
              name="saleEndTime"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePickerWrapper>
                  <DatePicker
                    selected={value ? new Date(value) : undefined}
                    showYearDropdown
                    showMonthDropdown
                    showTimeSelect
                    timeFormat="HH:mm"
                    placeholderText="HH:mm DD/MM/YYYY"
                    dateFormat="HH:mm dd/MM/yyyy"
                    customInput={
                      <CustomInputOutDate
                        size="small"
                        label="Thời gian kết thúc"
                        error={!!error}
                        helperText={error?.message}
                      />
                    }
                    onChange={onChange}
                  />
                </DatePickerWrapper>
              )}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Controller
              name="saleStartTime"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <DatePickerWrapper>
                  <DatePicker
                    selected={value ? new Date(value) : undefined}
                    showYearDropdown
                    showMonthDropdown
                    placeholderText="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    disabled
                    customInput={
                      <CustomInputOutDate
                        size="small"
                        label="Thời gian đếm ngược"
                        error={!!error}
                        helperText={error?.message}
                      />
                    }
                    onChange={onChange}
                  />
                </DatePickerWrapper>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <ControlledTextField
              name="countdownStep"
              size="small"
              fullWidth
              label="Thời gian đếm ngược flash sale"
              control={control}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormAddSale;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 8 }}>{children}</Box>}
    </div>
  );
}
