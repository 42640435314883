export enum EContactForm {
  CALL = 'call',
  MEET = 'meet',
  SHOW = 'show',
}

export enum EFlashSaleStatus {
  ACTIVED = 1,
  BLOCKED = 0,
  DELETED = -1,
  EXPIRED = -2,
}
export enum ESelectBooking {
  'BOOK_SKILL' = 'booking-skill',
  'BOOK_DIRECT' = 'booking-direct',
}
