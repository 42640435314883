import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetExpertIdQuery } from 'redux/services/experts.services';
import RichTextEditor from 'app/components/RichTextEditor';

import { CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useGetExpertByIdMutation } from 'redux/services/experts.services';
import paramsToObject from 'utils/paramToObject';
import AccountSettings from '../AccountSettings';
// ** React Imports
import { SyntheticEvent, useState } from 'react';

// ** MUI Imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import { styled } from '@mui/material/styles';
import MuiTab, { TabProps } from '@mui/material/Tab';

// ** Icons Imports
import AccountOutline from 'mdi-material-ui/AccountOutline';
import LockOpenOutline from 'mdi-material-ui/LockOpenOutline';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import BadgeIcon from '@mui/icons-material/Badge';
import ChecklistIcon from '@mui/icons-material/Checklist';

// ** Third Party Styles Imports
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import TabAccount from './TabAccount';
import TabInfo from './TabInfo';
import TabExperience from './TabExperience';
import TabSkill from './TabSkill';
import TabCertificates from './TabCertificates';

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: 100,
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: 67,
  },
}));

const TabName = styled('span')(({ theme }) => ({
  lineHeight: 1.71,
  fontSize: '0.875rem',
  marginLeft: theme.spacing(2.4),
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
const ImgStyled = styled('img')(({ theme }) => ({
  width: 120,
  height: 120,
  marginRight: theme.spacing(6.25),
  borderRadius: '50%',
}));

const ExpertDetailPage = () => {
  const { expertId } = useParams();
  const { data, refetch, isFetching } = useGetExpertIdQuery(expertId || '');
  useEffect(() => {
    refetch();
  }, []);

  const [value, setValue] = useState<string>('account');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <CardContent>
      <Card
        style={{
          height: '200px',
          position: 'relative',
        }}
        className="z-0"
      >
        <img
          src={'/images/cards/background-user.png'}
          alt="avt"
          loading="lazy"
          className="object-cover w-full h-full "
        />
      </Card>

      <Card className="">
        <TabContext value={value}>
          <Grid container spacing={7} sx={{ padding: ' 0 20px' }}>
            <Grid item xs={6} sx={{ marginTop: 4.8, marginBottom: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  top: 0,
                  borderRadius: '50%',
                }}
              >
                {/* <ImgStyled
                  className="mt-[10px] rounded-[50%] "
                  src={'/images/avatars/1.png'}
                  alt="Profile Pic"
                /> */}
                {/* <Typography>teen</Typography> */}
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: 4.8, marginBottom: 3 }}></Grid>
          </Grid>
          <TabList
            onChange={handleChange}
            aria-label="account-settings tabs"
            sx={{
              borderBottom: theme => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Tab
              value="account"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountOutline />
                  <TabName>Account</TabName>
                </Box>
              }
            />
            <Tab
              value="experience"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChecklistIcon />
                  <TabName>Kinh nghiệm</TabName>
                </Box>
              }
            />
            <Tab
              value="skill"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ChecklistIcon />
                  <TabName>Kỹ năng</TabName>
                </Box>
              }
            />
            <Tab
              value="certificate"
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <BadgeIcon />
                  <TabName>Giấy chứng nhận</TabName>
                </Box>
              }
            />
          </TabList>
          {isFetching ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 10 }}>
              <CircularProgress />
            </Box>
          ) : (
            <CardContent>
              <TabPanel sx={{ p: 0 }} value="account">
                <TabAccount dataAccount={data} />
                <TabInfo info={data} />
              </TabPanel>

              <TabPanel sx={{ p: 0 }} value="experience">
                <TabExperience info={data} />
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value="skill">
                <TabSkill info={data} />
              </TabPanel>
              <TabPanel sx={{ p: 0 }} value="certificate">
                <TabCertificates info={data} />
              </TabPanel>
            </CardContent>
          )}
        </TabContext>
      </Card>
    </CardContent>
  );
};

export default ExpertDetailPage;
