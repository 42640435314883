import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetPermissionGroupsQuery } from 'redux/services/permissionGroups.services';

const PermissionPage = () => {
  const [searchParams] = useSearchParams({
    page: '0',
    limit: '10',
  });
  const navigate = useNavigate();
  const page = Number(searchParams.get('page') || 0);
  const limit = Number(searchParams.get('limit') || 10);
  const skip = page * limit;
  const { data: listPermissionGroup } = useGetPermissionGroupsQuery({
    skip: page * limit,
    limit: limit,
  });

  const handleEdit = (id: string) => {
    navigate('/permission-groups/' + id);
  };
  const handleCreate = () => {
    navigate('/permission-groups/create');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        marginBottom: 20,
      }}
    >
      <Grid container>
        <Grid item xs={6}>
          <Typography sx={{ typography: 'h5', textAlign: 'left' }}>
            Danh sách nhóm quyền
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
          <Button type="button" variant="contained" onClick={handleCreate}>
            Tạo nhóm quyền
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ width: '100%', minWidth: 'min-content' }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>id</TableCell>
              <TableCell>Tên</TableCell>
              <TableCell
                sx={{
                  whiteSpace: 'nowrap',
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  bgcolor: 'background.paper',
                  borderRight: 'divider',
                }}
              >
                Thao tác
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listPermissionGroup?.map((data, index) => (
              <TableRow
                key={data._id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{skip + index + 1}</TableCell>
                <TableCell sx={{ maxWidth: 100 }}>
                  <Tooltip title="Sao chép">
                    <Button
                      fullWidth
                      onClick={() => {
                        navigator.clipboard.writeText(data._id);
                      }}
                    >
                      <Typography noWrap>{data._id}</Typography>
                    </Button>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ minWidth: 300 }}>{data.groupName}</TableCell>
                <TableCell
                  sx={{
                    position: 'sticky',
                    right: 0,
                    zIndex: 1,
                    bgcolor: 'background.paper',
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleEdit(data._id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PermissionPage;
