import { zodResolver } from '@hookform/resolvers/zod';
import ReplayIcon from '@mui/icons-material/Replay';
import { Box, FormHelperText } from '@mui/material';
import Button from '@mui/material/Button';
import { notifyError } from 'app/components/Toast';
import dayjs from 'dayjs';
import { MuiOtpInput } from 'mui-one-time-password-input';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLoginOTPMutation } from 'redux/services/accounts.services';
import {
  useSendCodeOtpVoucherMutation,
  useSendVerifyOtpVoucherMutation,
} from 'redux/services/voucher.services';
import { object, string } from 'zod';

const SendOTPLogin = forwardRef<
  {
    handleOTP: () => Promise<boolean>;
  },
  { idAccount: string }
>((prop, ref) => {
  const { idAccount } = prop;
  const [resendTimer, setResendTimer] = useState(60);
  const timerRef = useRef<NodeJS.Timeout>();
  const voucherSchema = object({
    otp: string().min(1, 'Vui lòng nhập mã OTP!'),
  });
  const methods = useForm({
    resolver: zodResolver(voucherSchema),
  });
  const {
    control,
    clearErrors,
    getValues,
    setError,
    formState: { errors },
  } = methods;

  // const { loginOTP } = useLoginOTPMutation()
  const [loginOTP, { isLoading, isError, error, isSuccess }] =
    useLoginOTPMutation();

  useEffect(() => {
    if (resendTimer > 0) {
      timerRef.current = setTimeout(() => {
        setResendTimer(t => t - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [resendTimer]);

  const submitOTP = async () => {
    if (!getValues('otp')) {
      setError('otp', { type: 'custom', message: 'Vui lòng nhập mã OTP' });
    }
    try {
      if (!!getValues('otp')) {
        const payload = {
          otp: getValues('otp'),
          accountId: idAccount,
        };
        await loginOTP(payload).unwrap();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    handleOTP() {
      return submitOTP();
    },
  }));
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <form>
        <Controller
          control={control}
          rules={{ validate: value => value.length === 6 }}
          render={({ field, fieldState }) => (
            <Box>
              <MuiOtpInput
                sx={{ gap: 3 }}
                {...field}
                onChange={value => {
                  field.onChange(value);
                  clearErrors();
                  if (value.length >= 6) {
                  } else {
                    setError('otp', {
                      type: 'custom',
                      message: 'mã otp ko hợp lệ',
                    });
                  }
                }}
                length={6}
              />
              <FormHelperText
                style={{ marginTop: '15px' }}
                error={!!errors.otp}
              >
                {errors.otp?.message as string}
              </FormHelperText>
            </Box>
          )}
          name="otp"
        />
      </form>
      {/* <Box display={'flex'} alignItems={'center'} sx={{ marginTop: 4 }}>
        <Button
          size="small"
          variant="text"
          color="primary"
          endIcon={<ReplayIcon />}
          disabled={resendTimer > 0}
        >
          {resendTimer > 0
            ? dayjs()
                .set('minute', 0)
                .set('second', resendTimer)
                .format('mm:ss')
            : 'Mã otp hết hiệu lực, vui lòng thử lại'}
        </Button>
      </Box> */}
    </Box>
  );
});

export default SendOTPLogin;
