import LayoutsPage from 'app/pages/Layouts';
import CreateNotifyPage from 'app/pages/Notifications/CreateNotify';
import { RouteObject } from 'react-router-dom';

const LayoutRoutes: RouteObject = {
  path: '/layouts',
  children: [
    {
      index: true,
      element: <LayoutsPage />,
    },
    {
      path: 'create',
      element: <CreateNotifyPage />,
    },
  ],
};

export default LayoutRoutes;
