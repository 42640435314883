import {
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemProps,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { IExpert } from 'types/Expert';
import CategoryIcon from '@mui/icons-material/Category';

const CategoriesOption = ({
  categories,
  ...props
}: { categories: any } & ListItemProps) => {
  return (
    <ListItem {...props}>
      {/* <img src={CategoryIcon} alt="icons" /> */}
      <ListItemIcon>
        <CategoryIcon />
      </ListItemIcon>
      <ListItemText
        primary={categories?.name}
        secondary={
          <React.Fragment>
            <Typography sx={{ typography: 'caption' }}>
              {categories?._id}
            </Typography>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default CategoriesOption;
