// ** React Import
import { ReactNode } from 'react';

// ** Next Import

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import Typography, { TypographyProps } from '@mui/material/Typography';

// ** Type Import
import { Settings } from '@core/context/settingsContext';

// ** Configs
import themeConfig from 'configs/themeConfig';
import React from 'react';
import { Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
  verticalNavMenuBranding?: (props?: any) => ReactNode;
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight,
}));

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 'normal',
  textTransform: 'uppercase',
  color: theme.palette.text.primary,
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out',
}));

const StyledLink = styled(Link)<
  LinkProps & {
    to: string;
  }
>({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const { verticalNavMenuBranding: userVerticalNavMenuBranding } = props;

  // ** Hooks
  const theme = useTheme();

  return (
    <MenuHeaderWrapper className="nav-header" sx={{ pl: 6 }}>
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : (
        <StyledLink component={RouterLink} to="/">
          <img
            src="https://dxe6lti90zltq.cloudfront.net/images/eed81e50-d928-4984-a24e-8e7911277e37.png"
            alt=""
            style={{ width: '30px', objectFit: 'cover' }}
          />
          <HeaderTitle variant="h6" sx={{ ml: 3 }}>
            {/* {themeConfig.templateName} */}
            ADMIN ASKANY
          </HeaderTitle>
        </StyledLink>
      )}
    </MenuHeaderWrapper>
  );
};

export default VerticalNavHeader;
