import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as React from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { NotificationInput } from 'schema/notification.schema';

interface IGroupRadio {
  setValueRecord: UseFormSetValue<NotificationInput>;
  name: any;
  valueRadio: boolean;
  onChange?: (item) => void;
}
export default function ControlledRadioButtonsGroup(props: IGroupRadio) {
  const {
    setValueRecord,
    name = 'isRecord',
    valueRadio = false,
    onChange = () => {
      return;
    },
  } = props;

  const handleChange = (event: any) => {
    onChange(event.target.value === 'true' ? true : false);
    setValueRecord(name, event.target.value === 'true' ? true : false);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name={name}
        value={valueRadio}
        onChange={handleChange}
        row
      >
        <FormControlLabel value="false" control={<Radio />} label="Chỉ FCM" />
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="Tạo chuông và FCM"
        />
      </RadioGroup>
    </FormControl>
  );
}
